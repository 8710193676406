&.individual-needs {
    padding-top: 48px;
    padding-bottom: 48px;

    @include tablet {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @include desktop-and-large-screen {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    h2.title {
        margin-bottom: 24px;
        max-width: 789px;
        &.needs-detail {
            margin-bottom: 40px;
        }
    }
    p.description {
        margin-bottom: 48px;
        max-width: 584px;
        @include tablet {
            max-width: 472px;
        }
    }

    .cta-button-link {
        margin-top: 40px;
    }

    .needs-services {
        @include small-mobile-and-mobile {
            margin: 0;
        }
    }
}
