.insight-authors {

    &.our-team-section-secondary {

        .our-team-wrapper-secondary {

            &.guest-author {

                .our-team-card-wrapper {

                    min-height: 149px;
                    height: auto;
                    max-width: 343px;

                    &.guest-card-wrapper {
                        max-height: none;
                        padding-bottom: 40px;
                        margin-bottom: 40px;
                    }

                    @include tablet {
                        width: 100%;
                        min-height: 160px;
                        max-width: 348px;
                    }

                    @include desktop-and-large-screen {
                        min-height: 160px;
                        max-width: 379px;
                    }

                    .guest-author-info {
                        margin-bottom: 8px;
                        .guest-author-name {

                            @include link-accessibility;

                            word-wrap: break-word;
                            font-size: $font-size-14px;
                            font-weight: $font-weight-bold;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            line-height: $line-height-24px;
                            color: $bmo-wealth-accessible-blue;
                        }
                    }

                    .guest-author-description p {
                        word-wrap: break-word;
                        font-size: $font-size-16px;
                        font-weight: $font-weight-normal;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        line-height: $line-height-24px;
                        margin-bottom: 0;
                        color: $bmo-wealth-slate;
                    }
                    .guest-author-description div {
                        &:not(:first-child) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
