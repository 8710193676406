section.banker-bio-wrapper {

    padding: 16px 0 48px;

    @include tablet {
        padding: 24px 0 64px;
    }
    @include desktop-and-large-screen {
        padding: 32px 0 80px;
    }

    .breadcrumbs {
        &.desktop, &.mobile {
            margin-top:0;
        }
    }

    @include social-share;
    .social-share {
        @include desktop-and-large-screen {
            margin-top: 0;
        }
    }

    &.sub-brand-banker {
        background-color: $bmo-wealth-light-gray;
    }

    .about-banker-wrapper {

        background-color: $bmo-wealth-container-bg-gray;
        margin: 16px 0;

        &.sub-brand-banker {
            background-color: $bmo-wealth-light-gray;
        }

        .about-banker-inner-wrapper {
            margin-top: 40px;
            margin-bottom: 32px;
            @include small-mobile-and-mobile {
                padding: 0;
            }
            @include tablet {
                padding: 0 12px;
            }
            .column {
                padding: 0 16px;
                @include tablet {
                    padding: 0 12px;
                }
            }
        }
        .banker-avatar {
            margin: 40px 0;
            padding: 0;

            img {
                height: 163px;
                width: 163px;
                border-radius: 4px;
                @include tablet {
                    height: 162px;
                    width: 162px;
                }
                @include desktop-and-large-screen {
                    height: 277px;
                    width: 277px;
                }
            }
            @include tablet-and-desktop {
                float: right;
            }
        }
        .banker-details {
            h1 {
                font-weight: $font-weight-light;
                @include small-mobile-and-mobile {
                    font-size: $font-size-36px;
                    line-height: $line-height-40px;
                }
                margin-bottom: 16px;
            }
            p {
                font-weight: $font-weight-light;
                font-size: $font-size-24px;
                line-height: $line-height-32px;
                letter-spacing: normal;
                margin-bottom: 0;
                @include small-mobile-and-mobile {
                    &:last-child {
                        padding-bottom: 40px;
                    }
                }
            }
            @include tablet {
                padding: 0 16px;
            }
            @include desktop {
                padding: 0;
            }
            @include tablet-and-desktop {
                display: flex;
            }
            .banker-details-inner-wrapper {
                margin: auto 0;
                width: 100%;
                @include tablet {
                    max-width: 534px;
                }
                @include desktop-and-large-screen {
                    max-width: 687px;
                }
                @include tablet-and-desktop {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }

    .banker-biography {

        line-height: $line-height-24px;
        margin-top: 32px;
        @include tablet-and-desktop {
            margin-top: 40px;
        }
        .biography {
            .content {
                p {
                    margin: 0;
                }
            }
            .area-of-experties {
                font-size: $font-size-22px;
                font-weight: $font-weight-bold;
                line-height: $line-height-28px;

                label {
                    font-size: $font-size-24px;
                    line-height: $line-height-32px;
                    letter-spacing: normal;
                    color: $bmo-wealth-slate;
                    margin: 40px 0 0;
                    @include tablet-and-desktop {
                        margin: 48px 0 0;
                    }
                }
                a {
                    font-size: $font-size-22px;
                    line-height: $line-height-28px;
                    display: block;
                    margin-top: 16px;
                }
                @include desktop-and-large-screen {
                    display: block;
                }
            }
            @include tablet {
                padding: 0 16px;
            }
            @include small-mobile-and-mobile {
                padding: 0;
            }
        }
        .biography:last-child {
            @include tablet {
                padding: 0 0 0 6px;
            }
        }
    }

    .breadcrumbs-wrapper {
        @include tablet {
            position: relative;
        }
        .breadcrumb-inner-wrapper {
            @include tablet {
                position: relative;
            }
        }

        @include desktop-and-large-screen {
            margin: 0 auto;
            .desktop {
                margin: 0;
            }
        }
    }
}

section.banker-bio-wrapper + div.related_insights_listing_container {
    background-color: $bmo-wealth-container-bg-gray;
    .insights-listing-plugin-wrapper {
        padding-bottom: 40px;
    }
}

.related_insights_listing_container .cta-button-secondary {
    padding-bottom:40px;
}