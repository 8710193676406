section.image-with-text-wrapper {

    .content-wrapper {
        h3 {
            font-size: $font-size-24px;
            line-height: $line-height-32px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
    }

    .image-bottom.image, .image-top.image {
        display: block;
        margin: 24px auto;
        max-width: 789px;

        .image-with-text-background-top-bottom-variant {
            width: 100%;
            height: 260px;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 0 auto;
            border-radius: 4px;
            @include tablet {
                width: 596px;
                height: 230px;
            }
            @include desktop-and-large-screen {
                width: 789px;
                height: 328px;
            }
        }

        .content-wrapper {
            margin: 0 auto;
            text-align: left;
            width: 100%;
            .content {
                margin: 0 auto 24px;
                h2, h3, h4 {
                    margin-bottom: 24px;
                }
                @include tablet {
                    max-width: 472px;
                }
                @include desktop-and-large-screen {
                    width: 584px;
                }
                p {
                    letter-spacing: 0.15px;
                }
            }
            .business-owner-strategies {
                @include desktop-and-large-screen {
                    margin-top: -35px;
                }
            }

            &.image-top {
                margin-top: 8px;
            }
            &.image-bottom {
                margin-bottom: 24px;
            }
            .cta-button-secondary{
                a:link, a:visited {
                    margin: 0 auto;
                }
            }
            @include tablet {
                max-width: 596px;
            }
            @include desktop-and-large-screen {
                max-width: 789px;
            }
        }

        .col {
            padding: 0;
        }

        .image-description {
            margin-top: 8px;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: $line-height-24px;

            min-width: 343px;

            @include tablet {
                max-width: 596px;
            }

            @include desktop-and-large-screen {
                max-width: 789px;
            }
        }
    }

    &.content-center, &.content-top, &.service-detail {
        padding: 0 0 48px;
        @include tablet {
            padding: 0 12px 64px;
        }
        @include desktop-and-large-screen {
            padding: 0 24px 80px;
        }
    }

    &.service-detail {
        .content-wrapper {
            .content {
                margin-top: 32px;
                @include tablet {
                    margin-top: 4px;
                }
            }
        }
    }

    &.content-top, &.service-detail {
        @include small-mobile-and-mobile {
            padding: 0 0 32px;
        }
        .image-right.image, .image-left.image {
            .image-wrapper {
                .image-with-text-background-right-left-variant {
                    background-size: auto 100%;
                    background-position: top center;
                }
            }
            .whoweservelp {
                margin-right: 60px;
                .content-wrapper{
                    padding-right: 60px;
                }
                .cta-button-secondary {
                    margin-top: -25px;
                }
            }
        }
    }

    &.image-right, &.image-left {
        &.sub-brand {
            padding: 0 0 24px;
            @include tablet {
                padding: 0 12px 24px;
            }
            @include desktop-and-large-screen {
                padding: 0 24px 40px;
            }
            .content-wrapper {
                .content {
                    @include small-mobile-and-mobile {
                        text-align: center;
                        ul {
                            text-align: left;
                        }
                    }
                    h3 {
                        font-size: $font-size-22px;
                        line-height: $line-height-28px;
                        letter-spacing: 0.21px;
                    }
                    p {
                        &:first-child {
                            a {
                                font-size: $font-size-22px;
                                line-height: $line-height-28px;
                                letter-spacing: 0.21px;
                            }
                        }
                    }
                }
            }
        }
    }

    .image-right.image, .image-left.image {
        display: flex;
        padding: 0;
        margin: 0 auto;

        @include small-mobile-and-mobile  {
            display: inline;
        }

        .column {
            padding: 0 16px;
            @include tablet {
                padding: 0 12px;
            }
        }

        .image-wrapper {
            .image-with-text-background-right-left-variant {
                width: 100%;
                height: 280px;
                background-repeat: no-repeat;
                border-radius: 4px;
                background-size: cover;
            }
            @include small-mobile-and-mobile {
                &.image-right {
                    margin-bottom: 24px;
                }
            }
        }

        .content-wrapper {
            width: 100%;
            &.image-right {
                @include small-mobile-and-mobile {
                    margin: 24px 0 0;
                }
            }
            &.image-left {
                @include small-mobile-and-mobile {
                    margin: 24px 0 0;
                }
            }
            .cta-button-secondary {
                margin-top: 40px;
            }
            .content {
                h2, h3, h4 {
                    margin-bottom: 16px;
                }
                p {
                    letter-spacing: 0.15px;
                }
                ul {
                    margin: 0;
                }
            }
        }
    }
    .image-right.image {
        @include small-mobile-and-mobile {
            display:flex;
            flex-direction: column-reverse;
        }
        .column:nth-child(2){

            .image-wrapper {
                    @include small-mobile-and-mobile {
                        margin-bottom: 0;
                    }
                }
        }
    }
    &.content-top {
        margin-top: 40px;
        @include small-mobile-and-mobile {
            margin-top: 32px;
        }
    }
    &.content-center {
        .image-right.image {
            .column:nth-child(1) {
                @include tablet-and-desktop {
                    display: flex;
                }
            }
        }
        .image-left.image {
            .column:nth-child(2) {
                @include tablet-and-desktop {
                    display: flex;
                }
            }
        }
        .image-right.image, .image-left.image {
            .image-with-text-background-right-left-variant {
                min-width: 343px;
                height: 240px;
                border-radius: 4px;
                @include tablet {
                    min-width: 348px;
                    height: 248px;
                }
                @include desktop-and-large-screen {
                    max-width: 584px;
                    height: 328px;
                }
            }
            .content-wrapper {
                @include tablet-and-desktop {
                    margin: auto;
                }
                .content {
                    p {
                        @include tablet {
                            min-width: 348px;
                        }
                        @include desktop-and-large-screen {
                            max-width: 481px;
                        }
                    }
                }
            }
        }
    }

    .icon {
        max-width: 482px;
        margin: 24px auto;

        &.image-left {
            .image-icon-wrapper {
                text-align: left;
            }
        }
        &.image-right {
            .image-icon-wrapper {
                text-align: right;
            }
        }

        .content-wrapper {
            margin: 0 auto;
            text-align: left;
            .content {
                width: 100%;
                h2, h3, h4 {
                    margin-bottom: 16px;
                    font-size: $font-size-24px;
                    line-height: $line-height-32px;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                }
            }
            a {
                line-height: $line-height-20px;
                letter-spacing: 0.25px;
            }
            .cta-button-secondary{
                a:link, a:visited {
                    margin: 0 auto;
                }
            }
        }
        .image-icon-wrapper {
            padding: 8px 0 11px;
            text-align: center;
            img {
                width: 48px;
                height: 48px;
            }
        }

        &.center {
            .image-icon-wrapper {
                padding-top: 0;
                img {
                    width: auto;
                    height: auto;
                    @include mobile-and-tablet {
                        max-width: 181px;
                    }
                }
            }
            .content-wrapper {
               p {
                    margin-bottom: 0;
               }
            }
            margin: 0 auto;
        }
    }
}

section.image-with-text-wrapper + div.page-cta-button {
    .cta-button-secondary {
        a {
            margin-top: 16px;
            @include small-mobile-and-mobile {
                margin-top: 0;
            }
        }
    }
}


section.image-with-text-wrapper{
    .whoweservelp{
        margin: -70px 60px -70px 0px;
    }
    .content-wrapper.image-left.whoweservelp {
        padding: 100px 50px 0px 60px;
        @include small-mobile-and-mobile {
            padding: 0px;
        }
    }
    .content-wrapper.image-right.whoweservelp {
        padding: 30px 50px 0px 0px;
    }

}
section.image-with-text-wrapper.screen-max-width.image-left.content-top.whoweservelp {
    margin-top: -30px;
}

section.image-with-text-wrapper .icon.center .content-wrapper  .content_description.shadeborder{
    background: #FFFFFF;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

}

section.image-with-text-wrapper .icon.center .content-wrapper .shadeborder{
    margin-bottom: 91px;
}

section.image-with-text-wrapper .icon .content-wrapper .content h2{
    border-bottom: 1px solid #D9D9D9;
    margin-left: 45px;
    width: 76%;
   
}
section.image-with-text-wrapper .icon .content-wrapper.shadeborder .content p{
    padding: 0px 10px;
}

.column.conversation.col-xs-4.col-sm-4.col-md-4.col-lg-4.col-xl-4.wealth-planning{
    padding: 0px 10px 0px 30px;
}

.column.fiscal-responsibility.col-xs-4.col-sm-4.col-md-4.col-lg-4.col-xl-4.investment-goals{
    padding: 0px 10px 0px 30px;
}

.column.looking-for-impact.col-xs-4.col-sm-4.col-md-4.col-lg-4.col-xl-4.bank-borrow{
    padding: 0px 10px 0px 30px;
}

.column.fiscal-responsibility.col-xs-8.col-sm-8.col-md-8.col-lg-6.col-xl-6.tax-planning{
    padding: 0px 10px 0px 215px;
    max-width: 50%;
} 

.column.conversation.col-xs-8.col-sm-8.col-md-8.col-lg-6.col-xl-6.trust-estate{
    padding: 0px 180px 0px 30px;
    max-width: 50%;
}

@media (max-width: 575px) {
    .column.fiscal-responsibility.col-xs-8.col-sm-8.col-md-8.col-lg-6.col-xl-6.tax-planning {
        padding: 0 20px 0 25px;
        max-width: 100%;
   }
    .column.conversation.col-xs-8.col-sm-8.col-md-8.col-lg-6.col-xl-6.trust-estate{
        padding: 0 20px 0 25px;
        max-width: 100%;
    }
}

@media (min-width: 767.9px) and (max-width: 991px){
    .column.fiscal-responsibility.col-xs-8.col-sm-8.col-md-8.col-lg-6.col-xl-6.tax-planning {
        max-width: 45.666667%;
        padding: 0px 10px 0px 115px;
    }
    .column.conversation.col-xs-8.col-sm-8.col-md-8.col-lg-6.col-xl-6.trust-estate{
        max-width: 45.666667%;
        padding: 0px 10px 0px 85px;
    }
}

@media (min-width: 576px) and (max-width: 767.9px){
    .column.fiscal-responsibility.col-xs-8.col-sm-8.col-md-8.col-lg-6.col-xl-6.tax-planning {
        padding: 0px 10px 0px 15px;
        max-width: 50%;
    }
    .column.conversation.col-xs-8.col-sm-8.col-md-8.col-lg-6.col-xl-6.trust-estate {
    padding: 0 20px 0 5px;
    max-width: 50%;
    }
}

@include desktop-and-large-screen {
.content.retail-wealth-expertise h1 {   

    margin : 80px 50px 50px -150px;
    width: 900px;
     }
}

// added this custom class to handle it for Lawyers and Lawfirms Page
@include desktop-and-large-screen {
    .content.lawyers-and-law-firms h2 {
        margin-top: 80px;
        font-size: 44px;
        font-weight: 300;
        line-height: 48px;
        letter-spacing: -0.5px;
        text-align: center;
    }

    .content.lawyers-and-law-firms {
        width: 630px !important;
    }
}
