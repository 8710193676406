.search-result-page {
  .search-result-header {
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
    border-image-slice: 1;
    background-color: $bmo-wealth-white;
    &.sticky {
      position: fixed;
      top: 140px;
      width: 100%;
      z-index: 5;
      background-color: $bmo-wealth-white;
      &.scroll-active {
        box-shadow: 0 4px 2px 0 rgba(0, 25, 40, 0.05), 0 2px 2px 0 rgba(0, 25, 40, 0.06);
        border-style: solid;
      }
      @include tablet {
        top: 80px;
      }
      &.language-bar-active {
        @include desktop-and-large-screen {
          top: 204px;
        }
      }
    }
    @include tablet {
      height: 237px;
    }
    height: 216px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .search-header-text {
      margin-top: 80px;
      font-size: $font-size-24px;
      font-weight: $font-weight-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: $bmo-wealth-slate;
    }
    .header-tab {
      justify-content: center;
      .nav-item {
        margin-right: 16px;
        margin-bottom: 16px;
        @include tablet {
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .nav-link {
          color: $bmo-wealth-accessible-blue;
          border-radius: 4px;
          background: $bmo-wealth-white;
          border: solid 2px $bmo-wealth-Granite;
          padding: 8px 16px;
          font-size: $font-size-14px;
          font-weight: $font-weight-bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 0.25px;
          text-transform: uppercase;
          &.active {
            color: $bmo-wealth-white;
            background: $bmo-wealth-accessible-blue;
            border: solid 2px $bmo-wealth-accessible-blue;
          }
          &.disabled-link {
            color: $bmo-wealth-Granite;
            cursor: not-allowed;
          }
        }
      }
      margin-top: 24px;
      padding: 0 20px;
    }
  }
  .search-result-header-mobile {
    height: 252px;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
    border-image-slice: 1;
    background-color: $bmo-wealth-white;
    &.sticky {
      position: fixed;
      top: 64px;
      width: 100%;
      z-index: 10;
      background-color: $bmo-wealth-white;
      &.scroll-active {
        box-shadow: 0 4px 2px 0 rgba(0, 25, 40, 0.05), 0 2px 2px 0 rgba(0, 25, 40, 0.06);
        border-style: solid;
      }
    }
    .search-header-text {
      font-size: $font-size-24px;
      font-weight: $font-weight-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      margin-top: 64px;
      color: $bmo-wealth-slate;
      padding: 0 16px;
    }
    .mobile-search-dropdown-wrapper {
      position: relative;
      display: inline-block;
      width: 100%;
      margin-top: 60px;
      padding: 0 16px;
      padding-top: 8px;
      select:focus {
        outline: none;
      }
      select:focus + span.dropdown-arrow + label.slide-label:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        background: $bmo-wealth-accessible-blue;
      }
      span.dropdown-arrow {
        position: absolute;
        right: 24px;
        top: 11px;
        width: 16px;
        height: 12px;
        color: $bmo-wealth-accessible-blue;
        background-image: url('/static/assets/svgs/accordion-svg/chevron-down.svg');
      }
      select {
        color: $bmo-wealth-slate;
        padding: 0;
        font-size: $font-size-18px;
        width: 100%;
        position: relative;
        width: 100%;
        border: none;
        background: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: $font-size-15px;
        option {
          font-size: $font-size-15px;
          text-transform: capitalize;
        }
      }
      .input-helper-text {
        font-size: $font-size-14px;
        font-weight: $font-weight-normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.25px;
        margin-top: 8px;
        color: $bmo-wealth-Granite;
      }
      label.slide-label {
        z-index: 2;
        position: absolute;
        top: -15px;
        right: 16px;
        left: 16px;
        pointer-events: none;
        height: 42px;
        cursor: pointer;
        border-radius: 4px 0px 0px 4px;
        &:before {
          z-index: 4;
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0px;
          left: 0px;
          background: $bmo-wealth-accessible-blue;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transition: all 0.3s linear;
          transition: all 0.3s linear;
          top: 46px;
        }
        &:after {
          z-index: 3;
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0px;
          left: 0px;
          background: $bmo-wealth-light-gray;
          top: 46px;
        }
        span.label-text {
          display: block;
          padding: 3px 0px 0px 0px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: $font-size-18px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 0.15px;
          color: $bmo-wealth-accessible-blue;
          -webkit-transition: 0.3s ease-out;
          transition: 0.3s ease-out;
        }
        &.active-label {
          span.label-text {
            font-size: $font-size-14px;
            margin-top: 3px;
            letter-spacing: 0.25px;
            line-height: $line-height-14px;
            font-weight: $font-weight-medium;
          }
        }
      }
    }
  }
  .search-result-body {
    >div:last-child {
      margin-bottom: 0 !important;
    }
    max-width: 995px;
    margin-bottom: 81px !important;
    margin-top: 372px !important;
    &.language-bar-active {
      @include desktop-and-large-screen {
        margin-top: 436px !important;
      }
    }
    @include tablet {
      max-width: 100%;
      padding: 0 24px;
      margin-bottom: 65px !important;
      margin-top: 360px !important;
    }
    @include small-mobile-and-mobile {
      margin-top: 340px !important;
      max-width: 100%;
      padding: 0 16px;
      margin-bottom: 48px !important;
    }
    margin: 0 auto;
    .explore-button {
      margin-bottom: 64px;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      a {
        font-size: $font-size-14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.25px;
      }
    }
    .insight-card,
    .service-card,
    .page-card,
    .banker-card {
      display: flex;
      width: 100%;
      @include search-result-card-shadow-mixin;
      margin-bottom: 16px;
    }
    .insight-card {
      @include insight-search-card-mixin;
      max-width: 343px;
      margin: 0 auto 16px;
      @include tablet {
          max-width: 720px;
      }
      @include desktop-and-large-screen {
          max-width: 995px;
      }
    }
    .service-card {
      @include small-mobile-and-mobile {
        flex-direction: column;
      }
      border-width: 0 0.5px 0.5px 0;
      .image-wrapper {
        @include tablet {
          min-width: 224px;
        }
        @include small-mobile-and-mobile {
          min-width: 100%;
          height: 154px;
        }
        min-width: 379px;
        max-width: 379px;
        border-radius: 4px;
        background: $bmo-wealth-albicant;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 120px;
          height: 96px;
        }
      }
      .card-content {
        padding: 24px 103px 24px 24px;
        @include tablet {
          padding: 24px 62px 26px 24px;
        }
        @include small-mobile-and-mobile {
          padding: 24px;
        }
        .block-heading {
          font-size: $font-size-12px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 0.25px;
          color: $bmo-wealth-slate;
          text-transform: uppercase;
        }
        .block-link {
          font-size: $font-size-22px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.27;
          letter-spacing: 0.15px;
          margin-top: 24px;
          display: block;
        }
        .block-description {
          font-size: $font-size-16px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          margin-top: 16px ;
          margin-bottom: 0;
          color: $bmo-wealth-slate;
        }
      }
    }
    .page-card {
      .card-content {
        padding: 24px 103px 24px 24px;
        @include tablet {
          padding: 24px 62px 24px 24px;
        }
        @include small-mobile-and-mobile {
          padding: 24px;
        }
        height: auto;
        .block-heading {
          font-size: $font-size-12px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          color: $bmo-wealth-slate;
          line-height: 1.33;
          letter-spacing: 0.25px;
          text-transform: uppercase;
        }
        .block-link {
          font-size: $font-size-22px;
          display: block;
          font-stretch: normal;
          font-style: normal;
          margin-top: 24px;
          line-height: 1.27;
          letter-spacing: 0.15px;
        }
        .block-description {
          font-size: $font-size-16px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          margin-top: 16px ;
          margin-bottom: 0;
          color: $bmo-wealth-slate;
        }
      }
    }
    .banker-card {
      @include small-mobile-and-mobile {
        flex-direction: column;
        padding: 24px 24px 32px 24px;
      }
      .banker-card-image-wrapper {
        @include small-mobile-and-mobile {
          padding: 0;
        }
        padding: 24px 0 30px 24px;
        .banker-card-image {
          border-radius: 4px;
          @include tablet {
            width: 138px;
            height: 138px;
          }
          @include small-mobile-and-mobile {
            width: 126px;
            height: 126px;
          }
          width: 150px;
          height: 150px;
        }
      }
      .card-content {
        padding: 24px 103px 24px 24px;
        @include tablet {
          padding: 24px 62px 24px 24px;
        }
        @include small-mobile-and-mobile {
          padding: 0;
          margin-top: 24px;
        }
        .block-heading {
          font-size: $font-size-12px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 0.25px;
          color: $bmo-wealth-slate;
          text-transform: uppercase;
          @include small-mobile-and-mobile {
            display: none;
          }
        }
        .block-link {
          font-size: $font-size-18px;
          display: block;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          margin-top: 24px;
          @include small-mobile-and-mobile {
            margin-top: 0;
          }
        }
        .job-title,
        .company {
          font-size: $font-size-14px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 0.25px;
          color: $bmo-wealth-slate;
        }
        .job-title {
          margin-top: 8px;
        }
        .block-description {
          font-size: $font-size-16px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          color: $bmo-wealth-slate;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
      &.thought-leader {
        background-clip: content-box;
        @include small-mobile-and-mobile {
          padding: 24px 24px 32px 24px;
        }
        padding: 24px 103px 24px 24px;
        @include tablet {
          padding: 24px 62px 24px 24px;
        }
        display: flex;
        flex-direction: column;
        .card-content {
          padding: 0;
          @include small-mobile-and-mobile {
            margin: 0;
          }
          display: flex;
          .banker-card-image-wrapper {
            padding: 0;
            .banker-card-image {
              width: 80px;
              height: 80px;
              @include tablet {
                width: 76px;
                height: 76px;
              }
              @include small-mobile-and-mobile {
                width: 64px;
                height: 64px;
              }
            }
          }
          .card-info {
            margin-left: 24px;
            .block-link {
              font-size: $font-size-14px;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.57;
              letter-spacing: normal;
              margin-top: 16px;
              @include small-mobile-and-mobile {
                margin-top: 0;
              }
            }
            .job-title {
              font-size: $font-size-12px;
              font-weight: $font-weight-normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
            }
          }
        }
        .block-description {
          font-size: $font-size-14px;
          font-weight: $font-weight-normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: 0.25px;
          margin-bottom: 0;
          margin-top: 24px;
        }
      }
    }
    .pagination {
      margin-top: 48px;
    }
  }
}

.no-search-result {
  max-width: 995px;
  margin: 0 auto;
  margin-bottom: 81px;
  margin-top: 80px;
  @include tablet {
    margin-bottom: 65px;
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
  @include small-mobile-and-mobile {
    margin-bottom: 48px;
    margin-top: 64px;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .no-results-found-message {
    width: 100%;
    margin: 0 auto;
    @include tablet {
      max-width: 596px;
    }
    @include desktop-and-large-screen {
      max-width: 789px;
    }
    padding-top: 64px;
    @include tablet {
      padding-top: 80px;
    }
    @include desktop-and-large-screen {
      padding-top: 140px;
    }
    text-align: center;
    h1 {
      line-height: 1.33;
      margin: 0;
    }
    h1 ,
    p {
      font-stretch: normal;
      font-style: normal;
      color: $bmo-wealth-slate;
      text-align: center;
    }
    h1 {
      font-size: $font-size-24px;
      font-weight: $font-weight-bold;
      line-height: 1.33;
      letter-spacing: normal;
      word-break: break-word;
      @include ie-browser {
        word-break: break-all;
      }
     }
    p {
      font-size: $font-size-16px;
      font-weight: $font-weight-normal;
      line-height: 1.5;
      letter-spacing: 0.15px;
      margin-top: 24px;
      margin-bottom: 0;
      word-break: break-word;
    }
  }
  .search-help-container {
    margin-top: 40px;
    @include tablet {
      padding: 40px 32px;
    }
    @include small-mobile-and-mobile {
      padding: 40px 24px;
    }
    .contact-us-container {
      .contact-us-btn {
        a {
          padding: 16px 32px;
        }
      }
    }
    .contact-us-container,
    .recent-search-container  {
      width: 50%;
      @include small-mobile-and-mobile {
        display: block;
        width: 100%;
      }
    }
    .recent-search-container {
      margin-left: 33px;
      @include tablet {
        margin-left: 24px;
      }
      @include small-mobile-and-mobile {
        margin-top: 48px;
        margin-left: 0;
      }
      .recent-search-link-container {
        a {
          display: block;
          width: fit-content;
        }
      }
    }
  }
}
