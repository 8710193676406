header.sticky {
  position: fixed;
  top: 0px;
  z-index: 1000;
  width: 100%;
}
section.regular-header {
  position: relative;
  .search-bar-container {
    display: none;
    @include tablet {
      padding: 0;
      top: 80px;
      height: auto;
      max-width: 100%;
    }
    @include small-mobile-and-mobile {
      padding: 0;
      top: 64px;
      height: auto;
      background: $bmo-wealth-white;
    }
    padding-right: 40px;
    width: 100%;
    height: 95px;
    max-width: 862px;
    z-index: 101;
    right: 0;
    @include max-width-and-below {
      width: calc(100% - 418px);
      @include mobile-and-tablet {
        width: 100%;
      }
    }
    @include max-width-and-above {
      right: calc(( 100% - 1280px )/2);
    }
    top: 44px;
    position: absolute;
    .search-block {
      max-width: 862px;
      width: 100%;
      display: flex;
      height: 95px;
      align-items: center;
      justify-content: space-between;
      @include mobile-and-tablet {
        height: auto;
        padding: 0;
        max-width: 100%;
      }
      .search-block-wrapper {
        @include tablet {
          padding: 26px 24px;
          width: 100%;
          height: auto;
          max-width: none;
        }
        @include small-mobile-and-mobile {
          width: 100%;
          height: auto;
          padding: 40px 16px;
          display: block;
        }
        max-width: 862px;
        width: 100%;
        height: 95px;
        right: 0;
        justify-content: space-between;
        display: flex;
        align-items: center;
        background: $bmo-wealth-white;
        .input-search-wrapper {
          width: 84%;
          position: relative;
          display: flex;
          align-items: center;
          max-width: 638px;
          @include mobile-and-tablet {
            margin-left: 0;
            width: 100%;
            margin-right: 32px;
            max-width: 100%;
            .form-field.input  {
              width: 100%;
              &.field-disabled {
                .form-label-group {
                  input {
                    color: $bmo-wealth-Granite;
                  }
                  label.slide-label {
                    pointer-events: none;
                    &:after {
                      background: $bmo-wealth-Granite;
                    }
                    .label-text {
                      color: $bmo-wealth-Granite;
                    }
                  }
                }
              }
            }
            @include small-mobile-and-mobile {
              max-width: 100%;
            }
          }
          .form-label-group {
            input {
              width: calc(100% - 34px);
              padding: 10px;
              @include mobile-and-tablet {
                width: calc(100% - 42px);
              }
            }
          }
          margin-right: 32px;
          margin-left: 57px;
          height: 43px;
          .clear-icon {
            display: none;
            border: none;
            background-repeat: no-repeat;
            background-color: transparent;
            position: absolute;
            width: 24px;
            height: 24px;
            right: 10px;
            bottom: 0;
            background-image: url('/static/assets/svgs/header-svg/close-granite.svg');
            &:hover {
              cursor: pointer;
            }
          }
          input[type="text"]:not(:focus) + label.slide-label.active-label:before {
            background: $bmo-wealth-gray;
          }
          input[type="text"]:focus + label.slide-label:before {
            background: $bmo-wealth-gray;
          }
          input[type="text"]:focus+ label.slide-label.active-label:before {
            background: $bmo-wealth-gray;
          }
          input.search-input:focus + label.slide-label:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            background: $bmo-wealth-gray;
          }
          input.search-input:focus + label.slide-label span.label-text {
              padding: 0px;
              margin-top: -9px;
              font-size: $font-size-14px;
              line-height: $line-height-14px;
              font-weight: $font-weight-medium;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: 0.25px;
          }
          input.search-input {
            width: calc(100% - 28px);
            height: auto;
            border: none;
            position: absolute;
            bottom: 2px;
            padding: 0;
            margin-top: 6px;
            &:focus {
              outline: none;
            }
            &::-webkit-input-placeholder {
              transition: color 300ms ease;
            }
            &:not(:focus)::-webkit-input-placeholder {
                color: transparent;
            }
          }
          label.slide-label {
            z-index: 2;
            position: absolute;
            top: 0px;
            right: 0px;
            left: 0px;
            pointer-events: none;
            height: 42px;
            cursor: pointer;
            border-radius: 4px 0px 0px 4px;
            &:before {
                z-index: 4;
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                left: 0px;
                background: $bmo-wealth-gray;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s linear;
                transition: all 0.3s linear;
                top: 44px;
            }
            &.active-label:after {
                background: $bmo-wealth-gray;
            }
            &:after {
                z-index: 3;
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                left: 0px;
                background: $bmo-wealth-gray;
                top: 44px;
            }
            span.label-text {
                display: block;
                padding: 18px 0px 0px 0px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: $font-size-18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: 0.15px;
                color: $bmo-wealth-accessible-blue;
                -webkit-transition: 0.3s ease-out;
                transition: 0.3s ease-out;
            }
            &.active-label {
                span.label-text {
                    padding: 0px;
                    font-size: $font-size-14px;
                    line-height: $line-height-14px;
                    margin-top: -9px;
                }
            }
          }
        }
        button.search-button {
          background: $bmo-wealth-accessible-blue;
          font-size: $font-size-14px;
          font-weight: $font-weight-bold;
          border: none;
          padding: 16px 32px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.25px;
          height: 56px;
          color: $bmo-wealth-white;
          border-radius: 28px;
          &:hover {
            border-color: $bmo-wealth-ultramarine;
            background-color: $bmo-wealth-ultramarine;
          }
          @include small-mobile-and-mobile {
            padding: 16px 72px;
            margin-top: 32px;
          }
        }
      }
    }
  }
  .search-overlay-container {
    @include small-mobile-and-mobile {
      max-width: 100%;
      width: 100%;
      top: 237px;
      box-shadow: 0 5px 4px 0 rgba(0, 25, 40, 0.07), 0 3px 2px 0 rgba(0, 25, 40, 0.12);
    }
    @include tablet {
      top: 187px;
      max-width: 100%;
      width: 100%;
      box-shadow: 0 5px 4px 0 rgba(0, 25, 40, 0.07), 0 3px 2px 0 rgba(0, 25, 40, 0.12);
    }
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    background: $bmo-wealth-white;
    max-width: 1200px;
    width: calc(100% - 80px);
    top: 139px;
    z-index: 101;
    height: auto;
    box-shadow: 0 5px 4px 0 rgba(0, 25, 40, 0.07), 0 3px 2px 0 rgba(0, 25, 40, 0.12);
    border-style: solid;
    border-width: 0;
    border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
    border-image-slice: 1;
    .search-result-wrapper {
      .search-result-container {
        padding: 40px 66px 40px 40px;
        height: auto;
        display: flex;
        @include tablet {
          padding: 16px 24px 40px 24px;
          display: flex;
          flex-direction: column;
        }
        @include small-mobile-and-mobile {
          padding: 40px 16px;
          display: block;
        }

        .search-overlay-content-header {
          margin: 0;
          font-size: $font-size-24px;
          color: $bmo-wealth-slate;
          font-weight: $font-weight-light;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
        }
        .search-result-trending {
          .trending-links {
            @include tablet {
              width: 100%;
              column-count: 2;
              column-gap: 24px;
            }
            @include small-mobile-and-mobile {
              width: 100%;
            }
            a {
              @include tablet {
                margin-bottom: 16px;
                padding: 0;
              }
              margin-bottom: 16px;
              display: block;
              font-size: $font-size-18px;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.56;
              letter-spacing: normal;
            }
            div.no-content {
              display: none;
              .no-content-message {
                width: 100%;
                @include tablet-and-desktop {
                  max-width: 339px;
                }
                font-size: $font-size-18px;
                font-weight: $font-weight-bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: 0.15px;
                color: $bmo-wealth-slate;
                word-break: break-word;
              }
            }
            width: 339px;
            margin-top: 16px;
          }
        }
        .search-result {
          .all-service-links {
            column-count: 3;
            -webkit-column-count: 3;
            -moz-column-count: 3;
            width: 100%;
            column-gap: 50px;
            -webkit-column-gap: 50px;
            -moz-column-gap: 50px;
            margin-top: 16px;
            @include desktop-and-large-screen {
              margin-left: -2px;
            }
            @include small-mobile-and-mobile {
              column-count: 1;
            }
          }
          width: calc(100% - 339px);
          padding-left: 31px;
          @include tablet {
            width: 100%;
            float: left;
            margin-top: 40px;
            padding: 0;
          }
          .all-service-links.active-expand {
            .service-link {
              &:nth-child(n+3) {
                display: none;
                @include tablet-and-desktop {
                  display: block;
                }
              }
            }
            &.active-collapse {
              .service-link {
                display: block;
              }
            }
          }
          div.service-link {
            @include tablet-and-desktop {
              display: none !important;
            }
            &.expand-links a .chevron-down {
              width: 14px;
              display: inline-block;
              height: 11px;
              margin-left: 4px;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('/static/assets/svgs/accordion-svg/chevron-down-blue-small.svg');
            }

            &.collapse-links  a .chevron-up {
              width: 14px;
              display: inline-block;
              height: 11px;
              margin-left: 4px;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('/static/assets/svgs/accordion-svg/chevron-up-blue-small.svg');
            }

            &.expand-links,
            &.collapse-links {
              display: none;
              margin-top: 40px;
              a {
                font-size: $font-size-14px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: 0.15px;
              }
            }
          }
          a.service-link {
            @include small-mobile-and-mobile {
              width: 100%;
              display: block;
              max-width: 100%;
              float: none;
              margin-bottom: 16px;
            }
            margin-bottom: 16px;
            margin-right: 5px;
            break-inside: avoid;
            outline-offset: -2px;
            font-size: $font-size-18px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: normal;
            display: block;
            @include desktop-and-large-screen {
              padding-left: 2px
            }
            sup {
              right: 0.3em;
            }
          }
          @include small-mobile-and-mobile {
            width: 100%;
            padding: 0;
            margin: 0;
            margin-top: 40px;
          }
          .search-disclaimer {
            display: block;
            height: auto;
            position: relative;
            top: 40px;
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
    .search-result-response-wrapper {
      display: none;
      .search-result-response-container {
        padding: 40px;
        @include tablet {
          padding: 16px 24px 40px 24px;
          display: block;
          background: $bmo-wealth-white;
        }
        @include small-mobile-and-mobile {
          padding: 40px 16px;
          display: block;
        }
        height: auto;
        display: flex;
        .insight-header,
        .service-header,
        .our-team-header {
          margin: 0;
          font-size: $font-size-24px;
          color: $bmo-wealth-slate;
          font-weight: $font-weight-light;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
        }
        .search-result-insight {
          width: 33%;
          margin-right: 31px;
          max-width: 339px;
          @include small-mobile-and-mobile {
            width: 100%;
            margin: 0;
            max-width: 100%;
          }
          @include tablet {
            width: auto;
            margin-right: 0;
            max-width: none;
            height: auto;
            min-height: 130px;
          }

          .insight-links {
            max-width: 339px;
            margin-top: 16px;
            @include small-mobile-and-mobile {
             max-width: 100%;
            }

            @include tablet {
              max-width: none;
              column-count: 2;
              column-gap: 24px;
            }
            a.insights-search-link {
              margin-bottom: 16px;
              display: block;
              font-size: $font-size-18px;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.56;
              letter-spacing: normal;
            }
          }
          div.no-content {
            display: none;
            .no-content-message {
              font-size: $font-size-18px;
              font-weight: $font-weight-bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: 0.15px;
              color: $bmo-wealth-slate;
              word-break: break-word;
            }
          }
        }
        .service-and-team {
          width: 66%;
          display: inline-flex;
          flex-flow: row wrap;
          @include small-mobile-and-mobile {
            width: 100%;
            display: block;
            margin-top: 40px;
          }
          @include tablet {
            margin-top: 40px;
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
          }
          .search-result {
            @include tablet {
              margin-right: 0;
            }
            @include small-mobile-and-mobile {
              width: 100%;
              margin: 0;
            }
            width: 50%;
            margin-right: 71px;
            order: 1;
            flex: 2 0px;
            .service-links {
              max-width: 339px;
              margin-top: 16px;
              a.services-search-link {
                margin-bottom: 16px;
                display: block;
                font-size: $font-size-18px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.56;
                letter-spacing: normal;
              }
            }
            div.no-content {
              display: none;
              .no-content-message{
                font-size: $font-size-18px;
                font-weight: $font-weight-bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: 0.15px;
                color: $bmo-wealth-slate;
                word-break: break-word;
              }
            }
          }
          .search-team {
            width:50%;
            order: 1;
            flex: 2 0px;
            @include small-mobile-and-mobile {
              width: 100%;
              margin: 0;
              margin-top: 40px;
            }
            @include tablet {
              width: 348px;
            }
            .team-result-container {
              margin-top: 16px;
              .team-member-block {
                margin-bottom: 24px;

                &:last-child {
                  margin-bottom: 0;
                }

                display: flex;
                div.member-logo {
                  @include small-mobile-and-mobile {
                    display: none;
                  }
                  img {
                    width: 80px;
                    height: 80px;
                    border-radius: 4px;
                  }
                }
                div.member-link {
                  @include small-mobile-and-mobile {
                    margin-left: 0;
                  }
                  display: flex;
                  flex-direction: column;
                  margin-left: 24px;
                  max-width: 214px;
                  a {
                    font-size: $font-size-16px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                  }
                  span {
                    &.member-text,
                    &.company {
                      font-size: $font-size-14px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.29;
                      display: block;
                      letter-spacing: normal;
                      color: $bmo-wealth-slate;
                    }
                    &.member-text {
                      @include tablet-and-desktop {
                        margin-top: 9px;
                      }
                      @include small-mobile-and-mobile {
                        margin-top: 8px;
                      }
                    }
                  }
                }
              }
              div.no-content {
                display: none;
                .no-content-message {
                  font-size: $font-size-18px;
                  font-weight: $font-weight-bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  letter-spacing: 0.15px;
                  color: $bmo-wealth-slate;
                  word-break: break-word;
                }
              }
            }
          }
          .search-disclaimer {
            order: 3;
            flex-basis: 100%;
            position: relative;
            top: 40px;
          }
        }
      }
    }
    .no-result-error-block {
      display: none;
      text-align: center;
      padding: 40px;
      @include tablet {
        padding: 16px 86px 40px;
      }
      @include small-mobile-and-mobile {
        padding: 40px 16px;
      }
      p,h2,b {
        color: $bmo-wealth-slate;
        font-stretch: normal;
        font-style: normal;
      }
      .no-results-found-message {
        width: 100%;
        margin: 0 auto;
        @include tablet {
          max-width: 596px;
        }
        @include desktop-and-large-screen {
          max-width: 789px;
        }
        .no-result-text {
          margin-bottom: 16px;
          font-size: $font-size-24px;
          font-weight: $font-weight-bold;
          line-height: 1.33;
          letter-spacing: normal;
          word-break: break-word;
          @include ie-browser {
            word-break: break-all;
          }
        }
        p {
          font-size: $font-size-16px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          margin-bottom: 40px;
        }
      }
      .recent-searches {
        @include tablet {
          text-align: center;
          font-size: 0;
        }
        span {
          font-size: $font-size-14px;
          @include small-mobile-and-mobile {
            display: block;
          }
          font-weight: $font-weight-medium;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.25px;
        }
        a {
          margin-left: 16px;
          font-size: $font-size-14px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.25px;
          &:first-of-type {
            @include small-mobile-and-mobile {
              margin: 0;
            }
          }
        }
      }
    }
  }
  div.regular-header-plugin-wrapper {
    .header-plugin-wrapper {
      padding: 0 40px;
      @include screen-max-width-mixin;
    }
    background-color: $bmo-wealth-bmoblue;
    .regular-header-plugin {
      position: relative;
      border-left: solid 1px $bmo-wealth-white;
      height: 44px;
      display: flex;
      .header-right-link {
        display: flex;
        margin-left: auto;
        .language-wrapper {
          padding-right: 27px;
          @include ie-browser {
            padding-right: 47px;
          }
          display: flex;
          align-items: center;
          a {
            @include ie-browser {
              position: relative;
            }
            display: flex;
            align-items: center;
            font-size: $font-size-14px;
            font-weight: $font-weight-medium;
            color: $bmo-wealth-white;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.2px;
            text-align: center;
            img {
              &.chevron-down {
                width: 14px;
                height: 12px;
                margin-left: 8px;
                @include ie-browser {
                  position: absolute;
                  width: 23px;
                  height: 100%;
                  top: 3px;
                  zoom: 0.67;
                  position: absolute;
                  left: 100%;
                }
              }
            }
          }
          &.active {
            a img {
              &.chevron-down {
                transform: rotate(180deg);
              }
            }
          }
        }
        .sign-in-content-wrapper {
          padding: 0;
          display: none;
          left: 0;
          min-height: 492px;
          border-radius: 4px;
          box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07), 0 0 2px 0 rgba(0, 25, 40, 0.12);
          background: $bmo-wealth-ultramarine;
          position: absolute;
          width: 100%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          z-index: 102;
          top: 100%;
          .sign-in-content {
            padding: 40px;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .signin-items {
              width: 20%;
              height: auto;
              padding: 0;

              .list-of-links {
                list-style: none;
                padding: 0;

                .cta-link {
                  margin-top: 16px;
                }
              }

              div {
                padding: 0;
                height: auto;
                width: 100%;
                margin: 0;
                &:first-child {
                  height: auto;
                  padding: 0;
                  margin: 0;
                  .sign-in-header {
                    @include bmo-font($font-size-24px, $font-weight-light);
                    height: 32px;
                    margin: 0;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    color: $bmo-wealth-white;
                  }
                }
                a {
                  span {
                    font-weight: $font-weight-bold;
                    font-size: $font-size-18px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $line-height-28px;
                    letter-spacing: normal;
                    color: $bmo-wealth-white;
                  }
                }
                a:hover {
                  span {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
        .sign-in-wrapper {
          padding-left: 24px;
          padding-right: 24px;
          background-color: $bmo-wealth-ultramarine;
          display: flex;
          align-items: center;
          a {
            display: flex;
            align-items: center;
            font-size: $font-size-14px;
            font-weight: $font-weight-medium;
            color: $bmo-wealth-white;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.2px;
            text-align: center;
          }
          .active-sub-menu {
            .accordian-wrapper {
              img {
                &.chevron-down {
                  transform: rotate(180deg);
                }
              }
            }
          }
          .accordian-wrapper {
            padding: 0;
            width: 24px;
            border-radius: 100px;
            height: 24px;
            background-color: $bmo-wealth-bmored;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            @include ie-browser {
              position: relative;
            }
            img {
              &.chevron-down {
                width: 14px;
                height: 12px;
                @include ie-browser {
                  position: absolute;
                  width: 26px;
                  height: 100%;
                  top: 5px;
                  zoom: 0.61;
                  position: absolute;
                  left: 4px;
                }
              }
            }
          }
        }
      }
      .header-left-link {
        display: flex;
        .cta-link {
          padding: 0 18px;
          border-right: solid 1px $bmo-wealth-white;
          display: flex;
          align-items: center;
          a,span {
            color: $bmo-wealth-white;
          }
          &.active-link {
            background-color: $bmo-wealth-white;
            span {
              color: $bmo-wealth-accessible-blue;
            }
          }
        }
        a span {
          font-size: $font-size-15px;
          font-weight: $font-weight-medium;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -0.2px;
          text-align: center;
        }
      }
    }
    &.marketing-page {
      display: none;
    }
  }
  .language-content-wrapper {
    @include mobile-and-tablet {
      display: none !important;
    }
    display: none;
    height: 64px;
    background: $bmo-wealth-albicant;
    .language-body {
      @include screen-max-width-mixin;
      height: 64px;
      .language-header {
        float: left;
        padding-top: 16px;
        padding-bottom: 16px;
        @include bmo-font($font-size-24px, $font-weight-light);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $bmo-wealth-slate;
      }
      .language-content {
        float: right;
        height: 64px;
        display: flex;
        align-items: center;
        div.language-block {
          display: flex;
          &:not(:last-child) {
            margin-right: 32px;
          }
          div {
            &.country-name {
              @include bmo-font($font-size-16px, $font-weight-bold);
              font-stretch: normal;
              font-style: normal;
              line-height: $line-height-32px;
              @include desktop-and-large-screen {
                line-height: $line-height-24px;
              }
              letter-spacing: 0.25px;
              color: $bmo-wealth-slate;
            }
            &.country-logo {
              width: 24px;
              height: 25px;
              margin-right: 8px;
              img {
                width: 24px;
                height: 24px;
              }
            }
            &.country-language {
              a {
                font-size: $font-size-14px;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-30px;
                @include desktop-and-large-screen {
                  line-height: $line-height-20px;
                }
                letter-spacing: 0.25px;
                margin-left: 16px;
                &.active-link {
                  text-decoration: underline;
                  color: $bmo-wealth-ultramarine;
                }
              }
            }
          }
        }
      }
    }
  }

  .secondary-header-plugin-wrapper {
    border-bottom: solid 1px $bmo-wealth-light-gray;
    background-color: $bmo-wealth-white;
    &.no-border {
      border-bottom: none;
    }
    @include mobile-and-tablet {
      display: none;
    }
    .secondary-header-plugin-body {
      @include screen-max-width-mixin;
      div.secondary-header-plugin {
        height: 95px;
        background-color: $bmo-wealth-white;
        display: flex;
        justify-content: space-between;
        position: relative;
        div {
          display: block;
          &.search-wrapper {
            max-width: 138px;
            width: 16.5%;
            display: flex;
            justify-content: center;
            align-items: center;
            .search-icon {
              margin-right: 7px;
              margin-left: 0;
            }
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              color: $bmo-wealth-slate;
              vertical-align: middle;
            }
            a {
              @include bmo-font($font-size-14px, normal);
              color: $bmo-wealth-slate;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
            }
          }
          &.secondary-header-links {
            width: 70%;
            display: flex;
            justify-content: flex-end;
            .secondary-header-menu {
              display: flex;
              &.active-sub-menu {
                a.header-link {
                  font-weight: $font-weight-bold;
                  word-break: break-word;
                  img {
                    &.chevron-down {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
              max-width: 130px;
              width: 15.78%;
              padding: 0 40x;
              justify-content: center;
              align-items: center;
              border-right: solid 1px $bmo-wealth-light-gray;
              &:first-child {
                border-left: solid 1px $bmo-wealth-light-gray;
              }
              a.header-link {
                align-items: center;
                display: flex;
                @include bmo-font($font-size-14px, normal);
                color: $bmo-wealth-slate;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                text-align: center;
                img {
                  &.chevron-down {
                    width: 14px;
                    height: 12px;
                    margin-left: 3px;
                    @include ie-browser {
                      width: 21px;
                      height: 100%;
                      zoom: 0.67;
                      position: absolute;
                      top: 4px;
                      left: 100%;
                    }
                  }
                }
                &.header-link {
                  text-align: center;
                  @include ie-browser {
                    position: relative;
                  }
                }
              }
            }
            // dropdwn
            .secondary-header-menu:nth-child(2) {
              .dropdown-content {
                height: auto;
                padding: 0  !important;
                .image-link {
                  padding: 0;
                  max-width: 600px;
                  width: calc(100% - 593px);
                  float: right;
                  height: auto;
                  >section {
                    padding: 40px 61px 40px 40px;
                    min-height: 305px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                  }
                  .rich-text-wrapper {
                    p {
                      &:first-child {
                        margin-bottom: 16px;
                        font-size: $font-size-24px;
                        font-weight: $font-weight-light;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        color: $bmo-wealth-slate;
                      }
                      margin: 0;
                      font-size: $font-size-16px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.5;
                      letter-spacing: 0.15px;
                      color: $bmo-wealth-slate;
                    }
                    a {
                      margin-bottom: 24px
                    }
                  }
                  a.cta-link {
                    @include bmo-font($font-size-14px, $font-weight-bold);
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: 0.25px;
                    color: $bmo-wealth-white;
                    margin-top: 24px;
                  }
                }
                .menu-item {
                  padding-top: 40px;
                  padding-left: 40px;
                  min-width: 450px;
                  .sub-links {
                    padding-bottom: 40px;
                    ul {
                      height: 135px;
                      list-style: none;
                      padding: 0;
                      li {
                        margin-right: 81px;
                      }
                    }
                  }
                }
              }
            }
            .secondary-header-menu {
              .dropdown-content {
                @include screen-max-width-mixin;
                text-align: -webkit-auto;
                position: absolute;
                background: $bmo-wealth-white;
                padding: 40px !important;
                height: auto;
                z-index: 101;
                top: calc(100% + 1px);
                left: 0;
                right: 0;
                box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07), 0 0 2px 0 rgba(0, 25, 40, 0.12);
                border-radius: 4px;
                border-width: 0.5px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                &.hide {
                  display: none;
                }
                &.show {
                  display: block;
                }
                .menu-item {
                  display: inline-block;
                  // width: 44%;
                  // min-width: 471px;
                  margin-right: 48px ;
                  .parent-link {
                    margin-bottom: 32px;
                    font-size: $font-size-18px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                  }
                  .dropdown-content-header {
                    @include bmo-font($font-size-24px, $font-weight-light);
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    text-align: left;
                    margin-bottom: 16px;
                    letter-spacing: normal;
                    color: $bmo-wealth-slate;
                  }
                  .sub-links {
                    margin: 0;
                    padding: 16px 0 0 0;
                    max-width: 100%;
                    ul {
                      margin: 0;
                      // display: flex;
                      // text-align: left;
                      // flex-direction: column;
                      // flex-wrap: wrap;
                      // height: 264px;
                      margin: 0;
                      list-style: none;
                      padding: 0;
                      li {
                        a.sub-pages {
                          font-size: $font-size-18px;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.56;
                          letter-spacing: normal;
                        }
                        margin-top: 16px;
                        // margin-right: 57px;
                        // max-width: 211px;
                        // min-width: 211px
                      }
                    }
                  }
                }
                .image-link {
                  max-width: none;
                  padding: 0;
                  // width: calc(100% - 544px);
                  vertical-align: top;
                  // display: inline-block;
                  // float: right;
                  padding-top: 48px;
                  @include max-width-and-below-1263 {
                    padding-left: 97px;
                    float: left;
                    width: calc(100% - 471px);
                  }
                  &>section {
                    padding-left: 40px;
                    padding-top: 40px;
                  }
                  .row {
                    margin: 0;
                    &.disclaimer {
                      @include max-width-and-below-1263 {
                        .column {
                          margin-bottom: 0;
                        }
                      }
                    }
                    .column {
                      @include max-width-and-below-1263 {
                        &:last-child {
                          .image-with-text-wrapper {
                            .content-wrapper {
                              .content p:last-child {
                                margin: 0;
                              }
                            }
                          }
                        }
                      }
                      float: left;
                      width: auto;
                      display: inline-block;
                      padding: 0;
                      @include max-width-and-below-1263 {
                        float: unset;
                        display: block;
                        max-width: unset;
                        margin-bottom: 16px;
                      }
                      &:first-child {
                        margin-right: 55px;
                        @include max-width-and-below-1263 {
                          margin-right: 0;
                          // margin-bottom: 16px;
                        }
                      }
                      &:nth-child(2) {
                        margin-right: 55px;
                      }
                      section.rich-text-main-content {
                        .rich-text-wrapper {
                          p {
                            @include bmo-font($font-size-24px, $font-weight-light);
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.33;
                            margin-bottom: 16px;
                            letter-spacing: normal;
                            max-width: 236px;
                            color: $bmo-wealth-slate;
                          }
                        }
                      }
                      section.image-with-text-wrapper {
                        padding: 0;
                        .image {
                          margin: 0;
                          .image-wrapper {
                            margin-bottom: 24px;
                            div {
                              margin: 0;
                            }
                          }
                          .col {
                            div.image-with-text-background-top-bottom-variant {
                              width: 236px;
                              height: 123px;
                              background-position: center;
                            }
                          }
                          .content-wrapper {
                            .content {
                              width: auto;
                              margin: 0;
                              ul {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                li {
                                  a {
                                    font-size: $font-size-18px;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: 1.56;
                                    letter-spacing: normal;
                                    display: block;
                                    max-width: 236px;
                                    word-break: break-word;
                                    text-decoration: none;
                                  }
                                  a:hover {
                                    text-decoration: underline;
                                  }
                                  &:not(:last-child) {
                                    margin-bottom: 16px;
                                  }
                                  &:last-child {
                                    margin-bottom: 0;
                                  }
                                }
                              }
                              p {
                                &:first-child {
                                  @include bmo-font($font-size-24px, $font-weight-light);
                                  font-stretch: normal;
                                  font-style: normal;
                                  line-height: 1.33;
                                  margin-bottom: 16px;
                                  letter-spacing: normal;
                                  max-width: 236px;
                                  color: $bmo-wealth-slate;
                                }
                                &:not(:last-child) {
                                  margin-bottom: 16px;
                                }
                                &:last-child {
                                  margin-bottom: 0;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .service-disclaimer-text {
                  display: inline-block;
                  top: 28px;
                  height: 0px;
                  position: relative;
                  p {
                    font-size: 14px;
                  }
                }
              }
            }
            &.marketing-page {
              display: none !important;
            }
          }
          &.logo-wrapper {
            align-items: center;
            padding-right: 26px;
            padding-left: 26px;
            display: flex;
            img {
              width: 285px;
              height: auto;
            }
          }
        }
      }
    }
  }
  .regular-header-wrapper-tablet {
    display: none;
    position: relative;
  }

  @include mobile-and-tablet {
    .regular-header-plugin-wrapper,
    .secondary-header-plugin {
      display: none;
    }
    .regular-header-plugin-wrapper {
      &.marketing-page {
        display: none;
      }
    }
    .regular-header-wrapper-tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $bmo-wealth-bmoblue;
      div {
        &.search-wrapper,
        &.sign-in-wrapper,
        &.hamburger-menu-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            background: none;
            border: none;
          }
        }
      }
      .regular-header-right-link {
        display: flex;
        .sign-in-wrapper {
          .profile {
            img {
              width: 18px;
              height: 18px;
            }
          }
        }
        .search-wrapper {
          .search {
            img {
              width: 19px;
              height: 19px;
            }
          }
        }
        .hamburger-menu-wrapper {
          img {
            width: 20px;
            height: 18px;
          }
        }
        &.marketing-page {
          display: none;
        }
      }
    }
  }

  @include tablet {
    .regular-header-wrapper-tablet {
      height: 80px;
      .logo-wrapper {
        padding-left: 40px;
        img {
          width: 255px;
          height: 32px;
        }
      }
      .regular-header-right-link {
        .hamburger-menu-wrapper,
        .search-wrapper,
        .sign-in-wrapper {
          width: 72px;
          height: 80px;
        }
        .search-wrapper,
        .hamburger-menu-wrapper,
        .sign-in-wrapper {
          &.active {
            background: $bmo-wealth-ultramarine;
          }
        }
        &.marketing-page {
          display: none;
        }
      }
    }
  }
  @include small-mobile-and-mobile {
    .regular-header-wrapper-tablet {
      height: 64px;
      .logo-wrapper {
        padding-left: 16px;
        img {
          width: 176px;
          height: 22px;
        }
      }
      .regular-header-right-link {
        .hamburger-menu-wrapper,
        .search-wrapper,
        .sign-in-wrapper {
          width: 56px;
          height: 64px;
          &.active {
            background: $bmo-wealth-ultramarine;
          }
        }
        &.marketing-page {
          display: none;
        }
      }
    }
  }
}

//sign-in-menu-modal
@include mobile-and-tablet {
  .sign-in-menu-modal {
    background: $bmo-wealth-ultramarine;
    position: absolute;
    display: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    right: 0;
    top: 100%;
    width: 384px;
    @include small-mobile-and-mobile {
     width: 100%;
    }
    z-index: 1;
    height: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07), 0 0 2px 0 rgba(0, 25, 40, 0.12);
    .sign-in-sub-menu {
      display: none;
      .modal-header {
        .sub-header-link {
          img {
            width: 21px;
            height: 21px;
            margin-bottom: 2px;
          }
        }
        padding-left: 18px !important;
        @include small-mobile-and-mobile {
          padding-left: 12px !important;
        }
        text-align: left !important;
        .header-text {
          @include bmo-font($font-size-22px, $font-weight-bold);
          padding-left: 5px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.27;
          letter-spacing: 0.21px;
          color: $bmo-wealth-white
        }
      }
      .modal-body {
        a {
          font-size: $font-size-22px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.27;
          letter-spacing: 0.21px;
          color: $bmo-wealth-white
        }
      }
    }
    .modal-content {
      background: $bmo-wealth-ultramarine;
      border: none;
      div {
        display: block !important;
        span {
          color: $bmo-wealth-white;
          font-size: $font-size-20px;
          &:first-child:not(.fa) {
            @include bmo-font($font-size-22px, $font-weight-bold);
            font-stretch: normal;
            font-style: normal;
            line-height: 1.27;
            letter-spacing: 0.21px;
          }
        }
        &.modal-header {
          height: 92px;
          padding: 32px;
          border-bottom: 2px solid $bmo-wealth-accessible-blue;
          text-align: center;
        }
        &.modal-body {
          div {
            display: flex !important;
            align-items: center;
            &:first-child {
              margin-top: 32px;
            }
            margin-top: 24px;
            span:first-child {
              padding-right: 11px;
            }
            img {
              width: 21px;
              height: 21px;
              margin-bottom: 3px;
            }
          }
          padding-top: 0;
          padding-bottom: 40px;
          @include tablet {
            padding-left: 24px;
          }
          @include small-mobile-and-mobile {
            padding-left: 16px;
          }
        }
      }
    }
  }
}

@include desktop-and-large-screen {
  .hamburger-modal {
    display: none !important;
    .hamburger-modal-wrapper {
      display: none;
    }
  }
}

@include mobile-and-tablet {
  .hamburger-modal {
    .language-wrapper {
      border-top: 2px solid $bmo-wealth-light-gray;
      padding: 32px 24px 40px 24px;
      @include small-mobile-and-mobile {
        padding-left: 16px;
      }
      .language-block {
        margin-top: 24px;
      }
      &>div{
        &.language-mobile-header {
          font-weight: $font-weight-light;
          @include bmo-font($font-size-24px, $font-weight-light);
          font-stretch: normal;
          font-style: normal;
          line-height: $line-height-32px;
          display: inline-block;
          letter-spacing: normal;
          color: $bmo-wealth-slate;
        }
        &:not(:first-child) {
          display: flex;
          align-items: center;
        }
        .country-logo {
          margin-bottom: 4px;
          margin-right: 8px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .country-name {
          @include bmo-font($font-size-22px, $font-weight-bold);
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: 0.35px;
          margin-right: 16px;
          color: $bmo-wealth-slate;
        }
        .country-language {
          display: flex;
          a {
            font-size: $font-size-22px;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-30px;
            letter-spacing: 0.21px;
            margin-right: 16px;
            &.active-link {
              text-decoration: underline;
              color: $bmo-wealth-ultramarine;
            }
          }
        }
      }
    }
    .modal-body {
      padding: 0;
      .header-menu-mobile-wrapper {
        margin-top: 32px;
        padding-bottom: 16px;
        .secondary-header-menu {
          a {
            margin-left: 24px;
            margin-bottom: 24px;
          }
        }
      }
      @include small-mobile-and-mobile {
        .secondary-header-menu {
          a {
            margin-left: 16px !important;
          }
        }
      }
    }
    .dropdown-content {
      margin: 0;
      .child-header {
        display: flex;
        border-bottom: 2px solid $bmo-wealth-light-gray;
        justify-content: left;
        align-items: center;
        height: 92px;
        @include tablet {
          padding-left: 22px;
          padding-top: 6px;
        }
        @include small-mobile-and-mobile {
          padding-left: 15px;
          padding-top: 3px;
        }
        span.fa {
          margin-right: 12px;
          font-size: $font-size-20px;
          color: $bmo-wealth-accessible-blue;
        }
        a.child-header-link {
          font-size: $font-size-22px;
          font-stretch: normal;
          font-style: normal;
          line-height: $line-height-28px;
          letter-spacing: 0.21px;
          display: inline-block;
          color: $bmo-wealth-accessible-blue;
          img {
            width: 20px;
            height: 20px;
            margin-bottom: 2px;
          }
        }
      }
      .menu-item {
        margin-bottom: 24px;
        .sub-links {
          padding: 0;
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
          }
        }
      }
      display: none;
      .image-link {
        padding: 0;
        .header-menu {
          div.column:last-child {
            .content-wrapper {
              li:last-child {
                a:last-child {
                  margin-bottom: 40px;
                }
              }
            }
          }
        }

        .row {
          display: block;
          margin: 0;
        }
        @include small-mobile-and-mobile {
          .rich-text-main-content {
            padding: 40px 32px 24px;
            .rich-text-wrapper {
              p {
                &:first-child {
                  @include bmo-font($font-size-24px, $font-weight-light);
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  margin-bottom: 16px;
                  letter-spacing: normal;
                }
                font-size: $font-size-16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 0.15px;
                margin: 0;
                color: $bmo-wealth-slate;
              }
            }
          }
          .cta-button-secondary {
            padding-left: 32px;
            padding-bottom: 40px;
            a.cta-link {
              width: 232px;
              height: 56px;
              border-radius: 28px;
              @include bmo-font($font-size-14px, $font-weight-bold);
              text-align: center;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: 0.25px;
            }
          }
        }
        @include tablet {
          .rich-text-main-content {
            padding: 40px 40px 24px 32px;
            .rich-text-wrapper {
              p {
                &:first-child {
                  @include bmo-font($font-size-24px, $font-weight-light);
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  margin-bottom: 16px;
                  letter-spacing: normal;
                }
                font-size: $font-size-16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 0.15px;
                margin: 0;
                color: $bmo-wealth-slate;
              }
            }
          }
          .cta-button-secondary {
            padding-left: 32px;
            padding-bottom: 40px;
            a.cta-link {
              width: 152px;
              padding: 15px;
              height: 56px;
              border-radius: 28px;
              text-align: center;
              @include bmo-font($font-size-14px, $font-weight-bold);
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: 0.25px;
            }
          }
        }
        .column {
          display: block;
          max-width: 100%;
          padding: 0;
          .rich-text-main-content {
            display: none;
          }
          &.mobile-column-hr {
            border-top: 2px solid $bmo-wealth-light-gray;
            padding-top: 24px;
          }
        }
        .image-with-text-wrapper {
          padding: 0;
          .image {
            margin: 0;
            display: block;
            .image-wrapper {
              display: none;
            }
            .content-wrapper {
              margin: 0;
              .content {
                margin: 0;
                ul {
                  margin: 0;
                  padding: 0;
                  li {
                    a {
                      margin-bottom: 24px;
                      display: inline-block;
                      font-size: $font-size-22px;
                      @include small-mobile-and-mobile {
                        margin-left: 16px;
                      }
                      @include tablet {
                        margin-left: 24px;
                      }
                      font-stretch: normal;
                      font-style: normal;
                      line-height: $line-height-28px;
                      letter-spacing: 0.21px;
                    }
                  }
                }
                p {
                  &:first-child {
                    display: none;
                  }
                  margin: 0;
                }
              }
            }
          }
        }
      }

    }
    .main-menu.mobile {
      .modal-header {
        justify-content: center;
      }
    }
    .child-menu.mobile {
      &.no-body-margin {
        .modal-body {
          margin: 0;
        }
      }
      .modal-header {
        .secondary-header-menu-header {
          padding: 0;
          padding-top: 3px;
          margin: 0;
        }
        justify-content: left;
        border-radius: 0;
        @include small-mobile-and-mobile {
          padding-left: 14px;
        }
        @include tablet {
          padding-left: 21px;
        }
      }
    }

    .modal-header {
      border-bottom: 2px solid $bmo-wealth-light-gray;
      display: flex;
      align-items: center;
      height: 92px;
      @include bmo-font($font-size-22px, $font-weight-bold);
      font-stretch: normal;
      color: $bmo-wealth-accessible-blue;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: 0.21px;
    }
    .secondary-header-menu-header {
     .top-header-link {
       img {
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
       }
     }
    }
    .secondary-header-menu {
      img {
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
      }
    }
    .secondary-header-menu,
    .secondary-header-menu-header {
      a {
        font-size: $font-size-22px;
        font-stretch: normal;
        font-style: normal;
        display: inline-block;
        line-height: $line-height-28px;
        letter-spacing: 0.21px;
      }
    }
    .menu-item {
      margin-bottom: 24px;
      .parent-link-wrapper {
         @include mobile-and-tablet {
           margin-bottom: 48px;
         }
        .parent-link {
          display: inline-block;
          text-align: left;
          margin-top: 32px;
          font-size: $font-size-22px;
          font-stretch: normal;
          @include small-mobile-and-mobile {
            margin-left: 16px;
          }
          @include tablet {
            margin-left: 24px;
          }
          font-style: normal;
          line-height: $line-height-28px;
          letter-spacing: 0.21px;
        }
      }
      .sub-pages {
        @include small-mobile-and-mobile {
          margin-left: 16px;
        }
        @include tablet {
          margin-left: 24px;
        }
        margin-bottom: 24px;
        display: inline-block;
        @include bmo-font($font-size-22px, $font-weight-bold);
        font-stretch: normal;
        font-style: normal;
        line-height: $line-height-28px;
        letter-spacing: 0.21px;
        color: $bmo-wealth-accessible-blue;
      }
    }

    .hamburger-modal-wrapper {
      border-top-style: none;
      border-right-width: 0 !important;
      border-top-left-radius: 0 !important;
      border-top-width: 0 !important;
      border-top-right-radius: 0 !important;
      @include small-mobile-and-mobile {
        border-width: 0 !important;
      }
      border-radius: 4px;
      @include mobile-and-tablet {
        box-shadow: 0 4px 2px 2px rgba(0, 25, 40, 0.10), 0 0 2px 0 rgba(0, 25, 40, 0.16);
        border-width: 0;
      }
      box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07), 0 0 2px 0 rgba(0, 25, 40, 0.12);
      border-style: solid;
      border-width: 0.5px;
      border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, var(--white), var(--white)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    //main menu
    .main-menu.mobile {
      .modal-body {
        padding: 0;
        .header-main-menu-link {
          padding: 32px 0 32px 24px;
          @include small-mobile-and-mobile {
            padding-left: 16px;
          }
          &>div {
            &:not(:last-child) {
              margin-bottom: 24px;
            }
          }
          div {
            img {
              width: 20px;
              height: 20px;
              margin-bottom: 6px;
            }
            div.cta-link {
              display: inline-block;
              a {
                font-size: $font-size-22px;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.21px;
                display: inline-block;
                line-height: $line-height-28px;
              }
            }
          }
        }
      }
    }
  }
}

.hamburger-modal {
  &.hide {
    display: none;
  }
  display: block;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: auto;
  @include tablet {
    width: 384px;
    right: 0;
  }
}
#hamburger-menu-modal {
  .hamburger-modal-wrapper {
    min-width: 98%;
    height: 100%;
    .modal-content {
      height: 100%;
    }
  }
}

.sub-menu{
  .sub-menu-wrapper {
    @include hero-banner-shadow-mixin;
    .container-background-primary-gray.cms-plugin {
      height: 100%;
    }
  }
  .menu-item {
    font-size: $font-size-24px;
    font-weight: $font-weight-light;
    padding: 40px 0 0 40px;
  }
  a {
    font-size: $font-size-18px;
    padding: 16px 40px;
    display: block;
  }
}
.search-help-container {
  background: $bmo-wealth-albicant;
  padding: 40px;
  display: flex;
  @include tablet {
    padding: 40px 24px;
    justify-content: space-between;
  }
  @include small-mobile-and-mobile {
    padding: 40px 16px;
    justify-content: space-between;
    display: block;
  }
  .contact-us-container {
    min-width: 339px;
    width: 339px;
    @include small-mobile-and-mobile {
      width: 100%;
      min-width: auto;
    }
    h2, p {
      &:first-child {
        margin: 0;
        font-size: $font-size-24px;
        color: $bmo-wealth-slate;
        font-weight: $font-weight-light;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
      }
      margin: 16px 0 0 0;
      font-size: $font-size-16px;
      font-weight: normal;
      font-stretch: normal;
      color: $bmo-wealth-slate;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.15px;
    }
    .cta-link {
      margin: 24px 0 0 0;
    }
    .contact-us-btn {
      display: flex;
      .contact-us-link {
        &:hover {
          border-color: $bmo-wealth-ultramarine;
          background-color: $bmo-wealth-ultramarine;
        }
        @include small-mobile-and-mobile  {
          padding: 16px 72px;
        }
        border-radius: 28px;
        background: $bmo-wealth-accessible-blue;
        color: $bmo-wealth-white;
        padding: 16px 32px;
        font-size: $font-size-14px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.25px;
      }
    }
  }
  .recent-search-container {
    @include small-mobile-and-mobile {
      width: 100%;
      margin-left: 0;
      margin-top: 40px;
    }
    margin-left: 31px;
    width: calc(100% - 339px);
    .recent-search-header {
      margin: 0;
      font-size: $font-size-24px;
      color: $bmo-wealth-slate;
      font-weight: $font-weight-light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }
    .recent-search-link-container {
      width: 100%;
      margin-top: 16px;
      @include small-mobile-and-mobile {
        margin-top: 0;
      }
      a {
        @include mobile-and-tablet {
          display: block;
          margin-bottom: 16px;
          margin-right: 0;
          @include small-mobile-and-mobile {
            margin-top: 16px;
            margin-bottom: 0;
          }
        }
        margin-right: 24px;
        margin-bottom: 16px;
        font-size: $font-size-18px;
        vertical-align: top;
        display: inline-block;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
      }
    }
  }
}

.service-menu {
  float: left;
}

.needs-menu {
  display: flex;
  flex-flow: wrap column;
}

.who-we-serve .image-link{
  margin-left: 50% !important;
  margin-top: -25% !important;
}

section.rich-text-main-content {
  .rich-text-wrapper {
    img{
      border-radius: 4px;
    }
  }}
