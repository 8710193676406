.contact-us-form-row {
    padding-bottom: 48px;

    display: flex;
    @include tablet-and-desktop {
        flex-wrap: nowrap;
    }
    @include small-mobile-and-mobile {
        display: block;
    }
    @include tablet {
        padding-bottom: 64px;
    }
    @include desktop-and-large-screen {
        padding-bottom: 80px;
    }
    .form-description-column {
        margin-right: 135px;
        @include tablet {
            margin-right: 24px;
        }
        @include small-mobile-and-mobile {
            margin: 0;
        }
        section.image-with-text-wrapper
        {
            &:first-of-type {
                .icon {
                    .content-wrapper {
                        .content {
                            h2 {
                                margin-bottom: 22px;
                            }
                            p {
                                &:first-of-type {
                                    font-family: $font-family;
                                    font-size: $font-size-14px;
                                    font-weight: $font-weight-bold;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: $line-height-20px;
                                    letter-spacing: 0.25px;
                                    color: $bmo-wealth-accessible-blue;
                                    margin-bottom: 22px;
                                }
                            }
                        }
                    }
                }
            }
            &:nth-of-type(2) {
                .icon {
                    .content-wrapper {
                        .content {
                            p {
                                margin-bottom: 30px;
                                &:first-of-type {
                                    margin-bottom: 38px;
                                    @include mobile-and-tablet {
                                        margin-bottom: 30px;
                                    }
                                }
                                &:nth-of-type(even) {
                                    margin-bottom: 0;
                                }
                                &:nth-of-type(odd):not(:first-of-type) {
                                    font-family: $font-family;
                                    font-size: $font-size-14px;
                                    font-weight: $font-weight-normal;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: $line-height-24px;
                                    letter-spacing: 0.25px;

                                }
                                a {
                                    margin-bottom: 10px;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .form-description-column,
    .form-column {
        padding: 0;
        width: 50%;
        flex: 1 1 auto;
        max-width: 482px;
        @include tablet {
            max-width: 50%;
        }
        @include small-mobile-and-mobile {
            width: 100%;
            max-width: 100%;
        }
        .image-with-text-wrapper {
            .icon {
                max-width: 100%;
                margin: 0;
                .image-icon-wrapper {
                    padding: 0 0 24px 0;
                    img {
                        height: 72px;
                        width: 96px;
                    }
                }
                .content-wrapper {
                    margin-bottom: 48px;
                    @include small-mobile-and-mobile {
                        margin-bottom: 40px;
                    }
                    .content {
                        max-width: 100%;
                        h4 {
                            margin-bottom: 16px;
                        }
                        h4,
                        strong {
                            font-family: $font-family;
                            font-size: $font-size-24px;
                            font-weight: $font-weight-bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: $line-height-32px;
                            letter-spacing: normal;
                        }
                        p {
                            &:last-child {
                                margin-bottom: 0;
                            }
                            font-family: $font-family;
                            font-size: $font-size-16px;
                            font-weight: $font-weight-normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: $line-height-24px;
                            letter-spacing: 0.15px;
                            color: $bmo-wealth-slate;
                            a {
                                font-family: $font-family;
                                font-size: $font-size-14px;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: $line-height-20px;
                                letter-spacing: 0.25px;
                            }
                        }
                    }
                }
            }
        }
    }
    .form-column {
        .image-with-text-wrapper {
            .icon {
                .content-wrapper {
                    margin-bottom: 32px;
                    .content {
                        max-width: 100%;
                        p {
                            &:first-of-type {
                                margin-bottom: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.contact-form {
    #contact-us-form {
        .captcha-error {
            display: none;
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            color: $bmo-wealth-negative;
            margin-bottom: 5px;
        }
        @include small-mobile-and-mobile {
            margin: 0;
            padding: 0;
        }
        @include tablet {
            margin: 0;
            padding: 0;
        }
        .g-recaptcha {
            margin-top: 40px;
            margin-bottom: -10px;
        }
        .terms-and-condition {
            font-size: $font-size-16px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;
            color: $bmo-wealth-slate;
            width: 100%;
            @include small-mobile-and-mobile {
                width: 100%;
            }
            @include tablet {
                width: 348px
            }
        }

        .success-message {
            .check-circle {
                width: 20px;
                height: 20px;
                font-size: $font-size-23px;
                margin-right: 10px;
                background-image: url('/static/assets/svgs/subscribe-modal-svg/success-green.svg');
                margin-top: 2px;
                float: left;
            }
            display: none;
            margin-bottom: 40px;
            width: 100%;
            @include small-mobile-and-mobile {
                width: 100%;
            }
            @include tablet {
                width: 348px
            }
            p {
                display: inline-block;
                width: 450px;
                font-size: $font-size-16px;
                font-weight: $font-weight-normal;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-24px;
                letter-spacing: 0.15px;
                color: $bmo-wealth-slate;
                @include small-mobile-and-mobile {
                    width: 311px;
                }
                @include tablet {
                    width: 316px
                }
                a {
                    color: $bmo-wealth-accessible-blue;
                    font-weight: $font-weight-bold;
                    letter-spacing: 0.29px;
                    font-size: $font-size-16px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $line-height-24px;
                }
            }
        }
    }

}
.tab-container {
    margin-top: 16px;
    margin-bottom: 16px;
    @include small-mobile-and-mobile {
        margin-bottom: 24px;
    }
    .tab-head-wrapper {
        display: inline-flex;
        height: auto;
        align-items: center;
        margin-bottom: 26px;
        a {
            text-decoration: none;
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-bold;
            font-stretch: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            background-color: $bmo-wealth-white;
            color: $bmo-wealth-accessible-blue;
            align-items: center;
            display: inline-flex;
            justify-content: center;
            padding: 8px 16px;
            border-radius: 4px;
            border: solid 2px $bmo-wealth-Granite;
            margin-right: 16px;
            &.active {
                background-color: $bmo-wealth-accessible-blue;
                color: $bmo-wealth-white;
                border: none;
            }
            &:last-child {
                margin: 0;
            }
        }
        span.tab-head {
            font-family: $font-family;
            font-size: $font-size-16px;
            margin-right: 18px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            color: $bmo-wealth-slate;
            line-height: $line-height-16px;
            letter-spacing: 0.15px;
        }
    }
}
.info-icon {
    .help-info-tooltip {
        width: 296px;
        position: absolute;
        z-index: 3;
        right: 0;
        display: none;
        top: 0;
        .help-info-tooltip-content {
            padding: 16px 48px 24px 16px;
            width: 296px;
            position: absolute;
            box-shadow: 0 2px 4px 0 $bmo-wealth-light-gray-border;
            background-color: $bmo-wealth-white;
            font-size: $font-size-14px;
            line-height: $line-height-24px;
            letter-spacing: 0.25px;
            color: $bmo-wealth-slate;
        }
        a.help-info-tooltip-close-icon {
          background-image: url('/static/assets/svgs/contact-svg/close-granite.svg');
          right: 20px;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 16px;
          z-index: 1;
          padding: 0;
          background-color: inherit;
          border: none;
        }
    }
}

.form-field.form-field-select {
    width: 100%;
    @include tablet {
        width: 348px
    }
    @include small-mobile-and-mobile {
        width: 100%;
    }
    &.help-icon-active {
        .form-label-group {
            width: calc(100% - 30px);
        }
    }
    margin-bottom: 24px;
    position: relative;
    &.field-disabled {
        .form-label-group {
            input {
                color: $bmo-wealth-Granite;
            }
            label.slide-label {
                pointer-events: none;
                &:after {
                    background: $bmo-wealth-Granite;
                }
                .label-text {
                    color: $bmo-wealth-Granite;
                }
            }

        }
    }
    &.error-message-active {
        .form-label-group {
            .error-message {
                display: flex;
                opacity: 1;
                align-items: center;
            }
            .input-helper-text {
                margin-top: 0;
            }
            label.slide-label {
                &:before {
                    background:$bmo-wealth-negative !important;
                }
                &:after {
                    background:$bmo-wealth-negative;
                }
            }
        }
    }
    &.help-icon-active {
        .info-icon {
            position: absolute;
            display: block;
            right: 0;
            top: -2px;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
    &.helper-text-active {
        .form-label-group {
            .input-helper-text {
                display: block;
            }
        }
    }
    .info-icon {
        display: none
    }
    .form-label-group {
        position: relative;
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        width: 100%;
        select::-ms-expand {
            display: none;
        }
        select:focus + label.slide-label span.label-text:before {
            padding: 0px;
            font-size: $font-size-14px;
            line-height: $line-height-14px;
            margin-top: -4px;
        }
        select:focus + label.slide-label span.label-text {
            padding: 0px;
            font-size: $font-size-14px;
            line-height: $line-height-14px;
            margin-top: -4px;
        }
        select {
            background-image: url('/static/assets/svgs/accordion-svg/chevron-down.svg') !important;
            background-position: calc(100% - 10px)!important;
            background-size: 14px 17px !important;
            background-repeat: no-repeat !important;
        }
        select:focus + label.slide-label:before {
            visibility: visible;
            -webkit-transform: scaleX(1) !important;
            transform: scaleX(1) !important;
            background: $bmo-wealth-accessible-blue !important;
        }
        select:not(:focus) + label.slide-label.active-label:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            transition: all 0.3s linear;
            background: $bmo-wealth-positive;
        }
        select:focus + label.slide-label.active-label:before {
            background: $bmo-wealth-positive;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
        }
        select {
            font-size: $font-size-18px;
            color: $bmo-wealth-slate;
            width: 100%;
            position: relative;
            width: 100%;
            border: none;
            background: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;
            padding: 0;
            @include mozilla {
                left: -4px;
            }
            option {
                font-size: $font-size-15px,
            }
        }
        .error-message {
            width: 100%;
            min-width: 150px;
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            color: $bmo-wealth-negative;
            background-repeat: no-repeat;
            background-position: left top;
            opacity: 0;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
            margin-top: 12px;
            display: none;
            margin-bottom: 6px;
            img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }
        .input-helper-text {
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            margin-top: 12px;
            display: block;
            color: $bmo-wealth-Granite;
        }
        label.slide-label {
            z-index: 2;
            position: absolute;
            top: -20px;
            right: 0px;
            left: 0px;
            pointer-events: none;
            height: 42px;
            cursor: pointer;
            border-radius: 4px 0px 0px 4px;
            &:before {
                z-index: 4;
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                left: 0px;
                background: $bmo-wealth-accessible-blue;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s linear;
                transition: all 0.3s linear;
                top: 46px;
            }
            &:after {
                z-index: 3;
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                left: 0px;
                background: $bmo-wealth-light-gray;
                top: 46px;
            }
            span.label-text {
                display: block;
                padding: 0;
                margin-top: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-family: $font-family;
                font-size: $font-size-14px;
                font-weight: $font-weight-normal;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-14px;
                letter-spacing: 0.25px;
                color: $bmo-wealth-accessible-blue;
                -webkit-transition: 0.3s ease-out;
                transition: 0.3s ease-out;
            }
            &.active-label {
                span.label-text {
                    padding: 0;
                    font-size: $font-size-14px;
                    letter-spacing: 0.25px;
                    line-height: $line-height-14px;
                    margin-top: 0;
                    font-weight: $font-weight-medium;
                }
            }
        }
    }
}

.tab-container {
    .form-field.form-field-select {
        margin-top: 22px;
    }
}

.form-field.text-area {
    width: 100%;
    margin-top: 36px;
    @include small-mobile-and-mobile {
        width: 100%;
    }
    @include tablet {
        width: 348px
    }
    &.help-icon-active {
        .form-label-group {
            width: calc(100% - 30px);
            .slide-label {
                width: calc(100% - 30px);
            }
        }
    }
    margin-bottom: 12px;
    position: relative;
    &.field-disabled {
        .form-label-group {
            input {
                color: $bmo-wealth-Granite;
            }
            label.slide-label {
                pointer-events: none;
                &:after {
                    background: $bmo-wealth-Granite;
                }
                .label-text {
                    color: $bmo-wealth-Granite;
                }
            }

        }
    }
    &.help-icon-active {
        .info-icon {
            position: absolute;
            display: block;
            right: 0;
            top: 2px;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
    &.error-message-active {
        .form-label-group {
            .error-message {
                display: flex;
                opacity: 1;
                align-items: center;
            }
            .input-helper-text {
                margin-top: 0;
            }
            label.slide-label {
                &:before {
                    background:$bmo-wealth-negative !important;
                }
                &:after {
                    background:$bmo-wealth-negative;
                }
            }
        }
    }
    &.helper-text-active {
        .form-label-group {
            .input-helper-text {
                display: block;
            }
        }
    }
    .info-icon {
        display: none
    }
    .form-label-group {
        textarea:focus + label.slide-label:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            background: $bmo-wealth-accessible-blue;
            top: unset;
        }
        textarea:not(:focus) + label.slide-label.active-label:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            transition: all 0.3s linear;
            background: $bmo-wealth-positive
        }
        textarea:focus+ label.slide-label.active-label:before {
            background: $bmo-wealth-positive;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            top: unset;
        }
        textarea:focus + label.slide-label span.label-text {
            padding: 0px;
            font-size: $font-size-14px;
            line-height: $line-height-14px;
            font-weight: $font-weight-medium;
            margin-top: -9px;
        }
        
        label.slide-label {
            line-height: 1.2;
            z-index: 2;
            position: absolute;
            top: 0px;
            right: 0px;
            left: 0px;
            pointer-events: none;
            height: 33px;
            cursor: pointer;
            border-radius: 4px 0px 0px 4px;
            &:before {
                z-index: 4;
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                left: 0px;
                background: $bmo-wealth-accessible-blue;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s linear;
                transition: all 0.3s linear;
                top: unset;
            }
            &.active-label:after {
                background: $bmo-wealth-accessible-blue;
            }
            &:after {
                z-index: 3;
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                left: 0px;
                background: $bmo-wealth-light-gray;
                top: unset;
            }
            span.label-text {
                display: block;
                top: -10px;
                position: absolute;
                padding: 14px 0px 0px 0px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-family: $font-family;
                font-size: $font-size-18px;
                font-weight: $font-weight-normal;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-24px;
                letter-spacing: 0.15px;
                color: $bmo-wealth-accessible-blue;
                -webkit-transition: 0.3s ease-out;
                transition: 0.3s ease-out;
            }
            &.active-label {
                span.label-text {
                    padding: 0px;
                    font-size: $font-size-14px;
                    line-height: $line-height-14px;
                    margin-top: -9px;
                    font-weight: $font-weight-medium;
                }
            }
            &.filled {
                &.before {
                background:$bmo-wealth-gray;
                }
            }
            &.error {
                &.before {
                background:$bmo-wealth-negative;
                }
            }
        }
        .error-message {
            width: 100%;
            min-width: 150px;
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            color: $bmo-wealth-negative;
            background-repeat: no-repeat;
            background-position: left top;
            opacity: 0;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
            margin-top: 17px;
            display: none;
            margin-bottom: 6px;
            img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }
        .input-helper-text {
            color: $bmo-wealth-Granite;
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            margin-top: 8px;
            display: block;
        }
        textarea {
            font-family: $font-family;
            font-size: $font-size-18px;
            font-weight: $font-weight-normal;
            color: $bmo-wealth-slate;
            width: 100%;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;
            margin: 0;
            padding: 0px;
            height: unset;
            overflow-y: auto;
            line-height: normal;
            white-space: pre-wrap;
            background-color: transparent;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            resize: none;
            overflow-x: hidden;
            word-wrap: break-word !important;
            border: none;
            &:focus {
                outline: none;
            }
        }
    }
}

.form-field.input {
    width: 100%;
    @include small-mobile-and-mobile {
        width: 100%;
    }
    @include tablet {
        width: 348px
    }

    .clear-icon {
        display: none;
        position: absolute;
        border: none;
        background-repeat: no-repeat;
        background-color: transparent;
        width: 24px;
        height: 24px;
        right: 10px;
        bottom: 34px;
        background-image: url('/static/assets/svgs/header-svg/close-granite.svg');
        &:hover {
            cursor: pointer;
        }
    }

    &.help-icon-active {
        .form-label-group {
            width: calc(100% - 30px);
        }
    }
    margin-bottom: 12px;
    position: relative;
    &.field-disabled {
        .form-label-group {
            input {
                color: $bmo-wealth-Granite;
            }
            label.slide-label {
                pointer-events: none;
                &:after {
                    background: $bmo-wealth-Granite;
                }
                .label-text {
                    color: $bmo-wealth-Granite;
                }
            }

        }
    }
    &.help-icon-active {
        .info-icon {
            position: absolute;
            display: block;
            right: 0;
            top: 16px;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
    &.error-message-active {
        .form-label-group {
            .error-message {
                display: flex;
                opacity: 1;
                align-items: center;
            }
            .input-helper-text {
                margin-top: 0;
            }
            label.slide-label {
                &:before {
                    background:$bmo-wealth-negative !important;
                }
                &:after {
                    background:$bmo-wealth-negative;
                }
            }
        }
    }
    &.helper-text-active {
        .form-label-group {
            .input-helper-text {
                display: block;
            }
        }
    }
    .info-icon {
        display: none
    }
    .form-label-group {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding: 0;
        input[type="text"]:focus + label.slide-label:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            background: $bmo-wealth-accessible-blue;
        }
        input[type="text"]:not(:focus) + label.slide-label.active-label:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            transition: all 0.3s linear;
            background: $bmo-wealth-positive
        }
        input[type="text"]:focus+ label.slide-label.active-label:before {
            background: $bmo-wealth-positive;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
        }
        input[type="text"]:focus + label.slide-label span.label-text {
            padding: 0px;
            font-size: $font-size-14px;
            line-height: $line-height-14px;
            font-weight: $font-weight-medium;
            margin-top: -9px;
        }
        input {
            font-family: $font-family;
            font-size: $font-size-18px;
            font-weight: $font-weight-normal;
            color: $bmo-wealth-slate;
            width: 100%;
            height: 24px;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;
            margin: 0;
            padding: 0px;
            white-space: nowrap;
            border: none;
            background-color: transparent;
            transition: all 0.3s ease-out;
            margin-top: 10px;
            &::-ms-clear {
                display: none;
            }
            &:focus {
                vertical-align: middle;
            }
            &::-webkit-input-placeholder {
                transition: color 300ms ease;
                vertical-align: middle;
            }
            &:not(:focus)::-webkit-input-placeholder {
                color: transparent;
            }
        }
        label.slide-label {
            z-index: 2;
            position: absolute;
            top: 0px;
            right: 0px;
            left: 0px;
            pointer-events: none;
            height: 24px;
            cursor: pointer;
            border-radius: 4px 0px 0px 4px;
            margin: 0;
            &:before {
                z-index: 4;
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                left: 0px;
                background: $bmo-wealth-accessible-blue;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s linear;
                transition: all 0.3s linear;
                top: 41px;
            }
            &.active-label:after {
                background: $bmo-wealth-accessible-blue;
            }
            &:after {
                z-index: 3;
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0px;
                left: 0px;
                background: $bmo-wealth-light-gray;
                top: 41px;
            }
            span.label-text {
                display: block;
                padding: 14px 0px 0px 0px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-family: $font-family;
                font-size: $font-size-18px;
                font-weight: $font-weight-normal;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-24px;
                letter-spacing: 0.15px;
                color: $bmo-wealth-accessible-blue;
                -webkit-transition: 0.3s ease-out;
                transition: 0.3s ease-out;
            }
            &.active-label {
                span.label-text {
                    padding: 0px;
                    font-size: $font-size-14px;
                    line-height: $line-height-14px;
                    margin-top: -9px;
                    font-weight: $font-weight-medium;
                }
            }
            &.filled {
                &.before {
                background:$bmo-wealth-gray;
                }
            }
            &.error {
                &.before {
                background:$bmo-wealth-negative;
                }
            }
        }
        .error-message {
            width: 100%;
            min-width: 150px;
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            color: $bmo-wealth-negative;
            background-repeat: no-repeat;
            background-position: left top;
            opacity: 0;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
            margin-top: 17px;
            display: none;
            margin-bottom: 6px;
            img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }
        .input-helper-text {
            color: $bmo-wealth-Granite;
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            margin-top: 17px;
            display: block;
        }
    }
}
.checkbox-container {
    @include small-mobile-and-mobile {
        margin-bottom: 44px;
    }
    span.header-text {
        font-family: $font-family;
        font-size: $font-size-16px;
        font-weight: $font-weight-normal;
        font-stretch: normal;
        font-style: normal;
        line-height: $line-height-24px;
        letter-spacing: 0.15px;
        text-align: left;
    }
    .input-wrap {
        padding-left: 29px;
        padding-top: 27px;
        input {
            display: block;
        }

        input[type="checkbox"] {
            position: relative;
            top: 26px;
            left: -28px;
            margin: 0px;
            width: 24px;
            height: 5px;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            border: 2px solid $bmo-wealth-Granite;
            border-radius: 4px;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            opacity: 0;
            &:first-of-type {
                margin-top: -24px;
            }
            &:focus {
                border: 2px solid $bmo-wealth-ultramarine;
                padding-bottom: 0;
                -webkit-box-shadow: 0 0 2px 1px rgba(115, 195, 235, 0.65);
                box-shadow: 0 0 2px 1px rgba(115, 195, 235, 0.65);
            }

        }
        input[type="checkbox"]:checked+label:before {
            content: '';
            background-color: $bmo-wealth-accessible-blue;
            background-image: url('/static/assets/svgs/contact-svg/checkmark-white.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 15px;
            border-color: $bmo-wealth-accessible-blue;
            cursor: pointer;
            display: block;
            border: 2px solid $bmo-wealth-accessible-blue;
            padding-bottom: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            &:focus+label:before {
                border: 2px solid $bmo-wealth-azure;
            }
        }
        input[type="checkbox"]+label:before {
            content: '';
            position: absolute;
            top: 0px;
            left: -40px;
            display: inline-block;
            vertical-align: middle;
            margin: 0px;
            width: 24px;
            height: 24px;
            border: 2px solid $bmo-wealth-Granite;
            border-radius: 4px;
            box-sizing: border-box;
            &:hover {
                cursor: pointer;
            }
        }
        // Focus
        input[type="checkbox"]:focus+label:before {
            border: 2px solid $bmo-wealth-cerulean;
            box-shadow: 0 0 2px 1px rgba(115, 195, 235, 0.65);
        }
        input[type="checkbox"]:checked:focus+label:before {
            border: 2px solid $bmo-wealth-cerulean;
            box-shadow: 0 0 2px 1px rgba(115, 195, 235, 0.65);
        }
        // Error
        input[type="checkbox"].has-error+label:before {
            border: 2px solid $bmo-wealth-negative;
        }
        // Disabled
        input[type="checkbox"]:disabled+label:before {
            background-color: $bmo-wealth-light-gray;
            border: 2px solid $bmo-wealth-Granite;
        }
        // Disabled
        input[type="checkbox"]:disabled+label {
            color: $bmo-wealth-Granite;
        }
        // Disabled and checked while disabled
        input[type="checkbox"]:checked:disabled+label:before {
            background-color: $bmo-wealth-Granite;
            // background-image: url('../../img/icons/16/16-checkmark-white.svg');
            border: 2px solid $bmo-wealth-Granite;
        }
        label {
            cursor: pointer;
            position: relative;
            top: 0px;
            left: 12px;
            color: $bmo-wealth-slate;
            font-family: $font-family;
            font-size: $font-size-16px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;

        }
    }
}

form .cta-button-secondary input[type="submit"] {
    @include small-mobile-and-mobile {
        padding: 15px 87.3px;
    }
    justify-content: center;
    padding: 14px 30px;
    display: flex;
    align-items: center;
    border-radius: 28px;
    font-family: $font-family;
    font-size: $font-size-14px;
    font-weight: $font-weight-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: $line-height-24px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    margin-top: 40px;
    height: 56px;
}

.g-recaptcha{
    transform:scale(0.898);
    -webkit-transform:scale(0.898);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
}
