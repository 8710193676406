section.thank-you-wrapper {
  text-align: center;
  margin-bottom: 199px;
  padding: 104px 0 0;
  @include tablet {
    margin-bottom: 159px;
    padding-top: 96px;
  }
  @include small-mobile-and-mobile {
    margin-bottom: 120px;
    margin-top: 27px;
    padding: 0 16px;
  }
  img {
    width: 240px;
    height: 200px;
  }
  .description {
    max-width: 789px;
    margin: 40px auto 0;
    @include tablet {
      max-width: 596px;
    }
    @include small-mobile-and-mobile {
      max-width: 100%;
    }
    h1 {
      font-family: $font-family;
      font-size: $font-size-44px;
      font-weight: $font-weight-light;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.09;
      letter-spacing: -0.5px;
      @include small-mobile-and-mobile {
        font-size: $font-size-36px;
        line-height: 1.11;
        letter-spacing: normal;
      }
      margin-bottom: 0;
      color: $bmo-wealth-slate;
    }
    p {
      margin-bottom: 0;
      font-family: $font-family;
      font-size: $font-size-16px;
      font-weight: $font-weight-normal;
      margin: 0 auto;
      max-width: 584px;
      @include tablet {
        max-width: 472px;
      }
      @include small-mobile-and-mobile {
        max-width: 100%;
      }
      font-stretch: normal;
      font-style: normal;
      margin-top: 24px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      color: $bmo-wealth-slate;
    }
  }
  .cta-button-wrapper {
    margin-top: 40px;
    display: inline-flex;
    @include small-mobile-and-mobile {
      display: block;
    }
    a.cta-link {
      margin: 0 12px;
      @include small-mobile-and-mobile {
        min-width: 232px;
        padding: 16px 64px;
        display: block;
      }
    }
    .cta-button-secondary:not(:first-child) {
      @include small-mobile-and-mobile {
        margin: 24px 0 0;
      }
    }
  }
}
