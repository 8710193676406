section.subscribe-modal-plugin {
    margin-top: 148px;
    @include tablet {
        margin-top: 164px;
    }
    @include desktop-and-large-screen {
        margin-top: 180px;
    }
    background: $bmo-wealth-container-bg-gray;
    min-height: 364px;
    position: relative;
    .subscribe-modal {
        align-items: center;
        justify-content: center;
        min-height: 364px;
        .logo-container {
            position: absolute;
            left: 50%;
            transform: translate(-50%,-50%);
            max-width: 240px;
            img {
                height: 200px;
            }
        }
        .content-wrapper {
            .subscribe-text {
                width: 100%;
                padding: 124px 0 0;
                margin: 0 auto;
                @include small-mobile-and-mobile {
                    padding: 124px 16px 0;
                }
                @include tablet {
                    max-width: 472px;
                }
                @include desktop-and-large-screen {
                    max-width: 584px;
                }
                p {
                    font-size: $font-size-24px;
                    line-height: $line-height-32px;
                    font-weight: $font-weight-bold;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: $bmo-wealth-slate;
                }
            }
        }
        button.subscribe-button {
            border-radius: 28px;
            height: 56px;
            font-family: $font-family;
            font-size: $font-size-14px;
            font-weight: $font-weight-bold;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-24px;
            margin: 24px auto 0;
            letter-spacing: 0.25px;
            text-transform: uppercase;
            padding: 14px 30px;
            display: block;
            @include small-mobile-and-mobile {
                padding: 16px 77px;
            }
        }
        button.subscribe-button:hover {
            color: $bmo-wealth-white;
            border-color: $bmo-wealth-ultramarine;
            background-color: $bmo-wealth-ultramarine;
        }
    }
    .subscribe-text,
    .terms-and-condition,
    .header-text,
    .success-message {
        a {
            color: $bmo-wealth-accessible-blue;
        }
    }
    #subscribeModal {
        height: calc(100% - 80px);
        max-height: 980px;
        top: 55px;
        @include mobile-and-tablet {
            height: 100%;
            max-height: 95%;
            top: 25px;
            &.success-message-centre {
                top: 40%;
            }
        }
        .modal-dialog {
            max-width: 995px;
            @include tablet {
                margin: 0 24px;
            }
            @include small-mobile-and-mobile {
                margin: 0 16px;
            }
        }
        .modal-content {
            .modal-header {
                height: 148px;
                border: 0;
                padding: 0;
                background: $bmo-wealth-container-bg-gray;
                .country-logo-modal {
                    margin: 0 auto;
                    margin-top: 43px;
                    img {
                        width: 240px;
                        height: 200px;
                    }
                }
            }
            .modal-body {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 32px;
                flex-direction: column;
                @include small-mobile-and-mobile {
                    padding-left: 24px;
                    padding-right: 24px;
                }
                @include tablet {
                    padding: 24px 124px 40px 125px;
                }
                button.close {
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    right: 17px;
                    top: -17px;
                    color: $bmo-wealth-Granite;
                    font-size: 30px;
                    background-image: url('/static/assets/svgs/header-svg/close-granite.svg');
                    width: 24px;
                    height: 24px;
                }
                .info-form-text {
                    max-width: 584px;
                    p {
                        font-size: $font-size-16px;
                        font-weight: $font-weight-normal;
                        color: $bmo-wealth-slate;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: 0.15px;
                    }
                }
                .header-text {
                    max-width: 584px;
                    p {
                        font-size: $font-size-24px;
                        font-weight: $font-weight-bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: center;
                        color: $bmo-wealth-slate;
                    }
                }
                .terms-and-condition {
                    max-width: 584px;
                    p {
                        font-size: $font-size-14px;
                        font-weight: $font-weight-normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.71;
                        letter-spacing: 0.25px;
                        text-align: center;
                        color: $bmo-wealth-slate;
                    }
                }
                .subscribe-form-container {
                    margin-top: 24px;
                    max-width: 379px;
                    @include small-mobile-and-mobile {
                        width: 100%
                    }
                    @include tablet {
                        width: 348px
                    }
                    .form-field.input {
                        width: 379px;
                        @include mobile-and-tablet {
                            width: 100%;
                        }
                        &.help-icon-active {
                            .form-label-group {
                                width: calc(100% - 30px);
                            }
                        }
                        .form-label-group {
                            width: 379px;
                            @include mobile-and-tablet {
                                width: 100%;
                            }
                        }
                    }
                    .checkbox-container {
                        margin-bottom: 24px;
                        margin-top: 24px;
                        span.helper-text {
                            padding-left: 18px;
                        }
                        .input-wrap {
                            padding-left: 29px;
                            padding-top: 25px;
                        }

                        .header-text {
                            display: block;
                            color: $bmo-wealth-slate;
                            padding-left: 15px;
                            @include mobile-and-tablet {
                                padding-left: 2px;
                                text-align: center;
                                display: block;
                            }
                        }
                        &.error-message-active {
                            .error-message {
                                display: block;
                                opacity: 1;
                            }
                        }
                        .error-message {
                            font-family: $font-family;
                            font-size: $font-size-14px;
                            font-weight: $font-weight-normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: $line-height-16px;
                            letter-spacing: 0.25px;
                            color: $bmo-wealth-negative;
                            background-repeat: no-repeat;
                            background-position: left top;
                            opacity: 0;
                            transition: all .3s ease;
                            margin-top: 7px;
                            display: none;
                            margin-bottom: 6px;
                            img {
                                width: 16px;
                                height: 16px;
                                margin-right: 8px;
                            }
            
                        }
                    }
                    .subscribe-submit-button-container {
                        display: flex;
                        justify-content: center;
                        .subscribe-submit-button {
                            border-radius: 28px;
                            height: 56px;
                            font-size: $font-size-14px;
                            font-weight: $font-weight-bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.71;
                            letter-spacing: 0.25px;
                            text-transform: uppercase;
                            margin-bottom: 40px;
                            margin-top: 0;
                            height: 56px;
                            @include small-mobile-and-mobile {
                                padding: 16px 77px;
                            }
                            padding: 16px 32px;
                        }
                    }
                }

                .success-message {
                    display: none;
                    max-width: 584px;
                    p {
                        font-stretch: normal;
                        font-style: normal;
                        text-align: center;
                        color: $bmo-wealth-slate;
                        &:first-child {
                            font-size: $font-size-24px;
                            font-weight: $font-weight-bold;
                            line-height: 1.33;
                            letter-spacing: normal;
                        }
                        &:last-child {
                            margin-bottom: 48px;
                            font-size: $font-size-16px;
                            font-weight: $font-weight-normal;
                            line-height: 1.5;
                            letter-spacing: 0.15px;
                            @include tablet {
                                margin-bottom: 24px;
                            }
                            @include desktop-and-large-screen {
                                margin-bottom: 56px;
                            }
                        }
                    }
                }
            }
        }
    }

    .cta-button-secondary{
        button {
            &.blue-bg-white-text {
                border: 2px solid $bmo-wealth-accessible-blue;
                background-color: $bmo-wealth-accessible-blue;
                color: $bmo-wealth-white;
            }
        }
    }
}

section.podcast-subscribe-model {
    min-height: 0px !important;
    background: $bmo-wealth-white !important;
    padding: 40px 25px 40px 25px;
    .subscribe-modal {
        min-height: 0px !important;
        .content-wrapper {
            .subscribe-text{
                max-width: none !important; 
                padding: 124px 0px 0 !important;
            }
            .subscribe-text p{
                font-size: 28px !important;
                font-weight: 700 !important;
            }
            .cta-button-secondary .subscribe-button{
                font-size: 14px !important;
                font-weight: 700 !important;
                border-radius: 28px !important;
                padding: 16px 32px 16px 32px !important;
                background: #0075be;
                color: #fff;
                border: none;
                margin-top: 30px;
                height: 54px !important;
            }
        }
    }
}

#subscribe-form .captcha-container{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    .g-recaptcha{
       width: 273px;
    }
}