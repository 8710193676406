section.overlapping-image-banner-wrapper {
    margin: 0 auto;
    width: 100%;
    padding: 48px 16px;
    background: -webkit-linear-gradient(top, $bmo-wealth-container-bg-gray 61.5%, $bmo-wealth-white 38.5%);
    background: -o-linear-gradient(top, $bmo-wealth-container-bg-gray 61.5%, $bmo-wealth-white 38.5%);
    background: -moz-linear-gradient(top, $bmo-wealth-container-bg-gray 61.5%, $bmo-wealth-white 38.5%);
    background: linear-gradient(to top, $bmo-wealth-container-bg-gray 61.5%, $bmo-wealth-white 38.5%);

    @include tablet {
        padding: 64px 86px 48px;
        background: -webkit-linear-gradient(top, $bmo-wealth-container-bg-gray 59.5%, $bmo-wealth-white 40.5%);
        background: -o-linear-gradient(top, $bmo-wealth-container-bg-gray 59.5%, $bmo-wealth-white 40.5%);
        background: -moz-linear-gradient(top, $bmo-wealth-container-bg-gray 59.5%, $bmo-wealth-white 40.5%);
        background: linear-gradient(to top, $bmo-wealth-container-bg-gray 59.5%, $bmo-wealth-white 40.5%);
    }

    @include desktop-and-large-screen {
        padding: 80px 143px 64px;
        background: -webkit-linear-gradient(top, $bmo-wealth-container-bg-gray 61%, $bmo-wealth-white 39%);
        background: -o-linear-gradient(top, $bmo-wealth-container-bg-gray 61%, $bmo-wealth-white 39%);
        background: -moz-linear-gradient(top, $bmo-wealth-container-bg-gray 61%, $bmo-wealth-white 39%);
        background: linear-gradient(to top, $bmo-wealth-container-bg-gray 61%, $bmo-wealth-white 39%);
    }

    .image-wrapper {
        text-align: center;
        margin: 0 auto;
        height: 270px;
        @include tablet {
            height: 277px;
        }
        @include desktop-and-large-screen {
            height: 477px;
        }
    }
    .background-image-wrapper {
        div.component-bg-image {
            max-width: 995px;
            margin: 0 auto;
            width: 100%;
            object-fit: cover;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            height: 185px;
            border-radius: 4px;
            @include tablet {
                height: 201px;
            }
            @include desktop-and-large-screen {
                height: 328px;
            }
        }


        .foreground-image-wrapper {
            div.component-fg-image {
                max-width: 789px;
                margin: 0 auto;
                height: 185px;
                border-radius: 4px;
                @include tablet {
                    height: 201px;
                }
                @include desktop-and-large-screen {
                    height: 328px;
                }
                object-fit: cover;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;

            }
            padding: 0 22px;
            position: relative;
            bottom: 92px;

            @include tablet {
                padding: 0 62px;
                bottom: 107px;
            }
            @include desktop-and-large-screen {
                padding: 0 102px;
                bottom: 164px;
            }
        }
    }
}
