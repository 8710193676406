section.question-answer-accordion-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;

    @include tablet {
        padding: 0;
    }
    .arrow-up,
    .arrow-down {
        padding: 24px 24px 0 0;
        @include tablet {
            padding: 24px 22px 0 0;
        }
    }
    .chevron {
        @include small-mobile-and-mobile {
            img {
                position: absolute;
                right: 23px;
            }
        }
    }
    .question-answer-inner-wrapper {
        width: 100%;
        padding-left: 40px;
        @include small-mobile-and-mobile {
            padding: 0;
        }
        @include tablet {
            padding-left: 38px;
        }
    }

    .question {
        width: calc(100% - 40px);
        color: $bmo-wealth-slate;
        border-top: 1px solid $bmo-wealth-light-gray;
        padding: 18px 25px 18px 0;;
        text-align: left;
        float: right;
        @include tablet {
            padding: 18px 25px 18px 2px;
            width: calc(100% - 38px);
        }
        @include small-mobile-and-mobile {
            width: 100%;
            padding: 18px 0;
        }

        p, h4 {
            margin:0;
            font-weight: $font-weight-bold;
            font-size: $font-size-18px;
            line-height: $line-height-28px;

            @include small-mobile-and-mobile {
                padding-left: 24px;
                max-width: calc(100% - 68px);
            }
        }
    }

    .answer {
        border-top: 1px solid #d9dce1;
        padding: 17px 0 48px;
        line-height: $line-height-32px;
        p {
            line-height: $line-height-24px;
            margin-bottom: 0;
        }
        p.strong {
            letter-spacing: 0.25px;
        }

        ul {
            list-style: none
        }

        li::before {
            content: "•"; color: $bmo-wealth-accessible-blue;
            display: inline-block;
            width: 1.5em;
            margin-left: -1.5em
        }
        h2, h3 {
            font-size: $font-size-24px;
            line-height: $line-height-32px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
        }
    }

    @include small-mobile-and-mobile {
        padding: 0;
    }
}

.accordion-wrapper {
    .question-answer-accordion-wrapper:last-child {
        .question {
            border-bottom: 1px solid $bmo-wealth-light-gray;
        }
        .answer {
            border-top: 0;
            border-bottom: 1px solid $bmo-wealth-light-gray;
        }
    }
    &.SUB_BRAND {
        .question {
            border-color: $bmo-wealth-white;
        }
        .answer {
            border-color: $bmo-wealth-white;
        }
        .question-answer-accordion-wrapper:last-child {
            .question {
                border-bottom: 1px solid $bmo-wealth-white;
            }
            .answer {
                border-top: 0;
                border-bottom: 1px solid $bmo-wealth-white;
            }
        }
    }
}

