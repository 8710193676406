.main-insights-categories-section {
    padding: 40px 10% 40px 10%;
    margin-bottom: 50px;

    .head {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 30px;
        text-align: center;
    }
    .tab_box {
        display: flex;
        align-items: center;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 2px;
    }
    .tab_box::-webkit-scrollbar {
      height:6px;
      margin-top: 10px;
      background-color: none;
    }
    .tab_box::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    .tab_box::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, .7);
    }
    .tab_box::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, .1);
    } 
    .content {
        display: flex;
        align-items: flex-start;
        padding-bottom: 3%;
        padding-top: 3%;
        border-bottom: 1px solid #d9dce1;
    }
    .banner-content-left-img{
        min-width: 150px;
        padding-right: 5%;
    }
    .banner-content-left-img .banner-content-img {
        border-radius: 5px;
        width: 128px;
        height: 128px;
    }

    .banner-content {
        width: 100%;
    }

    .flex-container {
        display: flex;
        align-items: center;
    }

    .flex-container-with-space {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .flex-container-mobile {
        display: none;
    }

    .icon-button {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
    }

    .download {
        padding: 0px 6px 0px 6px;
    }

    .transcript {
        font-size: 12px;
        font-weight: 500;
        color: #929ba9;
        margin-left: 5px;
        padding-right: 0px;
    }
    .podcast-no-results{
        display: none;
        position: absolute;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding-top: 50px;
    }
    .total-results {
        color: #646c76;
        font-size: 14px;
    }

    .drop-down {
        position: relative;
        font-size: 14px;
    }

    .drop-down .selected {
        background: #fff no-repeat scroll right center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #929ba9;
        width: 200px;
        border-radius: 5px;
        z-index: 100;
        padding: 10px;
        cursor: pointer;
    }

    .drop-down .selected a {
        text-decoration: none;
        color: #001928;
        font-weight: 400 !important;
    }

    .drop-down .option {
        position: absolute;
    }

    .drop-down .options ul {
        background: #fff none repeat scroll 0 0;
        display: none;
        list-style: none;
        padding: 0px 0px;
        position: absolute;
        left: 0px;
        top: 42px;
        width: 200px;
        min-width: 170px;
        z-index: 1000;
        border: 1px solid #929ba9;
    }

    .drop-down .selected span.value,
    .drop-down .options span.value {
        display: none;
    }

    .drop-down .options ul li a {
        padding: 12px 10px 12px 10px;
        display: block;
        text-decoration: none;
        color: #001928;
        font-weight: 400 !important;
    }

    .drop-down .options ul li a:hover {
        background: #f5f6f7;
        transition: 0.2s ease;
    }

    .sort-label {
        font-size: 14px;
        margin-right: 10px;
        white-space: nowrap;
    }

    .res-container {
        margin-top: 20px;
    }

    .category-tag {
        background-color: #005587;
        border-radius: 4px;
        padding: 1.5px 6px 0px 6px;
        font-weight: 700;
        font-size: 12px;
        margin-right: 12px;
        color: #fff;
        margin-bottom: 12px;
        text-transform: uppercase;
        cursor: pointer;
        white-space: nowrap;
    }

    .sub-category-tag {
        background: #d9dce1;
        color: #001928;
        border-radius: 4px;
        padding: 1.5px 6px 0px 6px;
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 12px;
        margin-right: 12px;
        text-transform: uppercase;
        cursor: pointer;
        white-space: nowrap;
    }
    .podcast-tags{
        flex-wrap: wrap;
    }

    .date {
        color: #646c76;
        font-size: 12px;
        font-weight: 400;
    }

    .insight-head{
        padding-top: 2%;
        font-weight: 700;
        font-size: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 5px;
        margin-bottom: 15px;
        line-height: 32px;
    }

    .insight-head.hide-date {
      margin-top: 0px;
      padding-top: 0px;
    }

    .insight-des {
        display: block;
        font-weight: 400;
        font-size: 14px;
        margin-top: 7px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
    }

    .insight-des-mobile {
        display: none;
    }

    .play-btn {
        border-radius: 28px;
        border: 2px solid #005587;
        padding: 4px 12px 4px 12px;
        color: #005587;
        background: none;
        margin-right: 8px;
        display: flex;
        font-size: 12px;
        cursor: pointer;
        align-items: center;

        img {
            padding-right: 10px;
        }
    }

    .podcast-time {
        font-size: 12px;
        color: #646c76;
    }

    .tab_box .tab_btn_wrapper {
      margin: 15px 25px;
    }

    .tab_box .tab_btn {
        border: none;
        outline: none;
        background: none;
        font-size: 18px;
        color: #001928;
        font-weight: 300;
        display: inline-block;
        cursor: pointer;
    }

    .tab_box .tab_btn.active {
        font-weight: 700;
        color: #001928;
    }

    .line {
        position: absolute;
        top: 52.5px;
        left: 0px;
        width: 90px;
        height: 4px;
        border-radius: 10px;
        background: #0075be;
        transition: all 0.3s ease-in-out;
        z-index: 100;
    }

    .hr_tag {
        position: absolute;
        top: 52.5px;
        border-radius: 10px;
        background: #dbdbdb;
        width: 100%;
        margin-top: 0 !important;
        height: 4px;
    }

    .content_box {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
    }
    .load-more {
        position: absolute;
        outline: none;
        border: none;
        background: none;
        margin: 30px 0px 15px 0px;
        font-size: 14px;
        font-weight: 700;
        color: #0075be;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: absolute;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .load-more-img {
        margin-right: 5px;
    }

    .share {
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .flex-container-share {
        display: flex;
        align-items: center;

        margin-bottom: 15px;
    }

    .share-img {
        margin-right: 15px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .share-close-img {
        cursor: pointer;
    }

    .share-title {
        font-size: 14px;
        font-weight: 700;
        margin-right: 70px;
        white-space: nowrap;
    }

    .modal-header {
        border-bottom: 0px;
    }

    .modal-content {
        border: 0px;
    }

    .modal-header {
        padding: 1rem 1rem 1rem 1rem;
    }

    .modal-body {
        padding: 0rem 10rem 0rem 10rem;
    }

    .modal-content .insight-head {
        padding-bottom: 15px;
        border-bottom: 1px solid #d9dce1;
    }

    .modal-dialog {
        max-width: none !important;
        margin: 5rem 1.75rem;

    }
    .modal-body .modal-dialog {
        margin-top: 0px;
    }
    .modal-header .close {
        font-size: 2.2rem;
        font-weight: 400;
    }
    .modal-lg {
        max-width: none !important;
    }

    .share-button {
        position: relative;
    }

    .podcast-share {
        position: absolute;
        display: none;
        // box-shadow: rgba(0, 0, 0, 0.2) 4px 5px 10px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        padding: 8px 8px 8px 16px;
        background-color: #fff;
        width: 220px;
        z-index: 100;
        bottom: 40px;
        right: 0px;
        border-radius: 4px;
    }

    .podcast-share-arrow {
        position: absolute;
        bottom: -10px;
        right: 10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        width: 0;
        height: 0;
    }
    .filter-container {
        margin-top: 25px;
        padding-bottom: 15px;
        border-bottom: 1px solid #D9DCE1;
      }
      .filter{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: #0075BE;
        margin-right: 10px;
        cursor: pointer;
      }
      .filter-img, .search-img{
        margin-right: 5px;
      }
      .filter-count{
        font-size: 14px;
        font-weight: 400;
        color: #001928;
      }
      .search{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: #0075BE;
        cursor: pointer;
        outline: none;
        border: none;
        background: #fff;
      }
      .right-img {
        width: 100%;
        // height: 192px;
        line-height: 192px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      .insight-card-content{
        position: relative;
        width: 100%;
        display: block;
        margin-right: 25px;
        margin-bottom: 25px;
        max-width: calc(33.3% - 17px);
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.14) 0px 1px 2px;
      }
      .insight-card-content:nth-child(3n) {
        margin-right: 0;
        }
      .right-content-container{
        padding: 20px;
        min-height: 285px;
      }
      .date{
        color: #646C76;
        font-size: 14px;
      }
      .filter-popup-content{
        padding: 2% 7% 5% 7%;
      }
      .filter-popup-content-header{
        border-bottom: 1px solid #d9d8d8;
        padding-bottom: 20px;
      }
      .filter-checkboxes{
        display: flex;
        padding-top: 25px;
        flex-wrap: wrap;
        margin-bottom: 65px;
      }
      .filter-check{
        padding-right: 50px;
        padding-bottom: 15px;
        display: flex;
        width: 33.33%;
      }
      .select-all{
        color: #0079C1;
        padding-top: 25px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
      .clear-all{
        color: #0079C1;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
      .sort-filter-popup-mobile{
        display: none;
      }
      .apply-filters-btn{
        border-radius: 28px;
        border: 2px solid #0075BE;
        padding: 14px 45px 14px 45px;
        color: #0075BE;
        background: none;
        margin-right: 5px;
        display: flex;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        position: absolute;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      .search-popup-content-container{
        padding: 2% 7% 7% 7%;
      }
      .search-popup-content{
        display: flex;
        justify-content: space-between;
      }
      .latest-tags-title, .latest-article-title{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .latest-tags{
        flex-wrap: wrap;
        width: 80%;
      }
      .latest-category-tag{
        margin-bottom: 15px;
        white-space: nowrap;
      }
      .latest-sub-category-tag{
        margin-bottom: 15px;
        white-space: nowrap;
      }
      .latest-articles{
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
      }
      .latest-article{
        display: flex;
        margin-bottom: 15px;
      }
      .latest-tags-container{
        width: 60%;
      }
      .latest-articles-container{
        width: 100%;
      }
      .latest-article-img{
        width: 64px;
        height: 64px;
        margin-right: 15px;
      }
      .latest-article-des{
        font-size: 14px;
        color: #646C76;
        font-weight: 400;
      }
      .search-input{
        width: 100%;
        border-bottom: 1px solid #d9d8d8;
        border-top: 0px;
        border-left: 0px;
        font-size: 24px;
        border-right: 0px;
        margin-bottom: 40px;
        padding-bottom: 10px;
        font-weight: 400;
        font-size: 20px;
      }
      .search-input:focus{
        outline: none;
      }
      .search-input::placeholder {
        color: #929BA9;
        font-size: 24px;
      }
      .filter-check-input{
        width: 24px;
        height: 24px;
        accent-color: #0075BE;
        margin-right: 8px;
      }
      .read-more{
        font-size: 16px;
        font-weight: 700;
        color: #0075BE;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 15px;
        padding-left: 20px
      }
      .cover {
        position: relative;
      }
      .left {
        display: none;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
      }
      .right {
        display: none;
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
      }
      .right-content-container .flex-container{
          overflow-x: overlay;
      }
    @media (max-width: 1000px) {
        .modal-body {
            padding: 0rem 1rem 0rem 1rem;
        }
    }
    @media (max-width: 1065px) {
      .insight-card-content{
        max-width: calc(50% - 15px);
        margin-right: 30px;
      }
      .insight-card-content:nth-child(3n) {
        margin-right: 30px;
        }
      .insight-card-content:nth-child(2n) {
        margin-right: 0;
        }
        .filter-popup-content{
          padding: 2% 7% 10% 7%;
        }
      }
    @media (max-width: 650px) {
        .tab_box::-webkit-scrollbar {
          height: 4px;
        }
          .cover{
            padding: 0%;
        }
        padding: 40px 0% 40px 0%;
        .latest-tags{
            margin-bottom: 20px;
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 180%;
          }
          .search-input{
            font-size: 18px;
          }
          .search-input::placeholder {
            font-size: 0.85rem;
          }
        .latest-sub-category-tag{
            padding: 4.5px 8px 4px 8px
        }
        .latest-category-tag{
            padding: 4.5px 8px 4px 8px
        }
        .insight-card-content{
            max-width: 100%;
          }
        .banner-content-left-img{
            min-width: 115px;
        }
        .banner-content-left-img .banner-content-img {
            width: 96px;
            height: 96px;
        }
        .insight-head {
            -webkit-line-clamp: 3;
        }
        .category-tag {
            margin-bottom: 5px;
        }
    
        .sub-category-tag {
            margin-bottom: 5px;
        }
        .insight-des-mobile {
            display: block;
            font-weight: 400;
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .sort {
            margin: 10px 25px 10px 25px;
        }

        .icons-container {
            display: none;
        }

        .flex-container-mobile {
            display: flex;
            align-items: center;
        }

        .total-results {
            margin-left: 25px;
        }

        .drop-down {
            margin-right: 25px;
        }

        .res-container {
            flex-wrap: wrap;
        }

        .banner-content-img {
            width: 96px;
            height: 96px;
        }

        .content {
            border-bottom: 0px;
        }

        .content {
            padding-top: 5%;
        }

        .icons-container-mobile {
            border-bottom: 1px solid #d9dce1;
            padding-bottom: 5%;
        }
        .content_box{
            margin-left: 25px;
            margin-right: 25px;
            display: flex;
            flex-direction: column;
          }
          .filter-container {
            margin: 25px;
          }
          .filter-checkboxes{
            flex-direction: column;
          }
          .filter-check{
            width: 100%;
          }
          .sort-filter-popup-mobile{
            display: block;
            margin-top: 25px;
            .sort-label{
              margin-bottom: 5px;
            }
            .drop-down{
              margin-right: 0px;
              .selected{
                width: 100%;
              }
              .options ul{
                width: 100%;
              }
            }
          }
          .apply-filters-btn{
            left: 8%;
            -ms-transform: none;
            transform: none;
          }
          .filter-popup-content{
            padding: 0%;
            margin: 2% 7% 80px 7%;
          }
          .search-popup-content{
            flex-direction: column;
          }
    }
}

.podcast-topic-section {
    .total-results {
        display: none;
    }

    .res-container {
        justify-content: end;
    }

    .sort {
        margin: 0px;
    }

    .about-category {
        display: flex;
        align-items: center;
        margin-top: 50px;
        margin-left: 7%;
        margin-right: 7%;

        .left-img {
            width: 380px;
            height: 192px;
            margin-right: 25px;
        }

        .about-head {
            font-size: 24px;
            font-weight: 700;
        }
        .about-content{
            width: 100%;
        }

        .about-des {
            font-size: 14px;
            font-weight: 400;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .about-container {
            display: flex;
            justify-content: space-between;
        }

        .about-results-container {
            display: flex;
            align-items: center;
        }

        .about-results {
            font-size: 12px;
            margin-right: 15px;
            text-transform: uppercase;
        }

        .about-links {
            a {
                margin-right: 10px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            a:last-child {
                margin-right: 0px;
            }
        }
    }

    @media (max-width: 1090px) {
        .about-category {
            .left-img {
                width: 40%;
            }
        }
    }

    @media (max-width: 770px) {
        .about-category {
            .left-img {
                width: 35%;
            }
        }
    }

    @media (max-width: 650px) {
        .about-category {
            margin-left: 25px;
            margin-bottom: 25px;
            margin-right: 25px;
            flex-wrap: wrap;

            .left-img {
                width: 100%;
                height: 100%;
                margin-right: 0px;
            }

            .about-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .about-head {
                margin-top: 15px;
            }

            .about-results-container {
                margin-bottom: 10px;
            }
        }
    }
}

// Hiding this as this was asked to be removed in Phase 1.5
.main-insights-categories-section{
    .filter {
        display: none !important; 
    }
    .filter-count {
        display: none !important; 
    }
}
