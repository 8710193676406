section.legal-block-wrapper {
    background: $bmo-wealth-ultramarine;
    min-height: 64px;
    .legal-block {
        @include screen-max-width-mixin;
        padding-top: 16px !important;
        padding-bottom: 16px !important;

        .legal-header {
            font-family: $font-family;;
            font-size: $font-size-14px;
            font-weight: $font-weight-bold;;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.29;
            letter-spacing: 0.25px;
            color: $bmo-wealth-white;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
                width: 16px;
                height: 17px;
                margin-left: 5px;
            }
        }
        .legal-description {
            @include desktop-and-large-screen {
                padding: 0 102px;
            }
            p {
                font-family: $font-family;;
                font-size: $font-size-16px;
                font-weight: $font-weight-normal;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-24px;
                letter-spacing: 0.15px;
                color: $bmo-wealth-white;
                margin-bottom: 0;
                a {
                    font-family: $font-family;;
                    font-size: $font-size-14px;
                    font-weight: $font-weight-bold;;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $line-height-24px;
                    letter-spacing: 0.25px;
                    color: $bmo-wealth-white;
                }
            }
        }
        .legal-header.collapsed {
            img.arrow-down {
                display: block;
            }
            img.arrow-up {
                display: none;
            }
        }
        .legal-header:not(.collapsed) {
            img.arrow-down {
                display: none;
            }
            img.arrow-up {
                display: block;
            }
        }
        .legal-description.show {
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
            padding-bottom: 32px !important;

        }
        .legal-description:not(.show) {
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
            padding-bottom: 0 !important;
        }
        button {
            background: transparent;
            border: none;
            cursor: pointer;
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
            margin-bottom: 32px;
            @include desktop-and-large-screen {
                margin-bottom: 40px;
            }
        }

        button.collapsed {
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;
            margin: 0;
        }
    }
}
