.marketing-form-wrapper {
  width: 482px;
  margin: 0 auto;
  @include mobile-and-tablet {
    width: 100%;
    .form-field {
      width: 100% !important;
    }
  }
  .form-field.form-field-select {
    margin-top: 24px;
  }
}
