.award-recognition-wrapper{
    margin-top: 48px;
    margin-bottom: 48px;
    @include tablet {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    @include desktop-and-large-screen {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    h2.title{
        position: relative;
        text-align: center;
        @include screen-max-width-mixin;
        margin-bottom: 40px;

        @include tablet {
            max-width: 596px;
        }
        @include tablet-and-desktop {
            padding: 0;
            max-width: 789px;
            letter-spacing: -0.5px;
            font-size: $font-size-44px;
            line-height: $line-height-48px;
        }
    }
    .award-recognition-inner-wrapper {
        @include screen-max-width-mixin;
        padding: 0;
        @include tablet {
            padding: 0 12px;
        }
        @include desktop-and-large-screen {
            padding: 0 24px;
        }

        &.row {
            margin:0 auto;
        }
        .award-recognition {
            display: none;
            margin-bottom: 24px;
            @include small-mobile-and-mobile {
                margin-bottom: 16px;
            }
            padding: 0 16px;
            @include tablet {
                padding: 0 12px;
            }
            .award-inner-wrapper {

                @include hero-banner-shadow-mixin;
                @include tablet {
                    min-height: 484px;
                }
                @include desktop-and-large-screen {
                    min-height: 478px;
                }
            }

            .image-wrapper{
                margin: 0 auto;
                padding: 0 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 176px;
                width: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    flex-shrink: 0;
                    border-radius: 4px 4px 0 0;
                }
            }
            .content-wrapper {
                padding: 29px 24px 40px 24px;
                @include small-mobile-and-mobile { 
                  padding-bottom: 50px;
                }

                .award-title {
                    font-size: $font-size-22px;
                    line-height: $line-height-28px;
                    font-weight: $font-weight-bold;
                    font-stretch: normal;
                }

                a {
                    font-size: $font-size-22px;
                    line-height: $line-height-28px;
                    font-stretch: normal;
                    overflow: hidden;
                }

                .award-description {

                    margin-top: 8px;
                    word-break: break-word;
                    p {
                        min-height: 96px;
                        font-size: $font-size-16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: $line-height-24px;
                        letter-spacing: 0.15px;
                        color: $bmo-wealth-slate;
                    }
                }

                .award-tag {

                    font-size: $font-size-12px;
                    line-height: $line-height-16px;
                    color: $bmo-wealth-slate;
                    text-transform: uppercase;
                    position: absolute;
                    bottom: 24px;
                    max-width: 331px;
                    margin-right: 40px;
                }
            }
        }

        @include desktop-and-large-screen {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }
    }
    .show-less-wrapper  {
        a.show-less {
            display: none;
            img {
                transform: rotate(180deg);
            }
        }
    }
    .show-more-wrapper,
    .show-less-wrapper {
        text-align: center;
        @include small-mobile-and-mobile {
            margin-top: 8px;
        }
        a.show-more,
        a.show-less {
            &.show-more-link,
            &.show-less-link {
                border: none;
                background: transparent;
                position: relative;
                text-align: center;
                padding-bottom: 0;
            }
            img {
                height: 14px;
                width: 10px;
                margin-left: 7px;
                &.active {
                    display: none;
                }
            }
        }
        a:hover, a:focus {
            img {
                &.active {
                    display: inline;
                }
                &:not(.active) {
                    display: inline !important;
                }
            }
        }
    }
}
