body {
  margin: 0;
}

//v2 insights share icon and download button - start
.v2-social-share-container {
  display: flex;
  align-items: center;
}

.v2-social-share-container ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.v2-social-share-container ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}
.v2-social-share-container ul li:not(:last-child) a {
  width: 24px;
  height: 24px;
}

.v2_download-container {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  display: flex;
  align-items: center;
  color: #0075BE;
  height: 24px;
}
.v2-social-share-container span ,.v2_download-container {
  letter-spacing: 0.25px;
}
.v2-v2_social-share-download-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 998px;
  padding: 0;
  margin: 24px 10% 0;
}

.social-icons {
  display: inline-block;
  a img.v2-active {
    display: none;
  }

  a:hover {
    img:not(.v2-active) {
      display: none;
    }

    img.v2-active {
      display: flex;
    }
  }
}

.v2-social-share-container span {
  color: #646C76;
  font-weight: 400;
  font-size: 16px;
  width: 47px;
  display: inline-block;
}

.v2_social-share-download-container {
  display: flex;
  justify-content: center;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
}

.v2-social-mail-share {
  width: 28px;
  height: 28px;
}

.social-icons li {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .v2_social-share-download-container {
    flex-direction: column;
    align-items: center;
  }

  .v2-v2_social-share-download-items {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 327px;
    margin: 24px 5% 0;
  }

  .v2_download-container {
    margin-top: 16px;
    font-size: 14px;
    height: 24px;
  }

  .v2-social-share-container span {
    font-size: 14px;
    width: 41px;
    display: flex;
    align-items: center;
    margin-left: 4px;
    height: 24px;
  }
}
//v2 insights share icon and download button - end
// Insight Details Page - Banner Image Section - Start

.insights-image-banner-section {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;

  .banner-text-container {
    padding: 60px 15% 20px 15%;
  }

  .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
    gap: 8px;
    a {
      text-decoration: none;
    }
  }
  .category-tag {
    background-color: $bmo-wealth-ultramarine;
    border-radius: 4px;
    padding: 1.5px 6px 1px 6px;
    font-weight: 700;
    font-size: $font-size-12px;
    color: $bmo-wealth-white;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
  }
  //NOSONAR The reason is author-date-container is used in both image banner and video banner sections
  .author-date-container { 
    justify-content: flex-end;
    margin: 45px 10% 0px 10%;
    padding-bottom: 10px;
    color: $bmo-wealth-Granite;
    font-size: $font-size-16px;
    border-bottom: 1px solid $bmo-wealth-Granite;
  }

  .author-date-container span {
    margin-right: 8px; /* Adjusts the space between authors */
    margin-left: 8px;
  }

  .author-date-container a {
    text-decoration: revert !important;
    font-size: $font-size-16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
    color: $bmo-wealth-Granite;
  }

  .banner-img {
    width: 100%;
    padding-top: 30px;
  }

  .sub-category-tag {
    background: $bmo-wealth-light-gray;
    color: $bmo-wealth-slate;
    border-radius: 4px;
    padding: 1.5px 6px 1px 6px;
    font-weight: 700;
    font-size: $font-size-12px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
  }

  .date {
    color: $bmo-wealth-Granite;
    font-size: $font-size-16px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 16px;
  }

  .insight-head {
    font-weight: 700;
    font-size: 44px;
    margin-bottom: 15px;
    text-align: center;
    line-height: 48px;
  }

  .insight-des {
    display: block;
    font-weight: 400;
    font-size: $font-size-18px;
    margin-top: 15px;
    text-align: center;
    line-height: 28px;
  }

  .right-content-container {
    width: 100%;
  }

  .right-content-container .flex-container {
    overflow-x: scroll;
  }

  .related-articles-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }

  .related-articles-container-mobile {
    display: none;
  }

  .primary-author-or-written-by {
    .written-by {
      &::after {
        content: ":";
      }
    }
  }

  @media (max-width: 675px) {

    .banner-img {
      padding-top: 0px;
    }
  
    .author-date-container {
      margin: 24px 5% 0px 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .author-date-container span {
      display: table-header-group;
    }

    .related-articles-container-mobile {
      display: flex;
      flex-direction: column;
    }

    .banner-text-container {
      padding: 0px 16px;
      margin-bottom: 64px;
    }

    .date {
      font-size: $font-size-14px;
    }

    .updated-date {
      text-align: center;
      margin-bottom: 10px;
    }

    .related-articles-container {
      display: none;
    }

    .insight-head {
      font-size: 40px;
      line-height: 46px;
    }
    .primary-author-or-written-by {
      display: inline-flex !important;
      margin-left: 0;
  
      .written-by {
        margin: 0;
        &::after {
          content: ":";
        }
      }
    }
    .flex-container{
      margin: 24px 0px 44px 0px;
    }
  }
  @media (min-width: 676px) {
    .primary-author-or-written-by , .written-by {
      margin: 0 !important;
    }
  }
}

// Insight Details Page - Banner Image Section - End

// Insight Details Page - Banner Video Section - Start

.insights-video-banner-section {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
  .banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .bd-video-modal-lg {
    background-color: rgba(0, 0, 0, 0.5);
    // add accessibility for tab click
    cursor: pointer;
  }
  .banner-text-contianer {
    width: 50%;
    margin-right: 20px;
  }

  .main-video {
    width: 100%;
    height: 580px;
    padding: 20px;
    border: 0px;
  }
  .modal-header {
    border-bottom: 0px;
    padding: 1rem 1rem 0rem 0rem;
  }
  .modal-content {
    height: 600px;
  }
  .modal-lg {
    max-width: 1000px;
  }
  .pip-video-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    z-index: 1000;
  }

  .pip-video {
    width: 340px;
    height: 190px;
    height: auto;
    border: 2px solid $bmo-wealth-white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: $bmo-wealth-white;
    color: $bmo-wealth-black;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: $font-size-12px;
    cursor: pointer;
  }
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 96px;
    gap: 8px;
  }
  .banner-video {
    width: 50%;
    height: 100%;
  }

  .category-tag {
    background-color: $bmo-wealth-ultramarine;
    border-radius: 4px;
    padding: 1.5px 6px 1px 6px;
    font-weight: 700;
    font-size: $font-size-12px;
    color: $bmo-wealth-white;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
  }
  .author-date-container {
    justify-content: flex-end;
    margin: 45px 10% 0px 10%;
    padding-bottom: 10px;
    color: $bmo-wealth-Granite;
    font-size: $font-size-16px;
    border-bottom: 1px solid $bmo-wealth-Granite;
    @media (min-width: 768px) {
      padding-bottom: $font-size-24px;
    }
  }

  .author-date-container span {
    margin-right: 8px;
    margin-left: 8px;
  }

  .primary-author-or-written-by {
    .written-by {
      &::after {
        content: ":";
      }
    }
  }
  .author-date-container a {
    text-decoration: revert !important;
    font-size: $font-size-16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
    color: $bmo-wealth-Granite;
  }
  .sub-category-tag {
    background: $bmo-wealth-light-gray;
    color: $bmo-wealth-slate;
    border-radius: 4px;
    padding: 1.5px 6px 1px 6px;
    font-weight: 700;
    font-size: $font-size-12px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
  }
  .date {
    color: $bmo-wealth-Granite;
    font-size: $font-size-16px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 16px;
  }
  .insight-head {
    font-weight: 700;
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 15px;
    text-align: center;
  }

  .insight-des {
    display: block;
    font-weight: 400;
    font-size: $font-size-18px;
    margin-top: 15px;
    text-align: center;
    line-height: 28px;
  }
  .right-content-container {
    width: 100%;
  }
  .right-content-container .flex-container {
    overflow-x: scroll;
  }
.image-container {
    position: relative;
    display: inline-block;
    margin-left: 20px;
    .banner-image {
        border-radius: 4px;
        opacity: 0.7;
        max-width: 580px;
    }
}
  .youtube-play-button-container , .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    z-index: 1;
  }
  .modal-dialog{
    margin-top: 6.5rem;
  }
  @media (max-width: 1130px) {
    .banner-image {
      opacity: 0.7;
      width: 480px;
    }
    .modal-lg {
      max-width: 800px;
    }
  }
  @media (max-width: 850px) {
    .banner-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .banner-text-contianer {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 64px;
    }
    .image-container {
      margin-left: 0px;
    }
    .banner-video {
      width: 100%;
      height: 100%;
    }
    .insight-head {
      line-height: 46px;
      font-size: 40px;
    }
    .banner-image {
      width: 100%;
      opacity: 0.7;
    }
  }
  @media (max-width: 675px) {
    .banner-image {
      width: 100%;
    }
    .modal-content {
      height: 300px;
    }
    .date {
      font-size: $font-size-14px;
    }
    .author-date-container {
      margin: 24px 5% 0px 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .flex-container {
      margin: 24px 0px 44px 0px;
    }
    .author-date-container span {
      display: table-header-group;
    }

    .primary-author-or-written-by {
      display: inline-flex !important;
      margin-left: 0;
  
      .written-by {
        margin: 0;
        &::after {
          content: ":";
        }
      }
    }
    
    .insights-video-banner-section{
      padding: 24px 24px 64px 24px;
    }
    
  }
  @media (min-width: 676px) {
    .primary-author-or-written-by , .written-by {
      margin: 0 !important;
    }
  }
}



.insights-video-banner-section .insight-head {
  margin-top: 16px;
  @media (max-width: 675px) {
    margin-top: 8px;
  }
}
// Insight Details Page - Banner Video Section - End

// Insight Details Authors Section - Start

.insights-authors-section {
  background-color: $bmo-wealth-container-bg-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 10% 60px 10%;
  margin: 80px 0px 30px 0px;

  .author-img {
    width: 96px;
    height: 96px;
    border-radius: 5px;
  }

  .author-section {
    display: flex;
    align-items: center;
  }

  .author-img-container {
    height: 96px;
    padding-right: 20px;
  }

  .vertical-line {
    border-right: 1px solid $bmo-wealth-light-gray;
    margin-left: 65px;
    margin-right: 65px;
    height: 100px;
  }

  .vertical-line:last-child {
    display: none;
  }

  .author-name {
    font-weight: 700;
    font-size: $font-size-18px;
    color: $bmo-wealth-accessible-blue;
    margin-bottom: 10px;
    margin-top: 0em;
  }

  .author-designation {
    font-weight: 400;
    font-size: $font-size-16px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media (max-width: 1200px) {
    .vertical-line {
      margin-left: 35px;
      margin-right: 35px;
    }
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: normal;
    .author-section:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
    .author-section{
      padding: 15px 0px;
      width: 100% !important;
    }

    .vertical-line {
      display: none;
    }


    .author-designation {
      font-size: $font-size-14px;
    }
  }

  @media (max-width: 650px) {
    .author-section{
      padding: 24px 16px;
    }
  }

}

// Insight Details Authors Section - End

// Content Section - Start

.quote-divider {
  width: 174px; /* Set the width of the horizontal line */
  border: 1px solid #707070;
}
.quote_content {
  margin: 48px 0;
}

.copy-module-section {
  padding: 0px;
  margin: 64px 0px 0px 0px;
  .quote_150_plus {
    display: flex;
  }
  .quote-img {
    height: 64px;
    margin-right: 32px;
  }
  @media (max-width: 650px) {
    margin: 64px 0px 0px 0px;
    .quote_150_plus {
      flex-direction: column;
    }
    .quote-img {
      width: 96px;
      height: auto;
      margin: 0 0 32px 0;
    }
    
  }
}

// Content Section - End

// Media Section - Start
.insights-media-section {
  flex-direction: column;
  padding: 0px;
  margin: 64px 0;
  .insights-media-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 32px;
  }
  .image-more-width {
    width: 58%;
    border-radius: 4px;
  }
  .image {
    width: 37.9%;
    border-radius: 4px;
  }
  .image-full-width {
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    margin-top: 32px;
  }
  .no-margin-top{
    margin-top: 0px;
  }
  .image-equal-width {
    width: 48%;
    border-radius: 4px;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .des-center {
    text-align: center;
    font-size: $font-size-14px;
    font-weight: 400;
    margin-top: 8px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
  .des-left {
    text-align: left;
    font-weight: 400;
    font-size: $font-size-14px;
    line-height: 24px;
    letter-spacing: 0.25px;
    margin-top: 8px;
  }
  .media-mobile-img{
    display: none;
  }
  @media (max-width: 675px) {
    flex-direction: column;
    justify-content: center;
    margin: 64px 0px 0px 0px;
    .insights-media-container {
      flex-direction: column;
    }
    .image, .image-more-width, .image-equal-width, .image-full-width{
      width: 100%;
    }
    .left {
      margin-right: 0px;
    }
    .right {
      margin-left: 0px;
    }
    .des-center {
      text-align: left;
    }
    .media-mobile-img{
      display: flex;
      border-radius: 4px;
      width: 100%;
    }
    .media-desk-img{
      display: none;
    }
  }
  :last-child {
    margin-bottom: 0px;
  }
}
  
// Media Section - End

// Highlight Section - Start

.insights-highlights-section {
    padding: 0px;
    margin: 64px 0px 0px 0px;
    display: flex;
    align-items: stretch;
  
    .highlight-text {
      width: 70%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
    }
  
    .right-img {
      width: 100%;
      border-radius: 16px;
    }
  
    .image-text-container {
      width: 35%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .image-text {
      font-size: $font-size-14px;
      margin-top: 5px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.25px;
      text-align: left;
    }
  
    .left {
      margin-right: 25px;
    }
  
    .right {
      order: 1;
      margin-left: 25px;
    }
  
    .highlight-points {
      background: linear-gradient(180deg, #0079C1 0%, #006EA3 100%);
      color: $bmo-wealth-white;
      padding: 24px 32px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30%;
      ul{
        padding-inline-start: 14px;
      }
    }
    @media (max-width: 1080px) {
      .image-text-container {
          width: 50%;
      }
    }
    @media (max-width: 675px) {
      flex-direction: column;
      justify-content: center;
      margin: 64px 0px 0px 0px;
      .highlight-text {
        width: 100%;
      }
      .image-text-container {
        width: 100%;
        align-items: flex-start;
      }
  
      .left {
        margin-right: 0px;
        margin-bottom: 48px;
      }
  
      .right {
        margin-left: 0px;
        margin-top: 48px;
      }
      .highlight-points{
        width: 100%;
      }
    }
  }
  
// Highlight Section - End

// Featured Section - Start
.insights-features-section-highlights{
    padding: 0px;
    margin-top: 64px;
    .highlight-points {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #0079C1 0%, #006EA3 100%);
      color: $bmo-wealth-white;
      padding: 60px 116px;
      border-radius: 5px;
      @media (max-width: 675px) {
        padding: 60px 16px;
      }
    }
}
.insights-features-section {
    background-color: $bmo-wealth-container-bg-gray;
    padding: 48px 0px;
    margin: 64px 0px 0px 0px;
    overflow-x: auto;

  .insights-features-container {
    margin: 0px 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bmo-wealth-white;
    padding: 24px 16px;
    gap: 24px;
  }

  .heading {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.2px;
    text-align: center;
    margin-bottom: 32px ;
  }

  .insights-features {
    display: flex;
    padding: 16px;
    align-items: center;
  }

  .vertical-line {
    border-right: 1px solid $bmo-wealth-light-gray;
    height: 9rem;
  }


  @media (max-width: 1100px) {

  }

  @media (max-width: 780px) {
    margin: 64px 0px 0px 0px;
    padding: 48px 0px;;
    .insights-features-container {
      flex-wrap: wrap;
      margin: 0px 32px;
    }

    .insights-features:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    .vertical-line {
      display: none;
    }
    .highlight-points {
      padding: 60px 16px;
    }
  }
}

// Featured Section - End

// Navbar Section - Start

.insights-navbar-section {
  display: flex;
  padding: 0;
  position: relative;

  .side-navbar {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding-right: 32px;
    word-wrap: break-word;
    margin-top: 64px;

    a {
      width: fit-content;
      text-decoration: none;
      color: #646C76;
      font-weight: 400;
      margin-bottom: 44px;
    }
    a:focus {
      outline-offset: 0px;
    }
  }

  .content-with-stickynav {
    width: 100%;
  }

  @media (max-width: 700px) {
    .content-with-stickynav {
      width: 100%;
    }
    
    .side-navbar {
      display: none;
    }

    .content-with-sidenav {
      width: 100%;
    }
  }
}

.sticky-navbar {
  display: none;
}

.sticky-navbar-container {
  position: relative;

  .sticky-navbar.show {
    display: flex;
    background: $bmo-wealth-container-bg-gray;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1000;
    overflow-x: auto;
    :nth-child(2){
      margin-left: 50px;
    }
    :nth-last-child(2){
      margin-right: 50px;
    }
    a {
      margin: 12px 24px;
      text-decoration: none;
      color: $bmo-wealth-accessible-blue;
      font-weight: 700;
      font-size: $font-size-14px;
      white-space: nowrap;
      
    }
    a:focus {
      outline-offset: 0px;
    }
  }

  .left {
    position: fixed;
    top: -12px;
    left: -21px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  .right {
    position: fixed;
    right: -21px;
    top: -12px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  .sticky-navbar-mobile-container.show {
    display: none;
  }

  .sticky-navbar-mobile-container {
    display: none;
  }

  @media (max-width: 700px) {
    .sticky-navbar.show {
      display: none;
    }

    .sticky-navbar-mobile-container.show {
      display: block;
      position: fixed;
      top: 20px;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 100%;
      z-index: 1000;

      .arrow-down {
        margin-top: 0.5px;
        margin-left: 4px;
      }

      .sticky-navbar-mobile {
        display: none;
        flex-direction: column;
        background: $bmo-wealth-container-bg-gray;
        align-items: center;
        position: fixed;
        width: 100%;
        top: 20px;

        a {
          margin: 12px 34px;
          text-decoration: none;
          color: $bmo-wealth-accessible-blue;
          font-weight: 700;
          text-align: center;
          font-size: $font-size-14px;
        }
        a:last-child {
          margin-bottom: 24px;
        }

        a:focus {
          outline-offset: 0px;
        }
      }

      .navigate-to {
        display: flex;
        justify-content: center;
        color: $bmo-wealth-accessible-blue;
        font-weight: 700;
        text-decoration: none;
        font-size: $font-size-14px;
        position: fixed;
        width: 100%;
        z-index: 1000;
        transform: translate(-50%, -50%);
        padding: 12px 34px 12px 34px;
        left: 50%;
        background: $bmo-wealth-container-bg-gray;
      }
    }
  }
}

// Navbar Section - End


// Related Articles Section - Start

.insights-related-articles-section {
  padding: 0px;
  margin: 64px 0px 0px 0px;

  .flex-container {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 12px 0;

    a {
      text-decoration: none;
    }
  }

  .category-tag-link {
    &:focus {
      outline-offset: 0px;
    }
  }

  .category-tag {
    background-color: $bmo-wealth-ultramarine;
    border-radius: 4px;
    padding: 1.5px 6px 1px 6px;
    font-weight: 700;
    font-size: $font-size-12px;
    color: $bmo-wealth-white;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
  }

  .sub-category-tag {
    display: flex;
    background: $bmo-wealth-light-gray;
    color: $bmo-wealth-slate;
    border-radius: 4px;
    padding: 1.5px 6px 1px 6px;
    font-weight: 700;
    font-size: $font-size-12px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
  }

  .date {
    color: $bmo-wealth-Granite;
    font-size: $font-size-14px;
    line-height: 24px;
  }

  .right-img {
    margin-right: 32px;
    width: 174px;
    height: 228px;
    border-radius: 4px;
  }

  .insight-head {
    font-weight: 700;
    font-size: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 4px;
    letter-spacing: 0.35px;
    line-height: 32px
  }

  .insight-head a {
    color: $bmo-wealth-slate !important;
  }

  .insight-des {
    font-weight: 400;
    font-size: $font-size-14px;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0.15px;
  }

  .right-content-container {

    width: 100%;
  }

  .related-articles-container {
    display: flex;
    align-items: center;
    margin-top: 40px;
    max-width: 100%;

    :last-of-type {
      margin-bottom: 0px;
    }
  }

  .related-articles-container-mobile {
    display: none;
  }

  .related-insights-heading {
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
  }

  
  @media (max-width: 675px) {
    .related-articles-container-mobile {
      display: flex;
      flex-direction: column;
      margin-top: 48px;
      .insight-des {
        margin: 5px 0 0 0;
      }
      img{
        border-radius: 4px;
      }

    }

    .related-articles-container {
      display: none;
    }

    .insight-head {
      font-size: $font-size-18px;
    }

    .date {
      margin-top: 16px;
    }

    .flex-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
}

// Related Articles Section - End

// Breadcrumb Section - Start

.insight-details-section {
  .breadcrumbs-wrapper {
    margin-bottom: 50px !important;
    @media (max-width: 675px) {
      margin-bottom: 0px !important;
    }

    .breadcrumbs.desktop {
      a:focus {
        outline-offset: 0px;
      }
    }
  }
}

.insight-details-section .breadcrumb-share-section { // To remove overflowing of row div
  overflow: hidden;
}

@media (max-width: 767.9px) {
  section.breadcrumbs-wrapper .breadcrumbs.mobile {
    display: block;
    padding: 0 5%;
    margin: 24px auto;
  }

}



// Breadcrumb Section - End
