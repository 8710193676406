.custom-carousel{
    padding-bottom: 3%;
    @include small-mobile-and-mobile {
        padding-bottom: 20%;
    }
    @media (max-width: 798px) {
        padding-bottom: 50px;
    }
}
.custom-carousel-control-wrapper{
    display: flex;
    width: 67%;
    margin: -20px 250px 0px 270px;
    justify-content: space-between;
    @include small-mobile-and-mobile {
        width: 90%;
        margin: -30px 1% 0px 0%;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        width: 95%;
        margin: -30px 5% 0px 0%;
    }
    @media (min-width: 992px) and (max-width: 1280px) {
        width: 80%;
        margin: -20px 5% 0px 5%;
    }
    @media (min-width: 1281px) and (max-width: 1751px) {
        width: 70%;
        margin: -20px 15% 0px 10%;
    }
    .custom-carousel-control-prev,
        .custom-carousel-control-next {
            width: 35px;
            height: 35px;
            opacity: 0.9;
            img {
                width: 80px;
                height: 80px;
                @include small-mobile-and-mobile {
                    width: 80px;
                    height: 80px;
                }
            }
        }
        .custom-carousel-control-prev {
            @include tablet {
                left: 20px;
            }
            @include desktop-and-large-screen {
                left: 20px;
            }
            @include small-mobile-and-mobile {
                left: 20px;
            }
        }
        .custom-carousel-control-next {
            @include tablet {
                right: 20px;
                margin-bottom: 20px;
            }
            @include desktop-and-large-screen {
                right: 20px;
            }
            @include small-mobile-and-mobile {
                right: 20px;
            }
        }
}
#custom-carousel-parent-container {
    .carousel-indicators-parent {
        margin-left: auto;
        margin-right: auto;
        .carousel-indicators {
            height: 35px;
            padding: 5px 9px;
            top: 402px;
            margin-left: auto;
            margin-right: auto;
            background: transparent;
            border-radius: 5px;
            color: transparent;
            width: min-content;
            width: -moz-min-content;
            width: -webkit-min-content;
            @include small-mobile-and-mobile {
                top: 98%;
            }
            @media (min-width: 768px) and (max-width: 991px) {
                top: 93%;
            }
            @media (min-width: 992px) and (max-width: 1751px) {
                top: 97%;
            }
            li {
                text-decoration: none;
                border: 1.5px solid gray;
                width: 6px;
                height: 6px;
                display: inline-block;
                color: transparent;
                overflow: hidden;
                border-radius: 50%;
                margin: auto 5px;
                opacity: 1;
                &.active {
                    background-color: #0075be;
                    border: none;
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}
.custom-carousel-inner {
    padding-bottom: 40px;
    overflow: auto; 
    @media (min-width: 768px) and (max-width: 991px) {
        margin-left: 5%;
    }
    @media (min-width: 992px) and (max-width: 1751px) {
        padding-bottom: 40px;
    }
    .carousel-item {
        .carousel-slide-inner-wrapper {
            display: flex;
            justify-content: center;
            @include small-mobile-and-mobile {
                flex-direction: column; 
                margin: 0 5% 0 5%;    
            }
            .slide-image-background {
                width: 275px;
                height: 360px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                border-radius: 4px;
                @include small-mobile-and-mobile {
                    width:100%;     
                }
                @media (min-width: 768px) and (max-width: 1751px) {
                    height: 400px;
                }
            }
            .description-foreground-wrapper {
                height: auto;
                width: 481px;            
                margin-left: 30px;
                @include small-mobile-and-mobile {
                    margin-left: 0;
                    width: 100%;   
                }
                .carousel-slide-description-title {
                    padding: 0;
                    text-align: left;
                    font-weight: bold;
                }
                .custom-carousel-slide-description {
                    width: inherit;
                    margin-top: 50px;
                    text-align: left;
                    background-color: transparent;
                    border: none;
                    @media (min-width: 768px) and (max-width: 991px) {
                        margin-top: 80px;
                    }
                    @media (min-width: 992px) and (max-width: 1751px) {
                        margin-top: 80px;
                    }
                    .custom-description-wrapper {
                        width: 100%;
                        .carousel-slide-description-text {
                            padding-top: 24px;
                            margin-bottom: 15px;
                            text-align: left;
                            padding-right: 90px;
                            padding-left: 5px;
                            @include small-mobile-and-mobile {
                                padding-right: 0px;
                            }
                            @media (min-width: 992px) and (max-width: 1751px) {
                                margin-bottom: 15px;
                            }
                        }
                        .carousel-slide-description-job-title{
                            font-weight: bold;
                            padding-left: 5px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }}