section.hero-banner-wrapper {
    &:not(.sub-brand-banner) {
        padding-bottom: 48px;
        @include tablet {
            padding-bottom: 64px;
        }
        @include desktop-and-large-screen {
            padding-bottom: 80px;
        }
        &.image-background {
            padding-bottom: 8px;
            @include tablet {
                padding-bottom: 16px;
            }
            @include desktop-and-large-screen {
                padding-bottom: 0;
            }
        }
    }
    .hero-banner-image {
        background-repeat: no-repeat;
        background-size: cover;
        @include tablet {
            height: 416px;
        }
        @include desktop-and-large-screen {
            height: 376px;
        }
    }
    .hero-image-right,
    .hero-image-left {
        @include container-center-align;
        display: flex;
        .column:first-child {
            padding: 0 16px;
            @include tablet {
                padding: 0 12px;
            }
        }
        .hero-image {
            display: none;
            @include tablet {
                display: block;
            }
            @include desktop-and-large-screen {
                display: block;
            }
            .hero-banner-image {
                box-shadow: 0 4px 24px 0 rgba(0, 25, 40, 0.1), 0 2px 8px 0 rgba(0, 25, 40, 0.04);
            }
            &.image-right {
                padding-right:0;
                padding-left: 8px;
                .hero-banner-image {
                    border-bottom-left-radius: 15px;
                }
            }
            &.image-left {
                padding-left:0;
                .hero-banner-image {
                    border-bottom-right-radius: 15px;
                }
            }
        }
        .description-wrapper {
            padding: 8px 0 0 0;
            @include tablet {
                padding: 96px 56px 0 74px;
            }
            @include desktop-and-large-screen {
                padding: 104px 104px 0 127px;
            }
            .hero-banner-description {
                @include tablet {
                    max-width: 348px;
                }
                @include desktop-and-large-screen {
                    max-width: 585px;
                }
                .hero-banner-description-text {
                    margin-top: 16px;
                    letter-spacing: 0.15px;
                    @include small-mobile-and-mobile {
                        margin-bottom: 0;
                    }
                    @include tablet {
                        margin-top: 16px;
                        margin-bottom: 24px;
                    }
                    @include desktop-and-large-screen {
                        margin-top: 24px;
                        margin-bottom: 24px;
                    }
                }
            }
        }
    }
    &.full-bleed {
        .hero-image-background-wrapper {
            .hero-image-background {
                max-width: 100%;
            }
        }
    }
    .hero-image-background-wrapper {
        .hero-image-background {
            @include container-center-align;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 270px;
            @include tablet {
                height: 300px;
            }
            @include desktop-and-large-screen {
                height: 370px;
            }
        }
        .description-foreground-wrapper {
            @include container-center-align;
            width: calc(100% - 32px);
            @include tablet {
                width: calc(100% - 48px);
            }
            @include desktop-and-large-screen {
                max-width: 995px;
            }
            .hero-banner-description-title {
                padding: 0;
            }
            .hero-banner-description {
                @include hero-banner-shadow-mixin;
                bottom: 40px;
                position: relative;
                text-align: center;
                background: $bmo-wealth-white;
                padding: 40px 24px 40px 24px;
                @include tablet {
                    bottom: 48px;
                    padding: 48px 62px 48px 62px;
                }
                @include desktop-and-large-screen {
                    bottom: 48px;
                    padding: 48px 102px 48px 102px;
                }
                .hero-banner-description-text {
                    margin-top: 24px;
                    // margin-bottom: 40px;
                }
            }
        }
    }
    h1.hero-banner-description-title {
        font-size: $font-size-36px;
        line-height: $line-height-40px;
        letter-spacing: normal;
        font-stretch: normal;
        font-style: normal;
        @include tablet-and-desktop {
            font-size: $font-size-44px;
            line-height: $line-height-48px;
            letter-spacing: -0.5px;
        }
    }
    p.hero-banner-description-text {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        letter-spacing: 0.15px;
    }
}

section.hero-banner-wrapper.sub-brand-banner {
    .description-foreground-wrapper {
        background: $bmo-wealth-light-gray;
        width: 100%;
        max-width: 100%;
        padding-bottom: 8px;
        @include tablet {
            padding-bottom: 16px;
        }
        @include desktop-and-large-screen {
            padding-bottom: 0;
        }
        .hero-banner-description {
            margin: 0 auto;
            width: calc(100% - 32px);
            @include tablet {
                width: calc(100% - 48px);
            }
            @include desktop-and-large-screen {
                max-width: 995px;
            }
        }
    }
    .hero-image-background-wrapper {
        .hero-image-background {
            max-width: none;
        }
    }
}


section.hero-banner-wrapper.sub-brand-left-right-banner {
    background: $bmo-wealth-light-gray;
    .image-wrapper {
        .hero-banner-image {
            border-bottom-left-radius: 17px;

        }
    }
}

section.hero-banner-wrapper.image-background + section.overlapping-image-banner-wrapper {
    @include desktop-and-large-screen {
        padding-top: 36px;
    }
}
