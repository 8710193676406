section.breadcrumbs-wrapper {
    font-size: $font-size-14px;
    line-height: $line-height-24px;
    letter-spacing: 0.25px;
    color: $bmo-wealth-black;
    font-weight: $font-weight-bold;
    @include tablet-and-desktop {
        .breadcrumb-inner-wrapper {
            z-index: 100;
            position: absolute;
            @include tablet {
                left: 24px;
            }
        }
    }

    &.sub-brand {
        .breadcrumbs.desktop {
            ol, li {
                align-items: center;
            }
            ol {
                padding: 4px 14px;
                background-color: $bmo-wealth-white;
                border-radius: 4px;
                li {
                    span.current-page {
                        width: auto;
                    }
                    img.chevron-right {
                        margin: 0 10.5px 1px 11.5px;
                    }
                }
            }
        }
        .breadcrumbs.mobile {
            position: absolute;
            ol {
                background-color: $bmo-wealth-white;
                border-radius: 4px;
                padding: 4px 8px;
            }
        }
    }

    &:not(.sub-brand) {
        img.white {
            display: none;
        }
    }

    ol, li {
        list-style-type: none;
        display: flex;
        padding-left: 0;
    }
    ol li {
        span.current-page  {
            @include tablet-and-desktop {
                width: 225px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @include tablet {
                    width: 131px;
                }
            }
        }
    }

    .breadcrumbs.desktop {

        margin: 24px auto;
        .chevron-right {
            height: 23px;
            width: 17px;
            margin: 0 10.5px 2px 11.5px;
        }

        @include small-mobile-and-mobile {
            display: none;
        }
    }

    .breadcrumbs.mobile {
        margin: 16px auto;
        display: none;

        .chevron-arrow-back {
            height: 23px;
            width: 23px;
            margin-right: 4px;
        }

        a {
            display: flex;
        }

        @include small-mobile-and-mobile {
            display: block;
        }
    }

}
