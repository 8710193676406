.alert-notification {
  display: block;
  padding: 16px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .bmo-container {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    display: flex;
    .alert-close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 0;
      border: none;
      border-radius: 4px;
      background-color: transparent;
      background-image: url('/static/assets/svgs/header-svg/close-granite.svg');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center center;
      min-width: 24px;
      padding: 0;
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
  .alert-icon {
    min-width: 24px;
    width:24px;
    height: 24px;
    background-repeat: no-repeat;
  }
  &.alert-success {
    background: $bmo-wealth-alert-success-bg;
    border-bottom: 2px solid $bmo-wealth-positive;
    .alert-icon {
      background-image: url('/static/assets/svgs/alert-svg/success-green.svg');
    }
  }
  &.alert-info {
    background: $bmo-wealth-alert-info-bg;
    border-bottom: 2px solid $bmo-wealth-accessible-blue;
    .alert-icon {
      background-image: url('/static/assets/svgs/alert-svg/announcement-blue.svg');
    }
  }
  &.alert-warning {
    background-color: $bmo-wealth-alert-warning-background;
    border-bottom: 2px solid $bmo-wealth-alert-warning-border;
    .alert-icon {
      background-image: url('/static/assets/svgs/alert-svg/warning-yellow.svg');
    }
  }
  &.alert-notification.alert-danger {
    background: $bmo-wealth-alert-danger-bg;
    border-bottom: 2px solid $bmo-wealth-negative;
    .alert-icon {
      background-image: url('/static/assets/svgs/alert-svg/error-red.svg');
    }
  }
  .alert-message-wrap {
    margin: 0 30px 0 15px;
    & > *:not(:last-child){
      margin-bottom: 10px;
    }
    p {
      font-size: $font-size-14px;
      line-height: $line-height-24px;;
      color: $bmo-wealth-slate;
      font-weight: $font-weight-normal;
      &:last-child {
        margin: 0;
      }
      a {
        font-size: $font-size-14px;
      }
    }
  }
}
