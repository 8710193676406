div.cta-link {
  &.LEFT,
  &.Left,
  &.left {
    display: flex;
    justify-content: flex-start;
  }
  &.RIGHT,
  &.Right,
  &.right {
    display: flex;
    justify-content: flex-end;
  }
  &.CENTER,
  &.Center,
  &.center {
    display: flex;
    justify-content: center;
  }
}
