section.tab-panel-section-wrapper {

    padding-top: 48px;
    padding-bottom: 48px;

    @include tablet {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @include desktop-and-large-screen {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    h2.title{
        text-align: center;
        line-height: $line-height-40px;
        width: 100%;
        margin: 0 auto 24px;
        @include tablet {
            max-width: 596px;
        }
        @include tablet-and-desktop {
            font-size: $font-size-44px;
            line-height: $line-height-48px;
        }
        @include desktop-and-large-screen {
            max-width: 789px;
        }
    }

    p.description {
        text-align: center;
        letter-spacing: 0.15px;
        max-width: 471px;
        margin: 0 auto 48px;
        line-height: $line-height-22px;
        @include tablet {
            line-height: $line-height-24px;
        }
        @include desktop-and-large-screen {
            max-width: 584px;
            line-height: $line-height-24px;
        }
    }

    .tab-heading {
        font-size: $font-size-16px;
        line-height: $line-height-24px;
        letter-spacing: 0.25px;
        font-weight: $font-weight-bold;
        text-align: center;
        max-width: 471px;
        margin: 0 auto 24px;
        @include desktop-and-large-screen {
            max-width: 584px;
        }
    }

    div.tab-heading-desktop {
        @include small-mobile-and-mobile {
            display: none;
        }
    }

    div.tab-heading-mobile {
        @include tablet-and-desktop {
            display: none;
        }
    }

    .tab-panel {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 24px;
        padding-left: 16px;

        &::-webkit-scrollbar {
            display: none;
        }
        scrollbar-width: none;
        @include tablet {
            max-width: 720px;
            margin: 0 auto;
            width: 100%;
        }
        @include tablet-and-desktop{
            @include screen-max-width-mixin;
            justify-content: center;
        }
        @include desktop-and-large-screen {
            text-align: center;
        }
    }

    .tab-panel-wrapper {
        text-decoration: none;
        background-color: $bmo-wealth-white;
        margin: 0 4px;
        flex: 0 0 auto;
        @include hero-banner-shadow-mixin;
        width: 140px;

        @include tablet-and-desktop{
            width: 138px;
            height: 168px;
        }

        @include desktop-and-large-screen {
            width: 174px;
            margin: 0 16px;
        }

        .box {
            display: none;
        }
        &.active {
            border: 2px solid $bmo-wealth-accessible-blue;
            .box {
                display: block;
                float: right;
                text-align: center;
                width: 24px;
                height: 24px;
                border-radius: 2px;
                background-color: $bmo-wealth-accessible-blue;
                img {
                    width: 26px;
                    height: 19px;
                }
            }
        }

        .need-logo-wrapper {
            text-align: center;
            padding: 24px;
            img{
                height: 64px;
                width: 80px;
            }
        }

        .tab-content-wrapper {
            padding: 0 8px;
            .need-name {
                text-align: center;
                color: $bmo-wealth-accessible-blue;
                font-size: $font-size-14px;
                line-height: $line-height-20px;
                letter-spacing: 0.25px;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
            }
        }
    }

    .tab-panel-wrapper:first-child{
        margin-left: 0;
    }

    .tab-panel-wrapper:last-child{
        margin-right: 0;
    }

    .tab-content-wrapper {
        .service-title-text {
            font-size: $font-size-24px;
            font-weight: $font-weight-bold;
            line-height: $line-height-32px;
            letter-spacing: normal;
            text-align: center;
            margin: 48px auto 24px;
            width: 100%;
            @include tablet {
                max-width: 596px;
            }
            @include desktop-and-large-screen {
                max-width: 584px;
            }
        }
        .cta-button-secondary {
            margin-top: 40px;
        }
        .needs-services {
            @include tablet {
                padding: 0 70px;
            }
            @include desktop-and-large-screen {
                padding: 0 205px;
            }
        }
    }

    .needs-services-container {
        padding: 0 24px;
        @include tablet {
            padding: 0 12px;
        }
        @include small-mobile-and-mobile {
            padding: 0 16px;
        }
        .needs-services {
            justify-content: center;
            width: 100%;
            margin: 0 auto;
        }
    }

    .service-content-wrapper {
        padding: 0;
        @include tablet {
            padding: 0 12px;
        }
        @include desktop-and-large-screen {
            padding: 0 16px;
        }
        .content {
            text-align: center;
            a {
                display: inline-block;
                font-size: $font-size-18px;
                line-height: $line-height-28px;
                letter-spacing: normal;
                cursor: pointer;
                @include tablet {
                    font-size: $font-size-18px;
                }
                @include desktop-and-large-screen {
                    font-size: $font-size-22px;
                }
                span {
                    &.tab-panel-service-links {
                        display: block;
                    }
                }
            }
            .description{
                text-align: center;
                letter-spacing: 0.15px;
                margin: 16px 0 40px;
                @include mobile-and-tablet {
                    font-size: $font-size-14px;
                    line-height: $line-height-22px;
                }
                @include desktop-and-large-screen {
                    margin: 16px 0 40px;
                    line-height: $line-height-24px;
                }
            }
        }
    }

    .insights-inner-wrapper:last-child {
        @include small-mobile-and-mobile {
            padding-bottom: 0;
        }
    }

    .cta-button-secondary {
        display: flex;
        margin: 0 auto;
        .cta-button-link {
            margin: 0 auto;
        }
    }

    @include small-mobile-and-mobile {
        .needs-services{
            &.listing {
                .service-content-wrapper:last-child {
                    .content {
                        .description {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @include tablet-and-desktop{
        .service-content-wrapper:nth-last-child(-n+3) {
            .content {
                .description {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include tablet-and-desktop{
        .service-content-wrapper:nth-child(n + 10) {
            .content {
                margin-top: 40px;
            }
        }
    }


    @import './insights.scss';
    @import './individual_needs.scss';
    @import './horizontal_tabs.scss';
}
