@media print {
  @page {
    padding: 0;
    border: none;
    size: A4;
    margin: 0.4cm 0.4cm 0.6cm 0.4cm;
}
  .regular-header {
      .regular-header-wrapper-tablet {
          display: none !important;
      }
      .regular-header-plugin-wrapper,
      .secondary-header-plugin-wrapper {
          display: block !important;
      }
      .sign-in-content-wrapper {
        display: none !important;
      }
      .secondary-header-menu {
        .desktop.dropdown-content {
          display: none !important;
        }
      }
      a {
          text-decoration: solid;
      }
  }
  .footer-wrapper {
      .footer-content {
          .links {
              .contact-us,
              .soocial-links {
                  display: inline-block !important;
              }
              .soocial-links {
                  float: right !important;
              }
          }
          .nav-links ul li {
              width: auto !important;
          }
      }
      .footer-body {
          .housing-lender-icon {
              top: 18px !important;
          } 
      } 
      a {
          text-decoration: solid;
      }
  }
  .insight-details-section {
      .insight-details-row-wrapper {
          .insight-details-column-wrapper-big {
              flex: 0 0 66.666667%;
              max-width: 66.666667%;
              .insight-content {
                  padding: 0 !important;
                  padding-left: 141px !important;
                  margin-top: 40px !important;
              }
          }
  
          .insight-details-column-wrapper-small {
              flex: 0 0 33.333333%;
              max-width: 33.333333%;
          }
          
      }
      .breadcrumb-share-section {
          .row {
              &>div {
                  flex: 0 0 50%;
                  max-width: 50%;
              }
              .social-share-section {
                  .social-share {
                      margin: 24px auto;
                  }
                  .social-share:not(.print) {
                      position: absolute;
                      right: 108px;
                  }
                  .print {
                      .include-printer-icon {
                          top: 24px;
                      }
                  }
              }
          }

          .breadcrumbs.desktop {
              display: block !important;
          }
          .breadcrumbs.mobile {
              display: none !important;
          }
      }
      
      a {
          text-decoration: solid;
      }
  }

  .insights-listing-plugin-wrapper {
      page-break-before: always;
      .insight-listing-column-wrapper {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
      }
      a {
          text-decoration: solid;
      }
  }
  .related-services-container {
      .related-services-cards {
          .service-content-wrapper {
              flex: 0 0 50%;
              max-width: 50%;
          }
      }
  }
  * {
      -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
      print-color-adjust: exact !important; /*Firefox*/
  }
}
