.document-container {
    height: max-content;
    padding: 0;
    @include tablet {
        max-width: 100%;
    }
    .document-wrapper {
        padding: 0 0 40px 0;
        @include tablet {
            padding: 0 0 40px 0;
            display: inline-block;
        }
        @include desktop-and-large-screen {
            padding-left: 0;
            padding-right: 0;
        }
        .document-cards {
            padding: 0;
            max-width: 343px;
            margin: 0 auto;
            @include tablet {
                max-width: 348px;
            }
            @include desktop-and-large-screen {
                padding: 0;
                max-width: 379px;
            }
            .document-section {
                padding: 24px 24px 40px 24px;
                margin: 0 auto;
                background-color: $bmo-wealth-container-bg-gray;
                border-radius: 4px;
                .heading-tag {
                    font-size: $font-size-16px;
                    font-weight: $font-weight-bold;
                    color: $bmo-wealth-slate;
                    margin-bottom: 16px;
                }
                .download-document {
                    display: flex;
                    font-size: $font-size-16px;
                    .download-file {
                        word-break: break-word;
                        margin-bottom: 8px;

                        @include mobile-and-tablet {
                            max-width: 265px;
                        }

                        @include desktop-and-large-screen {
                            max-width: 300px;
                        }
                    }
                    .download-insight {
                        img {
                            margin-left: 4px;
                            height: 22px;
                            width: 22px;
                            &:not(.active) {
                                display: block;
                            }
                            &.active {
                                display: none;
                            }
                        }
                    }
                }
                a.download-document:hover, a.download-document:focus {
                    img {
                        &:not(.active) {
                            display: none;
                        }
                        &.active {
                            display: block;
                        }
                    }
                }
                .pdf-file-size {
                    font-size: $font-size-12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $line-height-16px;
                    letter-spacing: 0.25px;
                    color: $bmo-wealth-slate;
                    @include tablet {
                        display: block;
                    }
                    @include desktop-and-large-screen {
                        display: block;
                    }
                }
            }
        }

        &.emag-section {
            .document-section {
                min-height: 152px;
            }
        }
        &.pdf-section {
            .document-section {
                min-height: 128px;
            }
        }
    }
}
