section.insight-search-section {
    padding: 64px 16px 48px;
    position: relative;

    @include tablet {
        padding: 96px 24px 64px;
    }
    @include desktop-and-large-screen {
        padding: 104px 0 80px;
    }

    .form-field.input .clear-icon {
        @include small-mobile-and-mobile {
            bottom: 34px;
        }
    }

    .header-wrapper {
        h1 {
            min-width: 343px;
            margin: 0 auto;
            font-size: $font-size-44px;
            font-weight: $font-weight-light;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-48px;
            letter-spacing: -0.5px;
            text-align: center;
            @include small-mobile-and-mobile {
                font-size: $font-size-36px;
                line-height: $line-height-40px;
                letter-spacing: normal;
            }
            @include tablet {
                max-width: 472px;
            }
            @include desktop-and-large-screen {
                max-width: 584px;
            }
        }
        .description {
            min-width: 343px;
            margin: 0 auto;
            font-size: $font-size-16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;
            text-align: center;
            margin: 16px auto 24px;
            @include tablet {
                max-width: 472px;
                margin: 16px auto 48px;
            }
            @include desktop-and-large-screen {
                max-width: 589px;
                margin: 24px auto 48px;
            }
        }
    }

    .insight-search-wrapper {
        min-width: 343px;
        width: 100%;
        z-index: 4;
        background: $bmo-wealth-white;
        padding: 32px 23px 24px;
        @include hero-banner-shadow-mixin;
        margin: 0 auto 48px;

        position: relative;
        left: 0;
        &.active-search-state {
            position: absolute;
            left: 16px;
            @include small-mobile-and-mobile {
                width: calc(100% - 32px);
            }
            @include tablet {
                left: 24px;
                width: calc(100% - 48px);
            }
            @include desktop-and-large-screen {
                left: 142px;
            }
        }

        @include tablet {
            margin: 0 auto 64px;
        }

        @include desktop-and-large-screen {
            margin: 0 auto 80px;
            width: calc(100% - 285px);
            max-width: 995px;
        }

        .search-block-wrapper {

            @include tablet-and-desktop {
                display: flex;
            }

            .input-search-wrapper {

                width: 100%;

                @include tablet-and-desktop {
                    display: flex;
                }
                .form-label-group {
                    input {
                        width: calc(100% - 34px);
                        @include mobile-and-tablet {
                            width: calc(100% - 42px);
                        }
                    }
                }
                .form-field.input {
                    margin-bottom: 0;
                    @include tablet {
                        min-width: 528px;
                        max-width: 795px;
                    }
                    @include desktop-and-large-screen {
                        max-width: 945px;
                    }
                }
                label.slide-label {
                    &:before {
                        background: $bmo-wealth-gray;
                    }
                    &.active-label:after {
                        background: $bmo-wealth-gray;
                    }
                    &:after {
                        background: $bmo-wealth-gray;
                    }
                }
                input[type="text"]:not(:focus) + label.slide-label.active-label:before {
                    background: $bmo-wealth-gray;
                }
                input[type="text"]:focus + label.slide-label:before {
                    background: $bmo-wealth-gray;
                }
                input[type="text"]:focus+ label.slide-label.active-label:before {
                    background: $bmo-wealth-gray;
                }
                input.search-input:focus + label.slide-label:before {
                    background: $bmo-wealth-gray;
                }
            }

            button.search-button {
                background: $bmo-wealth-accessible-blue;
                color: $bmo-wealth-white;
                font-size: $font-size-14px;
                font-weight: $font-weight-bold;
                padding: 16px 32px;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-24px;
                letter-spacing: 0.25px;
                height: 56px;
                border: none;
                border-radius: 28px;
                &:hover {
                    border-color: $bmo-wealth-ultramarine;
                    background-color: $bmo-wealth-ultramarine;
                }
                @include small-mobile-and-mobile {
                    padding: 16px 88px;
                    margin-top: 32px;
                }
                @include tablet {
                    margin-left: 24px;
                }
                @include desktop-and-large-screen {
                    margin-left: 32px;
                }
            }
        }

        .helper-text {
            position: absolute;
            top: 45px;
            max-width: 795px;
            height: 16px;
            font-size: $font-size-14px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            color: $bmo-wealth-Granite;
            margin-top: 8px;
        }

        .results {
            display: none;
            .result-container-section {
                margin-top: 40px;
                .loaded-results {
                    text-align: left;
                    a {
                        margin: 0 16px 0 0;
                    }
                    &.your-needs {
                        a {
                            font-size: $font-size-18px;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: $font-size-28px;
                            letter-spacing: 0.12px;
                            margin-right: 0;
                        }
                        .column {
                            padding: 0;
                            margin-bottom: 16px;
                        }
                    }
                    &.our-services {
                        a {
                            margin: 0 0 16px 0;
                            font-size: $font-size-18px;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: $font-size-28px;
                            letter-spacing: normal;

                        }
                        .service-links {
                            .column {
                                margin-bottom: 16px;
                                @include small-mobile-and-mobile {
                                    display: none;
                                }
                            }
                            .column:nth-of-type(-n+2) {
                                @include small-mobile-and-mobile {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.topics {
                    display: none;
                }
                &.insights {
                    display: none;
                    a {
                        margin-left: 0;
                        margin-bottom: 16px;
                        font-size: $font-size-18px;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: $font-size-28px;
                        letter-spacing: normal;
                        margin-right: 0;
                    }
                    .all-insight-links {
                        .link {
                            margin-bottom: 16px;
                        }
                        .link:nth-last-child(-n+2) {
                            @include tablet {
                                margin-bottom: 0;
                            }
                        }
                        .link:nth-last-child(-n+1) {
                            @include small-mobile-and-mobile {
                                margin-bottom: 0;
                            }
                        }
                        a {
                            display: block;
                        }
                        a:last-child {
                            margin: 0;
                        }
                    }
                }

                &.services {
                    margin-top: 24px;
                }
            }
            .expand-links {
                .hide-services,
                .show-services {
                    display: none;
                }
                @include small-mobile-and-mobile {
                    margin: 24px 0 16px;
                    .show-services {
                        display: block;
                    }
                }
                a {
                    display: block;
                    font-size: $font-size-14px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $line-height-20px;
                    letter-spacing: 0.15px;
                    margin: 0;
                }
                img {
                    width: 8px;
                    height: 16px;
                    margin-left: 7px;
                }
            }
            .title {
                max-width: 343px;
                @include tablet {
                    max-width: 596px;
                }
                @include desktop-and-large-screen {
                    max-width: 789px;
                }
                font-size: $font-size-24px;
                line-height: $line-height-32px;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                font-weight: $font-weight-light;
            }
        }

    }

    .insight-card {
        max-width: 343px;
        margin: 0 auto;
        @include insight-search-card-mixin;
        @include tablet {
            margin-bottom: 52px;
            max-width: 720px;
        }
        @include desktop-and-large-screen {
            max-width: 995px;
        }
    }

    .insight-card:last-child {
        margin-bottom: 0;
    }

    .trending-insight-wrapper {

        @include desktop-and-large-screen {
            padding: 0 142px;
        }

        h2.title {
            font-size: $font-size-44px;
            line-height: $line-height-48px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.5px;
            text-align: center;
            @include small-mobile-and-mobile {
                font-size: $font-size-36px;
                line-height: $line-height-40px;
                letter-spacing: normal;
                font-stretch: normal;
            }
        }
        .insights-card-wrapper {
            margin-bottom: 16px;
        }
        .loaded-results {
            display: block;
            margin: 24px 0 40px;
        }
        &.active-state {
            @include desktop-and-large-screen {
                margin-top: 253px;
            }
        }

        .insight-card {
            .card-content {
                display: flex;
            }
        }
        .insight-card:nth-child(odd) {
            @include small-mobile-and-mobile {
                display:flex;
                flex-direction: column-reverse !important;
            }
        }
    }

    .loaded-results {
        text-align: center;
        margin: 16px 0 0;

        span {
          font-family: $font-family;
          font-size: $font-size-14px;
          font-weight: $font-weight-medium;
          font-stretch: normal;
          font-style: normal;
          line-height: $line-height-24px;
          letter-spacing: 0.25px;
        }
        a {
          margin-left: 16px;
          font-size: $font-size-14px;
          font-stretch: normal;
          font-style: normal;
          line-height: $line-height-24px;
          letter-spacing: 0.25px;
        }
    }

    .result-block {
        display: none;
        padding-bottom: 40px;
        @include tablet {
            padding-bottom: 64px;
        }
        @include desktop-and-large-screen {
            padding: 80px 0;
        }
        text-align: center;

        &.found {
            display: block;
        }

        p,h2,b {
            color: $bmo-wealth-slate;
            font-stretch: normal;
            font-style: normal;
            margin: 0;
        }
        .results-message {
            width: 100%;
            margin: 0 auto;
            word-break: break-word;
            @include ie-browser {
                word-break: break-all;
            }
            @include tablet {
                width: 720px;
            }
            @include desktop-and-large-screen {
                max-width: 789px;
            }
            h2 {
                font-size: $font-size-24px;
                font-weight: $font-weight-bold;
                line-height: $line-height-32px;
                letter-spacing: normal;
                b {
                    font-size: $font-size-24px;
                    font-weight: $font-weight-bold;
                    line-height: 1.33;
                    letter-spacing: normal;
                }
            }
            p {
                font-size: $font-size-16px;
                font-weight: normal;
                line-height: 1.5;
                letter-spacing: 0.15px;
                margin-bottom: 40px;
            }
        }
        &.found-insight {
            padding-bottom: 0;
        }
        @include desktop-and-large-screen {
            padding-top: 0;
        }
    }

    .search-results {
        display: none;
        @include desktop-and-large-screen {
            padding: 0 142px;
        }
        .insight-results-card-wrapper {
            margin-top: 40px;
            .insight-card {
                @include tablet {
                    margin-bottom: 52px;
                }
            }
            .insight-card:last-child {
                margin-bottom: 0;
            }
        }
        &.active-state {
            @include desktop-and-large-screen {
                margin-top: 253px;
            }
        }
    }

    nav {
        .pagination {
            margin: 40px auto 0;
            @include tablet {
                margin: 76px auto 0;
            }
        }
    }

    .insight-card:last-child {
        margin-bottom: 0;
    }
}
