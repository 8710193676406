section.container-background-primary-white {
    background: $bmo-wealth-white;
}

section.container-background-primary-gray {
    background: $bmo-wealth-container-bg-gray;
}

section.container-background-sub-brand-gray {
    background: $bmo-wealth-light-gray;
}
