.insight-authors {
  &.our-team-section-primary {
    padding: 0 !important;
    @include insight-banker;

    .our-team-wrapper-primary {
      @include banker-card-default-show;

      &.executive-cards {
        @include author-type-card;

        .our-team-card-wrapper {
          height: 286px;
          @include tablet {
            max-width: 379px;
            height: 280px;
            width: 348px;
          }

          @include desktop-and-large-screen {
            height: 204px;
            width: unset;
          }
        }
      }
    }
  }

  &.our-team-section-secondary {
    padding: 0 !important;

    @include insight-banker;

    .our-team-wrapper-secondary {
      @include banker-card-default-show;

      &.thought-leader-cards {
        @include author-type-card;

        .our-team-card-wrapper {
          height: 149px;
          @include tablet {
            width: 348px;
            height: 160px;
            max-width: 379px;
          }

          @include desktop-and-large-screen {
            height: 160px;
          }
        }
      }
    }
  }
}
