.insight-series-container {
    @include tablet {
        padding: 0;
        max-width: 100%;
    }
    @include small-mobile-and-mobile {
        padding: 0;
    }
    @include desktop-and-large-screen {
        padding: 0;
    }
    .insight-series-wrapper {
        padding: 0;
        @include tablet {
            padding: 0;
        }
        .series-cards {
            padding: 0;
            max-width: 343px;
            margin: 0 auto;
            @include tablet {
                max-width: 348px;
                display: inline-block;
            }
            @include desktop-and-large-screen {
                max-width: 379px;
                padding: 0;
            }
            .series-section {
                height: auto;
                padding: 24px 24px 16px;
                background-color: $bmo-wealth-white;
                color: $bmo-wealth-slate;
                margin: 0 auto;
                margin-bottom: 40px;
                @include hero-banner-shadow-mixin;

                .series-info {
                    font-weight: $font-weight-bold;
                    color: $bmo-wealth-slate;
                    margin-bottom: 24px;
                }
            }
            .series-accordion-wrapper {
                display: flex;
                .arrow-up,
                .arrow-down {
                    float: right;
                }
                @include tablet-and-desktop {
                    .arrow-up,
                    .arrow-down {
                        float: left;
                        margin-right: 24px;
                    }
                }
                .chevron {
                    .arrow-down {
                        display: none;
                    }
                }
                .chevron.collapsed {
                    .arrow-down {
                        display: inline-block;
                    }
                    .arrow-up {
                        display: none;
                    }
                }
                .series-accordion-inner {
                    width: 100%;
                    .series-accordion {
                        width: 100%;
                        font-size: $font-size-18px;
                        font-weight: $font-weight-bold;
                        color: $bmo-wealth-slate;
                        border-top: 1px solid $bmo-wealth-light-gray;
                        padding: 18px 28px 18px 24px;
                        img {
                            position: relative;
                            top: 5px;
                        }
                        @include tablet-and-desktop {
                            padding: 18px 25px 18px 0;
                        }
                    }

                    .series-accordion-data {
                        border-top: 1px solid $bmo-wealth-light-gray;
                        .series-insight-image {
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                            height: 133px;
                            padding-bottom: 10px;
                            border-radius: 4px;
                            @include tablet {
                                height: 135px;
                            }
                            @include desktop-and-large-screen {
                                height: 148px;
                            }
                        }
                        .series-title-block {
                            padding-top: 24px;
                            .series-title {
                                display: block;
                                font-size: $font-size-22px;
                            }
                        }

                        .series-content {
                            font-size: $font-size-16px;
                            color: $bmo-wealth-slate;
                            padding-top: 8px;
                        }

                        .series-publication-details {
                            font-size: $font-size-12px;
                            color: $bmo-wealth-slate;
                            padding-top: 16px;
                            padding-bottom: 24px;
                            span {
                                padding-right: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
