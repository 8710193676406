section.footer-wrapper {
  background-color: $bmo-wealth-accessible-blue;;
  @include mobile-and-tablet {
    padding: 0;
  }
  .footer-content {
    position: relative;
    @include small-mobile-and-mobile {
      padding: 24px 16px 16px 16px;
    }
    @include tablet {
      padding: 20px 24px 48px 24px;
    }
    padding-top: 20px;
    padding-bottom: 40px;
    background-color: $bmo-wealth-accessible-blue;
    .links {
      .contact-us,
      .social-links {
        @include small-mobile-and-mobile {
          display: block;
        }
        display: inline-block;
      }
      .social-links {
        ul li {
          padding: 1px 16px 0 0;
        }
        @include small-mobile-and-mobile {
          float: none;
        }
        @include tablet {
          ul li {
            
            &:last-child {
              padding-right: 0;
            }
            padding-right: 15px;
          }
        }
        float: right;
        display: flex;
      }
    }
    .contact-us {
      ul li:not(.contact-us-link) {
        a{
          text-decoration: underline;
        }
      }
    }
    .contact-us,
    .social-links {
      li, span {
        color: $bmo-wealth-white;
        font-size: $font-size-14px;
        line-height: $line-height-24px;
        letter-spacing: 0.25;
      }
      span {
        margin-right: 24px;
      }
      ul {
        padding: 0;

        li {
          display: inline-block;
          padding-right: 24px;
          color: $bmo-wealth-white;
          &:last-child {
            padding-right: 0;
          }
          a {
            @include bmo-font(14px, bold);
            text-decoration: none;
            color: $bmo-wealth-white;
            display: inline-block;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-24px;
            letter-spacing: 0.25px;
            img {
              width: 20px;
              height: 20px;
              &:not(.active) {
                display: block;
              }
              &.active {
                  display: none;
              }
            }
          }
          a:hover, a:focus {
            img {
                &:not(.active) {
                    display: none;
                }
                &.active {
                    display: block;
                }
            }
          }
          &.contact-us-link-header {
            @include bmo-font(14px, $font-weight-medium);
            line-height: $line-height-24px;
            letter-spacing: 0.25;
            p {
              margin-bottom: 0;
              @include bmo-font(14px, $font-weight-normal);
            }
            a {
              @include bmo-font(14px, $font-weight-normal);
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .nav-links {
      display: block !important;
      padding-top: 10px;
      ul {
        display: inline-block;
      }
      @include small-mobile-and-mobile {
        ul li {
          width: 50%;
          float: left;
          padding-bottom: 12px;
        }
      }
      @include tablet {
        ul li {
          &:nth-last-child(3) {
            padding-right: 19px;
          }
          &:last-child {
            padding-top: 12px;
          }
          &:last-child,
          &:nth-last-child(2) {
            padding-right: 0;
          }
        }
      }
    }
    .footer-body {
      position: relative;
      padding-top: 24px;
      .housing-lender-icon {
        position: absolute;
        top: -13px;
        right: 0;
        @include small-mobile-and-mobile {
          top: 18px;
          right: 0;
        }
        @include tablet {
          top: -12px;
          right: 0;
        }
        img {
          width: 38px;
          height: auto;
        }
      }
      @include tablet {
        padding-top: 13px;
      }
      @include small-mobile-and-mobile {
        padding-top: 31px;
      }
      ul {
        margin-top: -15px;
        padding-inline-start: 15px;
      }
      li, p, a {
        @include bmo-font(12px);
        color: $bmo-wealth-white;
        font-stretch: normal;
        font-style: normal;
        line-height: $line-height-16px;
        letter-spacing: 0.25px;
      }
      p {
          &:first-child {
            padding-right: 40px;
          }
      }
      a {
        text-decoration: underline;
      }
    }
  }
}

.footer-modal.modal {
  overflow: auto;
  padding-top: 100px;
  opacity: 1;
  background-color: $bmo-wealth-black-shade;
  &.disclosures {
    .modal-content {
      .modal-body {
        p {
          span {
            strong {
              line-height: $bmo-wealth-modal-line-height;
              font-size: $font-size-17px;
            }
            a {
              line-height: $bmo-wealth-modal-line-height;
              letter-spacing: normal;
            }
          }
        }
      }
    }
  }
  .modal-content {
    width: 80%;
    margin: 0 auto;
    background-color: $bmo-wealth-white-shade;
    border: 1px solid $bmo-wealth-grey-shade;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: .4s;
    animation-name: animatetop;
    animation-duration: .4s;
    border-radius: 0;
  }
  .modal-footer {
    border: none;
    padding: 0;
    border-radius: 0;
  }
  .modal-header {
    padding: 2px 16px;
    border-radius: 0;
    display: flex;
    align-items: center;
    background-color: $bmo-wealth-bmoblue;
    color: $bmo-wealth-white;
    height: 77px;
    img {
      height: 70px;
      @include mobile-and-tablet {
        height: 65px;
        width: 210px;
      }
    }
    button {
      @include mobile-and-tablet {
        font-size: $font-size-13px;
      }
      color: $bmo-wealth-white;
      float: right;
      font-size: $font-size-18px;
      font-weight: $font-weight-normal;
      padding: 0 2px;
      line-height: initial;
      background: none;
      border: none;
      &:hover {
        color: $bmo-wealth-black;
        cursor: pointer;
      }
    }
  }
  .modal-body {
    padding: 2px 16px;
    p {
      font-size: $font-size-15px;
      color: $bmo-wealth-charcoal;
      margin: -.15em 0;
      line-height: $bmo-wealth-modal-line-height;
      a {
        color: $bmo-wealth-bmoblue;
        font-size: $font-size-15px;
        font-weight: $font-weight-normal;
      }
      &>strong {
        font-size: $font-size-17px;
        display: inline;
      }
    }
  }
}
