.services-listing-wrapper {
    @include desktop-and-large-screen {
        @include screen-max-width-mixin;
    }
    .services-listing {
        text-align: center;
        margin: 0 auto;
        .services-listing-title {
            margin-bottom: 40px;
            h2 {
                margin: 0;
                font-size: $font-size-44px;
                line-height: $line-height-48px;
                @include small-mobile-and-mobile {
                    font-size: $font-size-36px;
                    line-height: $line-height-40px;
                }
            }
        }
        .services-listing-sub-title {
            margin: 0 auto 16px;
            width: 100%;
            @include tablet {
                max-width: 472px;
            }
            @include desktop-and-large-screen {
                max-width: 584px;
            }
            h3 {
                margin: 0;
                font-weight: $font-weight-bold;
                font-size: $font-size-24px;
                line-height: $line-height-32px;
            }
        }
        .services-listing-description {
            margin: 0 auto 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;
            width: 100%;
            @include tablet {
                max-width: 472px;
            }
            @include desktop-and-large-screen {
                max-width: 584px;
            }
        }
    }
    .service-cards-wrapper {
        justify-content: center;
        list-style: none;
        padding: 0 8px;
        @include tablet {
            padding: 0 105px;
        }
        @include desktop-and-large-screen {
            padding: 0 136px;
        }
        margin: 0 auto;
        .service-cards-layout {
            padding: 0 8px 16px;
            @include tablet {
                padding: 0 12px 16px
            }
            @include desktop-and-large-screen {
                padding: 0 16px 24px;
            }
            .service-cards {
                display: table;
                text-align: center;
                width: 100%;
                height: 72px;
                @include tablet {
                    height: 64px;
                }
                @include desktop-and-large-screen {
                    height: 64px;
                }
                @include hero-banner-shadow-mixin;
                background: $bmo-wealth-white;
                .service-name {
                    text-transform: uppercase;
                    display: table-cell;
                    vertical-align: middle;
                    font-size: $font-size-14px;
                    line-height: $line-height-18px;
                    letter-spacing: 0.25px;
                    padding: 0 14px;
                }
            }
        }
    }
    .services-listing-cta-button {
        margin-top: 24px;
        padding-bottom: 48px;
        font-size: $font-size-14px;
        line-height: $line-height-24px;
        letter-spacing: 0.25px;
        @include tablet {
            padding-bottom: 64px;
        }
        @include desktop-and-large-screen {
            margin-top: 16px;
            padding-bottom: 64px;
        }
    }
}
