/* Common Mixins. */


/************* Fonts mixin *************/

@mixin bmo-font($size, $weight: $font-weight-normal, $family: $font-family) {
    font-size: $size;
    font-weight: $weight;
    font-family: $family;
}

/************* Bold the Text *************/

@mixin bold-text {
    font-weight: $font-weight-bold;
    font-family: $font-family;
}

/************* Media Query mixin *************/

@mixin small-mobile {
    @media (max-width: $screen-xs-max) {
        @content;
    }
}

@mixin mobile {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin small-mobile-and-mobile {
    @media (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @content;
    }
}

@mixin mobile-and-tablet {
    @media (max-width: $screen-md-max) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        @content;
    }
}

@mixin large-screen {
    @media (min-width: $screen-xl-min) {
        @content;
    }
}

@mixin tablet-and-desktop {
    @media (min-width: $screen-md-min) {
        @content;
    }
}

@mixin desktop-and-large-screen {
    @media (min-width: $screen-lg-min) {
        @content;
    }
}

@mixin max-width-and-above {
    @media (min-width: $screen-max-width) {
        @content;
    }
}

@mixin max-width-and-below {
    @media (max-width: $screen-max-width) {
        @content;
    }
}

@mixin max-width-and-below-1263 {
    @media (max-width: $screen-max-width-1263) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: $screen-lg-min) and (max-width: $screen-tab-landscape-max) and (orientation: landscape) {
        @content;
    }
}

@mixin mobile-landscape {
    @media (min-width: $screen-xs-min) and (max-width: $screen-sm-max) and (orientation: landscape) {
        @content;
    }
}

@mixin ie-browser {
    @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin edge-browser {
    @supports (-ms-ime-align: auto) {
        @content;
    }
}

@mixin mozilla {
    @supports (-moz-appearance:none) {
        @content;
    }
}

@mixin ios-devices {
    @supports (-webkit-overflow-scrolling: touch) {
        @content;
    }
}

@mixin screen-max-width-mixin {
    max-width: $screen-max-width;
    margin: 0 auto;
    width: 100%;
    // Gutter spacing
    padding: 0 $mobile-gutter-space;
    @include tablet {
        // Gutter spacing
        padding: 0 $tablet-gutter-space;
    }
    @include desktop-and-large-screen {
      // Gutter spacing
      padding: 0 $desktop-gutter-space;
    }
}

@mixin hero-banner-shadow-mixin {
    border:solid 1px rgba(0, 25, 40,0.07);
    box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07);
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07);
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07);
    -o-box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-origin: border-box;
}

@mixin container-center-align {
    max-width: 1280px;
    margin: 0 auto;
}

@mixin social-share-state {
    .social-share {
        a {
            img {
                &:not(.active) {
                    display: block;
                }
                &.active {
                    display: none;
                }
            }
        }
        a:hover, a:focus {
            img {
                &:not(.active) {
                    display: none;
                }
                &.active {
                    display: block;
                }
            }
        }
    }
}

@mixin social-share {
    @include social-share-state;
    .social-share {
        font-size: $font-size-12px;
        color: $bmo-wealth-slate;
        margin: 30px 0;
        display: flex;
        @include tablet-and-desktop {
            justify-content: flex-end;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            line-height: 0;

            li {
                display: inline;

                a {
                    display: inline-block;
                    text-decoration: none;
                    margin: 0 0 0 13px;
                    img {
                        height: 36px;
                        width: 36px;
                        @include tablet-and-desktop {
                            height: 24px;
                            width: 24px;
                        }
                    }
                }
                a.social-twitter-share{
                    img{
                        height: 30px;
                        width: 30px;
                        margin-bottom: 3px;
                        @include tablet-and-desktop {
                            height: 20px;
                            width: 20px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }
        }

        @include tablet-and-desktop {
            margin-top: 44px;
            text-align: right;
        }

        @include desktop-and-large-screen {
            margin: 36px auto;
        }

        span {
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            padding: 10px 3px 0 0;

            @include tablet-and-desktop {
                padding: 4px 3px 0 0;
            }
        }
    }
}

@mixin social-share-insight {
    @include social-share-state;
    .social-share {
        font-size: $font-size-12px;
        color: $bmo-wealth-slate;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 16px auto;
        text-align: right;
        @include small-mobile-and-mobile {
            padding: 0 1px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            line-height: 0;

            li {
                display: inline;
                a {
                    text-decoration: none;
                    margin: 0 0 0 13px;
                    display: inline-block;

                    img {
                        height: 24px;
                        width: 24px;
                    }
                }
                a.social-twitter-share{
                    img{
                        height: 20px;
                        width: 20px;
                        margin-bottom: 2px;
                    }
                }
            }
        }
        .include-printer-icon {
            display: flex;
            span {
                margin: auto 0;
                &.print {
                    margin-right: 14px;
                    @include ie-browser {
                        margin-top: 4px;
                    }
                }
            }
        }
        @include tablet-and-desktop {
            margin: 24px auto;
        }
        span {
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            padding-right: 3px;
        }
    }
}

@mixin link-accessibility {
    &.focus,
    &.hover {
        text-decoration: underline;
    }

}

@mixin accessibility-outline {
    outline: 2px solid $bmo-wealth-cerulean !important;
    outline-offset: -2px;
    box-shadow: $bmo-wealth-box-shadow-outline;
}

/************* Insight Details Author Card Styling *************/

@mixin insight-banker {

    width: 100%;

    @include tablet {
        width: 100%;
    }

    @include desktop-and-large-screen {
        width: 100%;
    }

}

@mixin banker-card-default-show {

    padding-bottom: 0;

    @include tablet {
        display: inline-block;
        padding: 0;
    }

    @include desktop-and-large-screen {
        padding: 0;
    }

}

@mixin author-type-card {

    @include tablet {
        display: inline-block;
        padding: 0;
    }

    @include desktop-and-large-screen {
        padding: 0;
    }

    .our-team-card-wrapper {
        max-width: 343px;
        margin-bottom: 40px;

        @include desktop-and-large-screen {
            max-width: 379px;
        }

        .image-wrapper {
            margin-bottom: 0;
            .person-job-title,
            .company {
                @include desktop-and-large-screen {
                    display: inline-block;
                }
            }
        }

        .content {
            display: none;
        }
    }
}

@mixin search-result-card-shadow-mixin {
    border-radius: 4px;
    box-shadow: $bmo-wealth-box-shadow;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: $bmo-wealth-border-image-source;
    border-image-slice: 1;
    background-image: $bmo-wealth-background-image;
    background-origin: border-box;
    background-clip: content-box, border-box;
}

@mixin  insight-search-card-mixin {
    display: flex;
    width: 100%;
    @include search-result-card-shadow-mixin;
    margin-bottom: 16px;
    border-width: 0 0.5px 0.5px 0;
    @include small-mobile-and-mobile {
        flex-direction: column;
    }
    .insight-card-image {
        min-width: 379px;
        height: 272px;
        @include tablet {
            min-width: 224px;
            height: 236px;
        }
        @include small-mobile-and-mobile {
            min-width: 100%;
            height: 154px;
        }
        &.insight-card-desktop-view {
            display: none;
            @include tablet {
                display: none;
            }
            @include desktop-and-large-screen {
                display: block;
            }
        }
        &.insight-card-tablet-view {
            display: none;
            @include tablet {
                display: block;
            }
            @include desktop-and-large-screen {
                display: none;
            }
        }
        &.insight-card-mobile-view {
            display: block;
            @include tablet {
                display: none;
            }
            @include desktop-and-large-screen {
                display: none;
            }
        }
        img {
            @include small-mobile-and-mobile {
                width: 100%;
                height: 154px;
                border-top-right-radius: 4px;
                border-bottom-left-radius: 0;
            }
            @include tablet {
                max-width: 224px;
                height: 236px;
            }
            width: 379px;
            height: 272px;
            border-radius: 4px 0 0 4px;
        }
        &.right {
            @include desktop-and-large-screen {
                padding-left: 10px;
            }
            img {
                border-radius: 0 4px 4px 0;
            }
            img {
                float: right;
            }
        }
        &.left {
            @include desktop-and-large-screen {
                margin-right: 8px;
            }
        }
    }
    .card-content {
        text-align: left;
        padding: 24px;
        @include tablet {
            max-height: 236px;
            max-width: 410px;
        }
        @include small-mobile-and-mobile {
            min-height: 299px;
        }
        @include desktop-and-large-screen {
            max-height: 272px;
            max-width: 481px;
        }
        display: flex;
        flex-direction: column;
        .block-heading {
            display: flex;
            align-items: center;
            .type {
                margin-right: 16px;
                @include small-mobile-and-mobile {
                    display: none;
                }
            }
            .series {
                font-family: $font-family;
                font-size: $font-size-10px;;
                font-weight: $font-weight-bold;;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: 0.63px;
                background-color: $bmo-wealth-light-gray;
                color: $bmo-wealth-slate;
                margin-right: 16px;
                padding: 0 4px;
                border-radius: 2px;
                text-transform: uppercase;
            }
            .type {
                font-family: $font-family;
                text-transform: uppercase;
                font-size: $font-size-12px;
                font-weight: $font-weight-normal;
                font-stretch: normal;
                font-style: normal;
                color: $bmo-wealth-slate;
                line-height: 1.33;
                letter-spacing: 0.25px;
                text-transform: uppercase;
            }
        }
        .block-link {
            max-width: 300px;
            @include tablet {
                max-width: 410px;
            }
            @include desktop-and-large-screen {
                max-width: 481px;
            }
            font-size: $font-size-22px;
            display: block;
            font-stretch: normal;
            font-style: normal;
            margin-top: 24px;
            line-height: $line-height-28px;
            letter-spacing: 0.15px;
        }
        .block-description {
            max-width: 300px;
            @include tablet {
                max-width: 410px;
            }
            @include desktop-and-large-screen {
                max-width: 481px;
            }
            font-family: $font-family;
            font-size: $font-size-16px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            margin-bottom: 0;
            font-style: normal;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;
            margin-top: 16px ;
            color: $bmo-wealth-slate;
        }
        .publication-details {
            margin-top: auto;
            font-family: $font-family;
            font-size: $font-size-12px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-16px;
            letter-spacing: 0.25px;
            color: $bmo-wealth-slate;
            max-width: 300px;
            line-height: 1.5;
            @include tablet {
                max-width: 410px;
            }
            @include desktop-and-large-screen {
                max-width: 481px;
            }
            span {
                margin-right: 16px;
            }
            .publication-authors {
                margin-top: 8px;
            }
        }
        .card-footer-text {
            font-family: $font-family;
            font-size: $font-size-12px;
            font-weight: $font-weight-normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.25px;
            color: $bmo-wealth-slate;
            margin-top: 8px;
            .insight-closure {
                margin-right: 16px;
                text-transform: uppercase;
            }
        }
        &.right {
            @include tablet-and-desktop {
                max-width: 100%;
                width: calc(100% - 32px);
            }
        }
    }
}
