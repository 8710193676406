.insight-banner-carousel-container {
  height: 575px;
  position: relative;

  .banner-title {
    position: absolute;
    text-align: center;
    top: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 700;
    z-index: 999;
  }

  .banner-title.large {
    font-size: 36px;
  }

  .banner-title.small {
    font-size: 18px;
  }

  .slider-data {
    width: 77%;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 5%;
    left: 12%;
    z-index: 10;
    padding-top: 60px;
    padding-bottom: 5%;
    color: #fff;
    text-align: center;
  }

  .banner-content {
    padding-left: 5%;
    text-align: left;
  }

  .flex-container {
    display: inline-block;
    align-items: center;
  }

  .carousel-inner {
    width: 100vw !important;
  }

  .carousel-indicators li {
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #fff;
    background: none;
    margin-left: 5px;
    margin-right: 5px;
  }

  .carousel-indicators .active {
    background-color: #fff;
  }

  #carouselExampleIndicators {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .carousel-item {
    background-size: cover;
  }

  .carousel-control-next-icon {
    transform: rotate(180deg);
  }

  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1 !important;
    width: 10%;
    display: flex;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 60px !important;
    height: 80px !important;
    background-image: url("/static/assets/images/podcast_carousel/Right-Carousel.svg") !important;
  }

  .category-tag {
    background-color: #005587;
    color: #ffffff;
    border-radius: 4px;
    padding: 1.5px 6px 0px 6px;
    font-weight: 700;
    font-size: 14px;
    margin-right: 12px;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
  }

  .sub-category-tag {
    background: #d9dce1;
    color: #001928;
    border-radius: 4px;
    padding: 1.5px 6px 0px 6px;
    margin-right: 12px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
  }

  .insight-heading {
    margin-top: 15px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 48px;
    width: 80%;
    line-height: 53px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    color: white;
    text-align: left;
  }

  .insight-date {
    font-weight: 700;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }

  .play-btn {
    border-radius: 28px;
    border: 2px solid #fff;
    padding: 8px 32px 8px 32px;
    color: #fff;
    background: none;
    margin-right: 5px;
  }

  .banner-content-img {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 1050px) {
    .banner-content-img {
      width: 250px;
      height: 250px;
    }

    .slider-data {
      // top: 20%;
    }

    .insight-heading {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    height: 850px;

    .banner-title {
      top: 60px;
    }

    .banner-content {
      padding-left: 0%;
    }

    .carousel-control-next,
    .carousel-control-prev {
      display: none !important;
    }

    .banner-content-img {
      width: 280px;
      height: 280px;
    }

    .transparent-img {
      height: 850px;
    }

    .slider-data {
      padding-top: 30px;
      // top: 15%;
      bottom: 10%;
      flex-direction: column;
      justify-content: center;
    }

    .flex-container {
      display: inline-block;
    }
  }

  @media (max-width: 600px) {
    .banner-title.small {
      font-size: 14px;
    }

    height: 775px;

    .insight-heading {
      -webkit-line-clamp: 3;
    }

    .sub-category-tag {
      margin-left: 2px;
    }

    .slider-data {
      width: 80%;
      left: 10%;
    }

    .insight-heading {
      font-size: 36px;
    }

    .banner-title {
      top: 60px;
    }

    .slider-data {
      // top: 10%;
    }

    .transparent-img {
      height: 775px;
    }
  }

  @media (max-width: 340px) {
    .banner-content-img {
      width: 230px;
      height: 230px;
    }

    .insight-des {
      -webkit-line-clamp: 3;
    }
  }
}
