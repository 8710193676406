.podcast-banner-carousel-container {
  background-image: url("/static/assets/images/podcast_carousel/Hero_BG_Desk.png");
  height: 600px;
  background-size: 100% 100%;
  position: relative;
  .banner-title {
    position: absolute;
    text-align: center;
    top: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 700;
  }
  .banner-title.large {
    font-size: 36px;
  }
  .banner-title.small {
    font-size: 18px;
  }
  .slider-data {
    width: 77%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 15%;
    left: 12%;
    z-index: 10;
    padding-top: 60px;
    padding-bottom: 5%;
    color: #fff;
    text-align: center;
  }
  .banner-content {
    padding-left: 5%;
    text-align: left;
  }
  .flex-container {
    display: flex;
    align-items: center;
  }
  .carousel-inner {
    width: 100vw !important;
  }
  .carousel-indicators li {
    width: 16px !important;
    height: 16px !important;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #fff;
    background: none;
    margin-left: 5px;
    margin-right: 5px;
  }
  .carousel-indicators .active {
    background-color: #fff;
  }
  .carousel-indicators{
    bottom: 20px;
  }
  #carouselExampleIndicators {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .carousel-control-next-icon {
    transform: rotate(180deg);
  }
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1 !important;
    width: 10%;
    display: flex;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 60px !important;
    height: 80px !important;
    background-image: url("/static/assets/images/podcast_carousel/Right-Carousel.svg") !important;
  }
  .category-tag {
    background-color: #005587;
    border-radius: 4px;
    padding: 1.5px 6px 0px 6px;
    font-weight: 700;
    font-size: 12px;
    margin-right: 12px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .sub-category-tag {
    background: #d9dce1;
    color: #001928;
    border-radius: 4px;
    padding: 1.5px 6px 0px 6px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .podcast-head {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .podcast-des {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .play-btn {
    border-radius: 28px;
    border: 2px solid #fff;
    padding: 8px 32px 8px 32px;
    color: #fff;
    background: none;
    margin-right: 10px;
  }
  .banner-content-img {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 1050px) {
    .banner-content-img {
      width: 250px;
      height: 250px;
    }
    .slider-data {
      top: 20%;
    }
  }
  @media (max-width: 900px) {
    height: 850px;

    .banner-title {
      top: 60px;
    }
    .banner-content {
      padding-left: 0%;
    }
    .banner-content-img {
      width: 280px;
      height: 280px;
    }
    .transparent-img {
      height: 850px;
    }
    .slider-data {
      padding-top: 30px;
      top: 15%;
      flex-direction: column;
      justify-content: center;
    }
    .banner-content {
      text-align: center;
    }
    .flex-container {
      padding-top: 25px;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 600px) {
    .banner-title.small {
      font-size: 14px;
    }
    height: 775px;
    .podcast-head {
      -webkit-line-clamp: 3;
    }
    background-image: url("/static/assets/images/podcast_carousel/Hero_BG_Mob.png");
    .slider-data {
      width: 80%;
      left: 10%;
    }
    .podcast-head {
      font-size: 24px;
    }
    .banner-title {
      top: 60px;
    }
    .slider-data {
      top: 10%;
    }
    .transparent-img {
      height: 775px;
    }
  }
  @media (max-width: 340px) {
    .banner-content-img {
      width: 230px;
      height: 230px;
    }
    .podcast-des {
      -webkit-line-clamp: 3;
    }
  }
}
