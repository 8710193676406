.podcast-contact-section {
  background-image: url('/static/assets/images/contact_us_background/contact_desktop_bg.png');
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 10% 60px 10%;

  .contact-head {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
  }

  .contact-content {
    width: 50%;
    text-align: center;
  }

  .contact-btn {
    color: #0075be;
    margin-top: 30px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    width: 60%;
    white-space: nowrap;
  }

  .contact-btn,
  .insights-btn-mobile {
    .white-bg-blue-text {
      background-color: #FFFFFF;
    }

    .blue-bg-white-text {
      margin: auto !important;
    }
  }

  @media (max-width: 650px) {
    background-image: url('/static/assets/images/contact_us_background/contact_mobile_bg.png');
    padding: 100px 25px 60px 25px;

    .contact-head {
      font-size: 20px;
    }
  }
}
