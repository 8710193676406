section.video-banner-wrapper {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
  @include small-mobile-and-mobile {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  @include tablet { 
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .video-banner-header {
    font-family: $font-family;
    font-size: $font-size-44px;
    font-weight: $font-weight-light;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.5px;
    text-align: center;
    color: $bmo-wealth-slate;
    margin-bottom: 40px;
    @include small-mobile-and-mobile {
      font-size: $font-size-36px;
      line-height: 1.11;
      letter-spacing: normal;
    }
  }
  .thumbnail_container {
    position: relative;
    .start-video {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -24px;
      margin-left: -24px;
    }
    .thumbnail {
      object-fit: cover;
      filter: brightness(50%);
      -webkit-filter: brightness(50%);
      -moz-filter: brightness(50%);
      -ms-filter: brightness(50%);
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include small-mobile-and-mobile { 
        background-size: 130%
      }
    }
  }
  .thumbnail,
  iframe {
    width: 789px;
    height: 328px;
    border-radius: 4px;
    @include small-mobile-and-mobile {
      width: 100%;
      height: 260px;
    }
    @include tablet {
      width: 596px;
      height: 230px;
    }
  }
}
