section.heading-container {
  h1 {
    padding: 24px 16px 64px;
    @include tablet {
      padding-top: 96px;
      padding-bottom: 40px;
    }
    @include desktop-and-large-screen {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  &.copy-text {
    h1 {
      padding-bottom: 40px;
    }
  }

  h2, h3 {
    padding: 48px 16px 40px;
    @include tablet {
      padding-top: 64px;
    }
  }

 //specific page plugin class css. can be used to override for heading plugin
  &.business-owner-strategies-bottom-heading {
    margin-top: -40px;
    @include small-mobile-and-mobile {
      margin-top: -20px;
    }
  }
  &.transitioning-your-business {
    margin-top: -85px;
    @include small-mobile-and-mobile {
      margin-top: -30px;
    }
  }
  &.business-owner-strategies-related-insights {
    margin: -75px 0 -55px 0;
  }

  @include desktop-and-large-screen {
    &.main-brand {
      h2, h3 {
        padding-top: 80px;
      }
    }
    &.sub-brand {
      h2, h3 {
        padding-top: 36px;
      }
    }
  }

  h1, h2, h3 {
    margin-bottom: 0;
    font-size: $font-size-36px;
    font-weight: $font-weight-light;
    font-stretch: normal;
    font-style: normal;
    line-height: $line-height-40px;
    letter-spacing: normal;
    color: $bmo-wealth-slate;
    margin: 0 auto;
    @include tablet {
      font-size: $font-size-44px;
      line-height: $line-height-48px;
      letter-spacing: $letter-spacing-44px;
      max-width: 596px;
    }
    @include desktop-and-large-screen {
      font-size: $font-size-44px;
      line-height: $line-height-48px;
      letter-spacing: $letter-spacing-44px;
      max-width: 789px;
    }
  }
  p {
    margin: 24px 16px 8px;
    font-size: $font-size-16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: $line-height-24px;
    letter-spacing: 0.15px;
    color: $bmo-wealth-slate;
    @include tablet {
      margin: 24px auto 8px;
      max-width: 472px;
    }
    @include desktop-and-large-screen {
      margin: 24px auto 0;
      max-width: 584px;
    }
    &:nth-of-type(1) {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 48px;
      @include tablet {
        margin-bottom: 64px;
      }
      @include desktop-and-large-screen {
        margin-bottom: 80px;
      }
    }
  }
  
  ul {
    @include tablet {
      max-width: 472px;
      margin: 0 auto;
    }

    @include desktop-and-large-screen {
      margin: 0 auto;
      max-width: 584px;
    }
  }
}


section.overlapping-image-banner-wrapper + section.container-background-primary-gray,
section.overlapping-image-banner-wrapper + section.container-background-sub-brand-gray {
  section.heading-container {
    h2 {
      padding: 0 0 45px;
      @include small-mobile-and-mobile {
        padding: 8px 0 45px;
      }
      @include tablet {
        padding: 24px 0 45px;
      }
    }
  }
}

section.container-background-primary-white {
  section.heading-container + div.row {
    .column:nth-child(-n+4) {
      .image-with-text-wrapper {
        .image-icon-wrapper {
          padding-top: 5px;
        }
      }
    }
  }
}

section.heading-container + div.row {
  &.screen-max-width {
    @include tablet {
      padding: 0 12px;
    }
    @include desktop-and-large-screen {
      padding: 0 24px;
    }
  }
  .column:not(:last-child) {
    .image-with-text-wrapper {
      .image-top.icon {
        @include small-mobile-and-mobile {
          margin-bottom: 45px;
        }
      }
    }
  }
  .column {
    padding: 0 16px;
    @include tablet {
      padding: 0 12px;
    }
    .image-with-text-wrapper {
      .image-top.icon {
        margin-top: 0;
        .image-icon-wrapper {
          padding-top: 0;
        }
      }
    }
    @include small-mobile-and-mobile {
      padding: 0;
    }
  }
}

section.heading-container + section.image-with-text-wrapper.content-top {
  margin-top: 0px;
}

section.heading-container.sub-brand + div.row section.image-with-text-wrapper .image-top.icon {
  margin-bottom: 48px;
  @include tablet {
    margin-bottom: 64px;
  }
  @include desktop-and-large-screen {
    margin-bottom: 80px;
  }
}


section.heading-container.spacing p {
  max-width: 800px;
}