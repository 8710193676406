.insights-listing-plugin-wrapper {
    padding-top: 48px;
    padding-bottom: 48px;

    @include tablet {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    @include desktop-and-large-screen {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .insights-listing-heading {
        margin: 0 auto 24px;
        font-weight: $font-weight-light;
        font-size: $font-size-36px;
        line-height: $line-height-40px;
        letter-spacing: normal;
        color: $bmo-wealth-slate;
        width: 100%;
        text-align: center;

        @include tablet {
            max-width: 596px;
        }
        @include tablet-and-desktop {
            font-size: $font-size-44px;
            line-height: $line-height-48px;
            letter-spacing: -0.5px;
        }
        @include desktop-and-large-screen {
            max-width: 789px;
        }
    }
    .insights-listing-sub-title {
        text-align: center;
        margin-bottom: 24px;
        span {
            width: 100%;
            @include tablet {
                max-width: 596px;
            }
            @include desktop-and-large-screen {
                max-width: 584px;
            }
            font-size: $font-size-24px;
            font-weight: $font-weight-bold;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-32px;
            letter-spacing: normal;
        }
    }

    .insights-series-listing {
        padding: 0 9px;
        text-align: center;
        margin: 0 auto 32px;
        width: 100%;

        @include tablet {
            max-width: 768px;
        }

        @include desktop-and-large-screen {
            max-width: 789px;
        }

        .series-browse {
            display: inline-block;
            font-size: $font-size-14px;
            font-weight: $font-weight-medium;
            color: $bmo-wealth-slate;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.25px;
            line-height: $line-height-24px;
            margin-right: 16px;
            margin-bottom: 8px;
        }

        .series-names-wrapper {
            display: inline-block;
            margin-bottom: 8px;

            .series-name-link {
                font-size: $font-size-14px;
                margin-right: 16px;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.25px;
                line-height: $line-height-24px;
            }
        }
    }

    .insights-listing-plugin {
        padding: 0;
        justify-content: center;
        @include tablet {
            max-width: 768px;
            margin: 0 auto;
            padding: 0 12px;
        }
        @include desktop-and-large-screen {
            padding: 0 24px;
        }

        .insight-listing-column-wrapper {
            padding: 0 16px;
            @include tablet {
                padding: 0 12px;
            }
            &.filter-series {
                display: none;
            }
            &:first-child {
                .insight-card-wrapper {
                    @include small-mobile-and-mobile {
                        margin-top: 0;
                    }
                }
            }
            &:nth-of-type(-n+2) {
                .insight-card-wrapper {
                    @include tablet {
                        margin-top: 0;
                    }
                }
            }
            &:nth-of-type(-n+3) {
                .insight-card-wrapper {
                    @include desktop-and-large-screen {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .insights-listing-bottom {
        text-align: center;

        #show-more-insights,
        #show-less-insights {
            margin-top: 24px;
            display: none;

            .show-more-listing-insights,
            .show-less-listing-insights {
                font-size: $font-size-14px;
                letter-spacing: 0.15px;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-20px;
            }

            .caret-down,
            .caret-up {
                width: 12px;
                height: 17px;
            }
            .caret-up {
                transform: rotate(180deg);
            }
            @include tablet-and-desktop {
                margin-top: 40px;
            }
        }
        .cta-button-secondary {
            margin-top: 40px;
        }
    }
}

// .our-team-section-primary + .cta-button-secondary,
// .our-team-section-secondary + .cta-button-secondary,
// .insights-listing-plugin-wrapper + .cta-button-secondary {
//     padding-bottom: 48px;
//     @include tablet {
//         padding-bottom: 64px;
//     }
//     @include desktop-and-large-screen {
//         padding-bottom: 80px;
//     }
// }
