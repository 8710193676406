.insight-details-wrapper {
  .insight-details-section {
    &.screen-max-width {
      padding: 0 !important;
    }
    .insight-details-row-wrapper.row {
      @include mobile-and-tablet {
        margin: 0;
      }
    }

    .insight-detail-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 270px;
      @include tablet {
        height: 300px;
      }
      @include desktop-and-large-screen {
        height: 370px;
      }
    }

    .breadcrumb-share-section {
      @include social-share-insight;
      .row {
        @include mobile-and-tablet {
          margin: 0;
        }
      }

      section.breadcrumbs-wrapper {
        @include small-mobile-and-mobile {
            padding-left: 1px;
        }
      }
      .social-share-section {
        @include small-mobile-and-mobile {
          position: relative;
        }

        @include tablet-and-desktop {
          display: flex;
          @include tablet {
            padding: 0;
          }
        }

        .include-printer-icon {
          @include small-mobile-and-mobile {
            position: absolute;
            top: 55px;
            right: 16px;
          }
        }

        .social-share {
          &:not(.print) {
            @include tablet-and-desktop {
              position: absolute;
              right: 125px;
              @include tablet {
                padding: 0;
                right: 132px;
              }
            }
          }
        }
      }
    }

    .insight-details-row-wrapper {
      .insight-details-column-wrapper-big {
        @include mobile-and-tablet {
          padding: 0;
        }
        @include desktop-and-large-screen {
          padding-right: 0;
        }
        .insight-content {
          margin-top: 54px;
          padding: 0 $mobile-gutter-space;
          @include tablet {
            padding-left: 86px;
            padding-right: 148px;
            margin-top: $tablet-gutter-space;
          }
          @include desktop-and-large-screen {
            padding-left: 141px;
            margin-top: 40px;
          }
          a {
            &.insight-tag {
              margin-right: 16px;
              font-size: $font-size-14px;
            }
          }

          .tagging-details-section {
            div {
              display: inline-block;
              margin-bottom: 8px;
            }
          }

          .insight-publication-date {
            margin-top: 8px;
            margin-bottom: 40px;
            height: 16px;
            font-size: $font-size-12px;
            color: $bmo-wealth-slate;
          }

          .insight-content-body {
            h1 {
              font-size: $font-size-36px;
              font-weight: $font-weight-light;
              font-stretch: normal;
              font-style: normal;
              line-height: $line-height-40px;
              letter-spacing: normal;
              margin-bottom: 24px;
              color: $bmo-wealth-slate;

              @include tablet-and-desktop {
                font-size: $font-size-44px;
                letter-spacing: $letter-spacing-36px;
                line-height: $line-height-48px;
              }
            }
            img {
              width: 100%;
            }
          }

          .insight-topics {
            display: flex;
            margin-top: 40px;
            @include desktop-and-large-screen {
              margin-top: 48px;
            }
            span {
              height: 24px;
              font-size: $font-size-14px;
              font-weight: $font-weight-medium;
              color: $bmo-wealth-slate;
              margin-right: 8px;
              @include desktop-and-large-screen {
                margin-right: 16px;
              }
            }
            div {
              display: inline-block;
              margin-bottom: 8px;
            }
          }
        }
      }

      .insight-details-column-wrapper-small {
        @include desktop-and-large-screen {
          padding: 0;
        }
        @include small-mobile-and-mobile {
          padding: 0 16px;
        }

        .insight-additional-details {
          margin: 0 auto;
          @include mobile-and-tablet {
            padding-top: 64px;
          }
          max-width: 375px;
          @include tablet {
            padding: 0 9px;
            margin-top: 64px;
            max-width: 768px;
            display: block;
            column-count: 2;
            column-gap: 24px;
          }
          @include desktop-and-large-screen {
            margin-right: 40px;
            max-width: 379px;
          }
        }
      }
    }
  }
  .insight-subscribe-modal-container {
    section.subscribe-modal-plugin {
      margin-top: 156px;
      @include tablet {
        margin-top: 188px;
      }
      @include desktop-and-large-screen {
        margin-top: 260px;
      }
    }
  }
  .insight-listing-related-services-container {
    margin-bottom: 48px;
    @include tablet {
      margin-bottom: 64px;
    }
    @include desktop-and-large-screen {
      margin-bottom: 80px;
    }
    &.screen-max-width {
      padding: 0;
    }
    .insights-listing-plugin-wrapper {
      padding-bottom: 40px;
    }
  }

  @import './insight_guest_author.scss';
  @import './insight_authors.scss';
  @import './insight_series.scss';
  @import './insight_document.scss';
  @import './insight_related_services.scss';
}

