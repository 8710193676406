.pagination {
  display: flex;
  padding: 0;
  list-style: none;
  border-radius: .25rem;
  border-radius: 0;
  align-items: center;
  justify-content: center;
  #previous-page a {
    background-image: url('/static/assets/svgs/accordion-svg/chevron-left-blue.svg');
    width: 27px;
    height: 23px;
    background-repeat: no-repeat;
    margin-left: 0;
  }
  #next-page a {
    width: 27px;
    height: 23px;
    background-repeat: no-repeat;
    background-image: url('/static/assets/svgs/accordion-svg/chevron-right-blue.svg');
  }
  li#next-page {
    margin-left: 11px;
  }
  li#previous-page {
    margin-right: 6px;
  }
  li.page-item {
    height: 44px;
    display: flex;
    align-items: center;
    a.page-link {
      font-family: $font-family;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      color: $bmo-wealth-accessible-blue;
      line-height: 1.27;
      letter-spacing: 0.15px;
      border-radius: 0;
      border: none;
      padding: 8px 14px;
      &:hover {
        background: none;
        text-decoration: underline;
        color: $bmo-wealth-ultramarine;
      }
      &.ellipsis {
        text-decoration: solid;
        &:focus {
          text-decoration: solid;
        }
      }
      &:focus {
        outline: -webkit-focus-ring-color auto 1px;
        text-decoration: underline;
        color: $bmo-wealth-ultramarine;
      }
    }
    &.active a.page-link {
      background: none;
      border-radius: 4px;
      padding: 8px 14px;
      margin: 0 4px;;
      color: $bmo-wealth-accessible-blue;
      border: solid 2px $bmo-wealth-accessible-blue;
    }
  }
}
