/************* Colors *************/

$bmo-wealth-white: #FFFFFF;
$bmo-wealth-black: #000000;
$bmo-wealth-accessible-blue: #0075be;
$bmo-wealth-light-gray: #d9dce1;
$bmo-wealth-slate: #001928;
$bmo-wealth-slate-border: rgba(0, 25, 40, 0.1);
$bmo-wealth-cerulean: #73c3eb;
$bmo-wealth-gray: #929ba9;
$bmo-wealth-bg-gray: #d8d8d9;
$bmo-wealth-container-bg-gray: #f5f6f7;
$bmo-wealth-ultramarine: #005587;
$bmo-wealth-Granite: #646c76;
$bmo-wealth-bmoblue: #0079c1;
$bmo-wealth-azure: #bedceb;
$bmo-wealth-negative: #c81414;
$bmo-wealth-bmored: #ed1c24;
$bmo-wealth-albicant: #f5f6f7;
$bmo-wealth-positive: #0b8224;
$bmo-wealth-lilac: #6a88c3;
$bmo-wealth-skyblue: #009ec9;
$bmo-wealth-poolside: #2cbfc1;
$bmo-wealth-orchid: #2cbfc1;
$bmo-wealth-warning: #ffc827;
$bmo-wealth-olive: #c3c070;
$bmo-wealth-retrospect: #00e7b4;
$bmo-wealth-sunflower: #ffc700;
$bmo-wealth-light-gray-border: rgba(0, 0, 0, 0.5);
$bmo-wealth-alert-bg: #3c3c3c;
$bmo-wealth-alert-warning-background: #FFFAEB;
$bmo-wealth-alert-warning-border: #D07704;
$bmo-wealth-alert-success-button-background: #010101;
$bmo-wealth-alert-success-bg: rgba(11, 130, 36, 0.1);
$bmo-wealth-alert-info-bg: rgba(0, 121, 193, 0.1);
$bmo-wealth-alert-danger-bg: rgba(237, 28, 36, 0.1);


/************* Font Family *************/

$font-family: 'Heebo', sans-serif;

/************* Font Sizes *************/

$font-size-10px: 0.625rem;
$font-size-11px: 0.6875rem;
$font-size-12px: 0.75rem;
$font-size-13px: 0.8125rem;
$font-size-14px: 0.875rem;
$font-size-15px: 0.9375rem;
$font-size-16px: 1rem;
$font-size-17px: 1.0625rem;
$font-size-18px: 1.125rem;
$font-size-20px: 1.25rem;
$font-size-22px: 1.375rem;
$font-size-23px: 1.4375rem;
$font-size-24px: 1.5rem;
$font-size-28px: 1.75rem;
$font-size-36px: 2.25rem;
$font-size-44px: 2.75rem;
$font-size-100px: 6.25rem;
$font-size-200px: 12.5rem;

/************* Font Weight *************/

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

/************* Letter Spacing *************/

$letter-spacing-10px: -0.3px;
$letter-spacing-12px: -0.2px;
$letter-spacing-14px: -0.2px;
$letter-spacing-16px: -0.2px;
$letter-spacing-18px: -0.2px;
$letter-spacing-22px: -0.3px;
$letter-spacing-28px: -0.2px;
$letter-spacing-36px: -0.5px;
$letter-spacing-40px: -0.5px;
$letter-spacing-44px: -0.5px;


/************* Line Height *************/

$line-height-14px: 0.875rem;
$line-height-16px: 1rem;
$line-height-18px: 1.125rem;
$line-height-20px: 1.25rem;
$line-height-22px: 1.375rem;
$line-height-24px: 1.5rem;
$line-height-28px: 1.75rem;
$line-height-30px: 1.875rem;
$line-height-32px: 2rem;
$line-height-40px: 2.5rem;
$line-height-48px: 3rem;

/************* Breakpoint Values *************/

/* Extra Large Screen */
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

/* Large Screen */
$screen-lg: 992px;
$screen-lg-min: $screen-lg;
$screen-lg-max: ($screen-xl - 0.1);

/* Tablet screen */
$screen-md: 768px;
$screen-md-min: $screen-md;
$screen-md-max: ($screen-lg - 0.1);

/* Mobile screen */
$screen-sm: 576px;
$screen-sm-min: $screen-sm;
$screen-sm-max: ($screen-md - 0.1);

/* Extra small mobile screen */
$screen-xs: 320px;
$screen-xs-min: $screen-xs;
$screen-xs-max: ($screen-sm - 0.1);

/* Other */
$screen-max-width: 1280px;
$screen-tab-landscape-max: 1024px;
$screen-mac-smaller-resolution: 1280px;
$screen-max-width-1263: 1263px;

/************* Gutter space *************/

$desktop-gutter-space: 40px;
$tablet-gutter-space: 24px;
$mobile-gutter-space: 16px;

// footer modal
$bmo-wealth-black-shade: rgba(0,0,0,.4);
$bmo-wealth-white-shade : #fefefe;
$bmo-wealth-grey-shade : #888;
$bmo-wealth-charcoal: #414141;

$bmo-wealth-modal-line-height: 1.3em;

// box-shadow
$bmo-wealth-box-shadow: 0 2px 2px 0 rgba(0, 25, 40, 0.07), 0 0 2px 0 rgba(0, 25, 40, 0.12);
$bmo-wealth-border-image-source: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
$bmo-wealth-background-image: linear-gradient(to bottom, $bmo-wealth-white, $bmo-wealth-white), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.02) 95%, rgba(0, 0, 0, 0.04));
$bmo-wealth-alert-close-button-border: 0.0625rem solid #cacaca;
$bmo-wealth-box-shadow-outline: 0 0 4px 3px rgba(115, 195, 235, 0.35);
