.related-services-container {
    margin: 48px auto 0;
    @include tablet {
        max-width: 472px;
    }
    @include desktop-and-large-screen {
        max-width: 790px;
    }
    .related-services-tag {
        font-size: $font-size-24px;
        font-weight: $font-weight-bold;
        text-align: center;
        color: $bmo-wealth-slate;
        padding-bottom: 24px;
    }

    .related-services-cards {
        justify-content: center;
        @include small-mobile-and-mobile {
            margin: 0;
        }
        .content {
            .service-link {
                @include link-accessibility;
                font-size: $font-size-18px;
                display: inline-block;
            }
            .tab-panel-service-links {
                display: block;
            }
            .description {
                max-width: 343px;
                margin: 0 auto;
                padding-top: 16px;
                padding-bottom: 40px;
                @include tablet {
                    max-width: 224px;
                }
                @include desktop-and-large-screen {
                     max-width: 379px;
                }
            }
        }
        .service-content-wrapper {
            &:last-child {
                .content {
                    .description {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}

.insights-listing-plugin-wrapper + .cta-button-secondary {
    padding-bottom: 0;
}
