.how-can-we-help {
    display: flex;
    flex-direction: column;
    color: #001928;
    .title {
        font-size: 2.75rem;
        line-height: 3rem;
        margin: 0 auto 24px;
        text-align: center;
        width: 100%;
        padding-bottom: 40px;
        font-weight: 300;
    }
    &.growing-your-business-icons-with-text {
        margin-bottom: 50px;
    }
    .image-with-content {
        padding: 0% 20% 0% 20%;
        display: flex;
        justify-content: center;
        padding-bottom: 60px;
        .image {
            width: 112px;
            height: 84px;
            padding-right: 4%;
        }
        .sub-title {
            color: #0075be;
            padding-bottom: 15px;
            font-weight: 700;
            font-size: 1.375rem;
        }
        .description {
            font-size: 1rem;
            font-weight: 400;
            line-height: 24px;
        }
        @include tablet {
            padding: 0% 10% 0% 10%;
            padding-bottom: 60px;
        }
    }
    @include small-mobile-and-mobile {
        .title {
            font-size: 2.25rem;
            padding-bottom: 20px;
        }
        .image-with-content {
            padding: 0% 5% 0% 5%;
            padding-bottom: 60px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .image {
                width: 90px;
                height: 70px;
                padding-right: 0%;
                padding-bottom: 20px;
            }
            .sub-title {
                font-size: 1.25rem;
                text-align: center;
            }
            .description {
                font-size: 0.95rem;
                text-align: center;
            }
        }
    }
    @include small-mobile {
        .title {
            font-size: 2rem;
            padding-bottom: 20px;
        }
        .image-with-content {
            padding: 0% 2% 0% 2%;
            padding-bottom: 60px;
            .sub-title {
                font-size: 1.1rem;
            }
            .description {
                font-size: 0.95rem;
            }
        }
    }
    .button {
        width: 152px;
        height: 56px;
        padding: 16px 32px 16px 32px;
        border-radius: 32px;
        background-color: #0075BE;
        border: none;
        color: white;
        margin: 0 auto;
    }
    button:hover {
        cursor: pointer;
    }
    @include small-mobile-and-mobile {
        padding-top: 30px;
        // padding-bottom: 30px;
        .sub-title {
            font-size: 1.25rem;
            display: inherit;
        }
    }
}
