&.horizontal-tabs {
    .needs-services {
        padding: 0;
        justify-content: center;
    }
    .need-filter{
        text-align: center;
        margin: 24px 0 48px;
        @include small-mobile-and-mobile {
            margin: 36px 0 48px;
        }
        font-weight: $font-weight-bold;
        font-size: $font-size-14px;
        a {
            text-decoration: none;
        }
        .filter {
            color: $bmo-wealth-accessible-blue;
            margin-left: 16px;
            padding: 5px 16px;
            border-radius: 4px;
            border: solid 2px #646c76;
            margin-bottom: 16px;

            &.active {
                color: $bmo-wealth-white;
                background-color: $bmo-wealth-accessible-blue;
                border-color: $bmo-wealth-accessible-blue;
            }
        }

        .filter:nth-child(2) {
            @include tablet {
                margin-left: 7px;
            }
        }

        .filter-inner-wrapper {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            label {
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-24px;
                letter-spacing: 0.25px;
                @include tablet {
                    display: block;
                    width: 100%;
                    margin-bottom: 16px;
                }
                @include desktop-and-large-screen {
                    padding-top: 6px;
                }
            }
            @include small-mobile-and-mobile {
                display: none;
            }
        }

        .mobile-dropdown-wrapper {
            display: none;
            @include small-mobile-and-mobile {
                display: block;
            }
            select.dropdown-filter {
                appearance: none;
                width: 100%;
                height: 30px;
                color: $bmo-wealth-accessible-blue;
                font-size: $font-size-18px;
                overflow: hidden;
                background-color: $bmo-wealth-white;
                background-image: url('/static/assets/svgs/tab-panel-svg/chevron-down-blue.svg');
                background-position: center right;
                background-repeat: no-repeat;
                background-size: 17px 23px;
                border: 0;
                border-bottom: 2px solid $bmo-wealth-light-gray;
                border-radius: 0;
                margin: 0;
                padding: 0;
            }

            label.mobile-dropdown-label {
                float: left;
                color: $bmo-wealth-Granite;
                font-weight: $font-weight-normal;
                font-size: $font-size-14px;
                line-height: $line-height-16px;
                letter-spacing: 0.25px;
                margin-top: 8px;
            }
        }
    }
    .tab-pane {
        display: none;
        &.active{
            display: block;
        }
    }
}
