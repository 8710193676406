div.cta-button-secondary {
  &.LEFT,
  &.Left,
  &.left {
    display: flex;
    justify-content: flex-start;
  }
  &.RIGHT,
  &.Right,
  &.right {
    display: flex;
    justify-content: flex-end;
  }
  &.CENTER,
  &.Center,
  &.center {
    display: flex;
    justify-content: center;
  }

  //specific class css for particular page plugins. Have used this class for a particular style match in few pages.
  &.business-owner-strategies {
    margin-top: -30px;
  }
  &.expertise-cta-button {
    margin-bottom: 50px;
    margin-top: -120px;
    @include desktop-and-large-screen {
      margin-top: -120px;
    }
  }
  &.growing-your-business {
    margin-top: -75px;
    margin-bottom: -10px;
    @include small-mobile-and-mobile {
      margin-top: -50px;
    }
  }
}

.infographic a.cta-link.white-bg-blue-text {
  border: none;
  padding: 10px;
}
