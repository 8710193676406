section.our-team-section-primary {

    padding: 0 0 48px;
    @include tablet {
        padding: 0 0 64px;
    }
    @include desktop-and-large-screen {
        padding: 0 0 80px;
    }

    h2.title{
        position: relative;
        text-align: center;
        line-height: $line-height-40px;

        @include screen-max-width-mixin;

        margin: 0 auto 40px;

        padding-top: 48px;
        @include tablet {
            padding-top: 64px;
        }
        @include desktop-and-large-screen {
            padding-top: 80px;
        }

        @include tablet-and-desktop {
            font-size: $font-size-44px;
            line-height: $line-height-48px;
            letter-spacing: -0.5px;
        }
    }

    div.sub-title {
        text-align: center;
        margin-bottom: 24px;
        span {
            width: 100%;
            @include tablet {
                max-width: 596px;
            }
            @include desktop-and-large-screen {
                max-width: 584px;
            }
            font-size: $font-size-24px;
            font-weight: $font-weight-bold;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-32px;
            letter-spacing: normal;
        }
    }

    .our-team-wrapper-primary {
        justify-content: center;
        &.row {
            margin: 0;
            @include tablet-and-desktop{
                margin: 0 auto;
            }
            @include desktop-and-large-screen {
                padding: 0 8px;
            }
        }


        @include tablet {
            padding: 0 12px;
        }
        @include desktop-and-large-screen {
            max-width: 838px;
        }

        .col {
            display: none;
            padding: 0 15.5px;
            @include tablet {
                padding: 0 12px;
            }
        }

        .our-team-card-wrapper {
            width: 100%;
            max-height: 350px;
            padding: 24px 24px 40px;
            color: $bmo-wealth-slate;
            background-color: $bmo-wealth-white;
            margin: 0 auto;
            margin-bottom: 16px;

            @include tablet-and-desktop {
                margin-bottom: 24px;
            }
            @include tablet {
                max-height: 368px;
            }
            @include desktop-and-large-screen {
                max-height: 308px;
                margin: 0 auto 24px;
                padding-bottom: 48px;
            }
            &.with-biography {
                @include tablet {
                    height: 368px;
                }
                @include desktop-and-large-screen {
                    height: 308px;
                }
            }
            @include hero-banner-shadow-mixin;

            .image-wrapper {
                img {
                    height: 126px;
                    max-width: 126px;
                    object-fit: contain;
                    border-radius: 4px;
                    @include mobile-and-tablet {
                        margin-bottom: 24px;
                    }
                    @include tablet {
                        height: 120px;
                        max-width: 120px;
                    }
                    @include desktop-and-large-screen {
                        height: 140px;
                        max-width: 140px;
                    }
                }

                .person-name {
                    font-size: $font-size-18px;
                    line-height: $line-height-24px;
                    display: block;
                    margin-bottom: 8px;
                    word-wrap: break-word;
                }

                .person-job-title,
                .company {
                    font-size: $font-size-14px;
                    line-height: $line-height-20px;
                }
                .company {
                    @include desktop-and-large-screen {
                        margin-bottom: 16px;
                    }
                }

                .about {
                    @include desktop-and-large-screen {
                        padding-left: 16px;
                    }
                }

                @include desktop-and-large-screen {
                    display: flex;
                }
            }

            .content {
                font-size: $font-size-16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-24px;
                letter-spacing: 0.15px;
                color: $bmo-wealth-slate;
                .mobile-view {
                    display: block;
                }
                .tablet-view,
                .desktop-view {
                    display: none;
                }
                @include mobile-and-tablet {
                    margin-top: 16px;
                }
                @include tablet {
                    .tablet-view {
                        display: block;
                    }
                    .mobile-view,
                    .desktop-view {
                        display: none;
                    }
                }
                @include desktop-and-large-screen {
                    margin-top: 24px;
                    .desktop-view {
                        display: block;
                    }
                    .mobile-view,
                    .tablet-view {
                        display: none;
                    }
                }
                display: block;
                p {
                    -webkit-box-orient: vertical;
                    max-width: 300px;
                    overflow: hidden;
                    letter-spacing: 0.15px;
                }
            }
        }
    }

    &.SUB_BRAND {
        div.sub-title {
            margin-bottom: 24px;
        }
    }
}

section.our-team-section-secondary {

    padding: 0 0 48px;
    @include tablet {
        padding: 0 0 64px;
    }
    @include desktop-and-large-screen {
        padding: 0 0 80px;
    }

    h2.title{
        position: relative;
        text-align: center;
        line-height: $line-height-40px;

        @include screen-max-width-mixin;

        margin: 0 auto 40px;
        padding-top: 48px;
        @include tablet {
            padding-top: 64px;
        }
        @include tablet-and-desktop {
            font-size: $font-size-44px;
            line-height: $line-height-48px;
            letter-spacing: -0.5px;
        }
        @include desktop-and-large-screen {
            padding-top: 80px;
        }
    }

    div.sub-title {
        text-align: center;
        margin-bottom: 24px;
        span {
            width: 100%;
            @include tablet {
                max-width: 596px;
            }
            @include desktop-and-large-screen {
                max-width: 584px;
            }
            font-size: $font-size-24px;
            font-weight: $font-weight-bold;
            font-stretch: normal;
            font-style: normal;
            line-height: $line-height-32px;
            letter-spacing: normal;
        }
    }

    .our-team-wrapper-secondary {

        justify-content: center;

        &.row {
            margin: 0;
            @include tablet-and-desktop{
                margin: 0 auto;
            }
        }

        max-width: $screen-sm-max;

        @include tablet {
            padding: 0 12px;
        }
        @include desktop-and-large-screen {
            max-width: 1280px;
            padding: 0 24px;
        }

        .col {
            display: none;
            padding: 0 15.5px;
            @include tablet {
                padding: 0 12px;
            }
        }

        .our-team-card-wrapper {

            max-height: 201px;
            background-color: $bmo-wealth-white;
            padding: 24px 24px 32px;
            color: $bmo-wealth-slate;
            margin: 0 auto;
            margin-bottom: 16px;

            @include mobile {
                width: 343px;
                margin: 0 auto 16px;
            }
            @include tablet {
                max-height: 264px;
                margin: 0 auto 24px;
            }
            @include desktop-and-large-screen {
                max-height: 258px;
                margin-bottom: 24px;
                padding-bottom: 48px;
            }
            &.with-biography {
                @include tablet {
                    height: 264px;
                }
                @include desktop-and-large-screen {
                    height: 258px;
                }
            }

            @include hero-banner-shadow-mixin;

            .image-wrapper {
                display: flex;

                img {
                    height: 64px;
                    max-width: 64px;
                    object-fit: contain;
                    border-radius: 4px;
                    @include tablet-and-desktop {
                        height: 96px;
                        max-width: 96px;
                    }
                }

                .person-name {
                    font-size: $font-size-14px;
                    line-height: $line-height-22px;
                    display: block;
                    margin-bottom: 8px;
                    word-wrap: break-word;
                }

                .person-job-title,
                .company {
                    font-size: $font-size-12px;
                    line-height: $line-height-18px;
                }

                .about {
                    padding-left: 16px;
                }
            }

            .content {
                font-size: $font-size-14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-22px;
                letter-spacing: normal;
                color: $bmo-wealth-slate;
                display: block;
                margin-top: 24px;
                .mobile-view {
                    display: block;
                }
                .desktop-view,
                .tablet-view {
                    display: none;
                }
                @include tablet {
                    .tablet-view {
                        display: block;
                    }
                    .desktop-view { 
                        display:none;
                    }
                }
                @include desktop-and-large-screen {
                    .desktop-view {
                        display: block;
                    }
                    .tablet-view {
                        display: none;
                    }
                }
                @include tablet-and-desktop {
                    letter-spacing: 0.25px;
                    .mobile-view {
                        display: none;
                    }
                }
                p {
                    font-size: $font-size-14px;
                    line-height: $line-height-22px;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    letter-spacing: 0.25px;
                }
            }
        }
    }
    &.SUB_BRAND {
        div.sub-title {
            margin-bottom: 24px;
        }
    }
}

.show-less-button {
    margin-top: 24px; 
    .show-less {
        img {
            transform: rotate(180deg);
        }
    }
}

.show-more-button,
.show-less-button {
    text-align: center;
    @include small-mobile-and-mobile {
        margin-top: 8px;
    }
    a {
        display: none;
        font-size: $font-size-14px;
        line-height: $line-height-20px;
        letter-spacing: 0.15px;
        font-stretch: normal;
        font-style: normal;
        position: relative;
        .arrow-down {
            margin-left: 5px;
            width: 12px;
            height: 15px;
        }
        label {
            text-transform: lowercase;
            margin: 0;
        }
        img {
            &.active {
                display: none;
            }
        }
    }
    a:hover, a:focus {
        img {
            &.active {
                display: inline;
            }
            &:not(.active) {
                display: inline !important;
            }
        }
    }
}

section.our-team-section-primary, section.our-team-section-secondary {
    h2.title {
        width: 100%;
        @include tablet {
            max-width: 595px;
        }
        @include desktop-and-large-screen {
            max-width: 789px;
        }
    }
    .explore-team-cta-wrapper {
        .cta-button-secondary {
            margin-top: 40px;
        }
    }
}
