section.what-we-can-offer-you {

    padding: 48px 0;
    @include tablet{
        padding: 64px 0;
    }
    @include desktop-and-large-screen {
        padding: 80px 0;
    }

    div.header{

        min-width: 343px;
        margin-bottom: 40px;

        @include tablet{
            max-width: 789px;
        }

        h2 {
            font-size: $font-size-44px;
            line-height: $line-height-48px;
            letter-spacing: -0.5px;
            margin: 0 auto;
            text-align: center;
            @include small-mobile-and-mobile {
                font-size: $font-size-36px;
                line-height: $line-height-40px;
                letter-spacing: normal;
            }
        }
    }

    .image-with-text-wrapper {
        .image-bottom.image {
            margin: 0 auto;
        }
    }

    .what-we-can-offer-inner-wrapper{
        .row {
            margin: 0 auto;
            padding: 0 25px;
            @include tablet {
                padding: 0 12px;
            }
            @include small-mobile-and-mobile {
                padding: 0 16px;
            }
            justify-content: center;
        }
        .icon {
            margin: 40px auto 0;
        }
        p {
            margin-bottom: 0;
        }
        .image-with-text-wrapper {
            .image {
                .content-wrapper {
                    .content {
                        h3 {
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
        @include tablet {
            .row > div {
                padding: 0 12px;
            }
        }
        @include small-mobile-and-mobile {
            .row > div {
                padding: 0;
            }
        }
    }
}
