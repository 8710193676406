section.carousel-slide-wrapper {
	text-align: center;
	#carousel-parent-container {
		.carousel-indicators-parent {
			margin-left: auto;
			margin-right: auto;
			.carousel-indicators {
				height: 35px;
				padding: 5px 9px;
				top: 320px;
				margin-left: auto;
				margin-right: auto;
				background: #fff;
				border-radius: 5px;
				color: transparent;
				width: min-content;
				width: -moz-min-content;
				width: -webkit-min-content;
    			box-shadow: 0 2px 3px 0 #c7c6c6;

				li {
					text-decoration: none;
					border: 2px solid #0075be;
					width: 8px;
					height: 8px;
					display: inline-block;
					color: transparent;
					overflow: hidden;
					border-radius: 50%;
					margin: auto 5px;
					opacity: 1;
					&.active {
						background-color: #0075be;
						border: none;
						width: 12px;
						height: 12px;
					}
				}
			}
		}

		.carousel-inner {
			padding-bottom: 40px;
			.carousel-controls-auto {
				position: absolute;
				margin: 0;
				@include tablet {
					right: 25px;
					top: 320px;
				}
				@include desktop-and-large-screen {
					right: 250px;
					top: 320px;
				}
				@include small-mobile-and-mobile {
					right: 15px;
					top: 15px;
				}
				.carousel-controls-auto-item {
					button {
						background: transparent;
						border: none;
						img {
							width: 36px;
							height: 36px;
						}
						&.carousel-play {
							display: none;
						}
						&.carousel-pause {
							display: block;
						}
					}

					// Timer Styles
					#countdown {
						position: relative;
						svg {
							position: absolute;
							top: -2px;
							right: -2px;
							width: 40px;
							height: 40px;
							transform: rotateY(-180deg) rotateZ(-90deg);
							circle {
								stroke-dasharray: 113px;
								stroke-dashoffset: 0px;
								stroke-linecap: butt;
								stroke-width: 2px;
								stroke: #0075be;
								fill: none;
								animation: countdown 6s linear infinite forwards;
							}
							@keyframes countdown {
								from {
									stroke-dashoffset: 0px;
								}
								to {
									stroke-dashoffset: 113px;
								}
							}
						}
					}
				}
			}
			.carousel-item {
				.carousel-slide-inner-wrapper {
					.slide-image-background {
						max-width: 100%;
						height: 370px;
						margin: 0 auto;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
					}
					.description-foreground-wrapper {
						.carousel-slide-description-title {
							padding: 0;
						}
						.carousel-slide-description {
							@include hero-banner-shadow-mixin;
							position: relative;
							text-align: center;
							background: $bmo-wealth-white;
							padding: 40px 24px 40px 24px;
							@include tablet {
								padding: 48px 62px 48px 62px;
							}
							@include desktop-and-large-screen {
								padding: 48px 102px 48px 102px;
							}
							.description-wrapper {
								padding: 0 40px;
								max-width: 995px;
								margin: 0 auto;
								width: 100%;
								.carousel-slide-description-text {
									margin-top: 24px;
									margin-bottom: 60px;
								}
							}
						}
					}
				}
			}
		}
		.carousel-control-prev,
		.carousel-control-next {
			width: 35px;
			height: 35px;
			opacity: 0.9;
			top: 155px;
			img {
				width: 35px;
				height: 35px;
			}
		}
		.carousel-control-prev {
			@include tablet {
				left: 20px;
			}
			@include desktop-and-large-screen {
				left: 20px;
			}
			@include small-mobile-and-mobile {
				left: 20px;
			}
		}
		.carousel-control-next {
			@include tablet {
				right: 20px;
			}
			@include desktop-and-large-screen {
				right: 20px;
			}
			@include small-mobile-and-mobile {
				right: 20px;
			}
		}
	}
}