.insights-section {
    display: flex;
    align-items: center;
    padding: 40px 10% 40px 10%;
    background: linear-gradient(180deg, #0079c1 0%, #006ea3 100%),
        linear-gradient(0deg, #ffffff, #ffffff);
    color: white;

    .insights-head {
        font-weight: 700;
        font-size: 44px;
        margin-bottom: 20px;
        line-height: inherit;
    }

    .insights-des {
        font-size: 18px;
    }
    .insights-img{
        width: 96px;
        height: 96px;
    }
    .insights-btn,
    .insights-btn-mobile {
        .white-bg-blue-text {
            background-color: #FFFFFF;
            margin-top: 20px;
        }

        .blue-bg-white-text {
            margin: 20px 0px 35px 0px !important;
            margin-top: 20px;
        }
    }

    .insights-btn-mobile {
        .white-bg-blue-text {
            margin-top: 30px !important;
        }

        .blue-bg-white-text {
            margin-top: 30px !important;
        }
    }

    .left-content {
        padding-right: 5%;
        width: 90%;
    }

    .right-content-container {
        background: linear-gradient(252.09deg, #005587 0.49%, #001928 100.61%);
        padding: 20px;
        border-radius: 0px 0px 15px 15px;
    }

    .flex-container {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        flex-wrap: wrap;
    }

    .category-tag {
        background-color: #005587;
        border-radius: 4px;
        padding: 1.5px 6px 0px 6px;
        font-weight: 700;
        font-size: 12px;
        margin-right: 12px;
        color: #FFFFFF;
        margin-bottom: 12px;
        white-space: nowrap;
        text-transform: uppercase;
        cursor: pointer;
    }

    .sub-category-tag {
        background: #d9dce1;
        color: #001928;
        border-radius: 4px;
        padding: 1.5px 6px 0px 6px;
        font-weight: 700;
        font-size: 12px;
        margin-right: 12px;
        margin-bottom: 12px;
        white-space: nowrap;
        text-transform: uppercase;
        cursor: pointer;
    }

    .podcast-head {
        padding-top: 2%;
        font-weight: 700;
        font-size: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .podcast-des {
        font-weight: 400;
        font-size: 14px;
        padding-top: 2%;
        padding-bottom: 2%;
    }

    .read-more {
        font-weight: 700;
        font-size: 16px;
        color: #fff;
    }

    .insights-btn-mobile {
        display: none;
    }

    .right-img {
        width: 100%;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .right-img-mobile {
        display: none;
    }

    .right-content {
        width: 100%;
        display: block;
        max-width: 500px;
    }

    @media (max-width: 1450px) {
        .right-img {
            height: 100%;
            display: block;
        }

        .right-img-mobile {
            display: none;
            height: 100%;
            width: 100%;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }
    }

    @media (max-width: 1050px) {
        .right-img {
            height: 100%;
            display: none;
        }

        .right-img-mobile {
            display: block;
            height: 100%;
            width: 100%;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        .right-content {
            max-width: 400px;
        }
    }

    @media (max-width: 800px) {
        .insights-head {
            font-size: 36px;
        }

        .right-content {
            max-width: 500px;
        }

        .insights-btn-mobile {
            display: block;
        }

        .insights-des {
            margin-bottom: 30px;
        }

        .insights-btn {
            display: none;
        }

        flex-direction: column;

        .left-content {
            text-align: center;
            padding-right: 0%;
        }
    }

    @media (max-width: 650px) {
        padding: 40px 25px 40px 25px;
    }

}
