.player-section {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #d9dce1;
  box-shadow: 0px -2px 2px 0px #00192812;
  z-index: 10000;

  .controls {
    display: flex;
    align-items: center;
  }

  .player-container {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .controls-flex {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 22px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .category {
    background-color: #005587;
    border-radius: 4px;
    padding: 1.5px 6px 0px 6px;
    font-weight: 700;
    font-size: 12px;
    margin-right: 12px;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
  }

  .sub-category {
    background: #d9dce1;
    color: #001928;
    border-radius: 4px;
    padding: 1.5px 6px 0px 6px;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
  }

  #backward,
  #forward {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    cursor: pointer;
  }

  #ctrlIcon {
    width: 36px;
    height: 36px;
    margin-right: 15px;
    cursor: pointer;
  }

  input[type="range"] {
    color: #0075be;
    --thumb-height: 1.125em;
    --track-height: 4px;
    --track-color: #d9dce1;
    --brightness-down: 80%;
    --clip-edges: 0.125em;
    border-radius: 30px;
    width: 100%;
    cursor: pointer;
  }

  input[type="range"] {
    position: relative;
    background: #fff0;
    overflow: hidden;
  }

  .left-img {
    width: 108px;
    height: 108px;
    margin-right: 20px;
    border-radius: 2px;
  }

  #podcast-current-time,
  #podcast-duration {
    font-size: 16px;
    font-weight: 400;
    color: #646c76;
    white-space: nowrap;
  }

  #podcast-current-time {
    margin-right: 10px;
    margin-left: 10px;
  }

  #podcast-duration {
    margin-left: 10px;
    margin-right: 10px;
  }

  #transcript {
    width: 22.5px;
    height: 22.5px;
    margin-left: 15px;
    cursor: pointer;
  }

  #volume {
    height: 32px;
    display: inline-flex;
    overflow: hidden;
    width: auto;
    max-width: 37px;
    -webkit-transition: max-width 0.5s;
    transition: max-width 0.5s;
    align-items: center;
    cursor: pointer;
  }

  #volume:hover {
    max-width: 1000px;
  }

  .volume-icon {
    padding: 0px 8px;
    display: flex;
    align-items: center;
  }

  #volume-range {
    width: 150px;
  }

  /* === WebKit specific styles === */
  input[type="range"],
  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-webkit-slider-runnable-track {
    position: relative;
  }

  input[type="range"]::-webkit-slider-thumb {
    position: relative;
  }

  input[type="range"]::-webkit-slider-thumb {
    --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
    --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
    --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
    --clip-further: calc(100% + 1px);
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
      100vmax currentColor;

    width: var(--thumb-width, var(--thumb-height));
    background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
      50% calc(var(--track-height) + 1px);
    background-color: currentColor;
    box-shadow: var(--box-fill);
    border-radius: var(--thumb-width, var(--thumb-height));
    filter: brightness(100%);
    clip-path: polygon(
      100% -1px,
      var(--clip-edges) -1px,
      0 var(--clip-top),
      -100vmax var(--clip-top),
      -100vmax var(--clip-bottom),
      0 var(--clip-bottom),
      var(--clip-edges) 100%,
      var(--clip-further) var(--clip-further)
    );
  }

  input[type="range"]:hover::-webkit-slider-thumb {
    filter: brightness(var(--brightness-hover));
    cursor: grab;
  }

  input[type="range"]:active::-webkit-slider-thumb {
    filter: brightness(var(--brightness-down));
    cursor: grabbing;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
      100% calc(var(--track-height) + 1px);
  }

  /* === Firefox specific styles === */
  input[type="range"],
  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-thumb {
    appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-thumb,
  input[type="range"]::-moz-range-progress {
    background: #fff0;
  }

  input[type="range"]::-moz-range-thumb {
    background: currentColor;
    border: 0;
    width: var(--thumb-width, var(--thumb-height));
    border-radius: var(--thumb-width, var(--thumb-height));
    cursor: grab;
  }

  input[type="range"]:active::-moz-range-thumb {
    cursor: grabbing;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    background: var(--track-color);
  }

  input[type="range"]::-moz-range-progress {
    appearance: none;
    background: currentColor;
    transition-delay: 30ms;
  }

  input[type="range"]::-moz-range-track,
  input[type="range"]::-moz-range-progress {
    height: calc(var(--track-height) + 1px);
    border-radius: var(--track-height);
  }

  input[type="range"]::-moz-range-thumb,
  input[type="range"]::-moz-range-progress {
    filter: brightness(100%);
  }

  input[type="range"]:hover::-moz-range-thumb,
  input[type="range"]:hover::-moz-range-progress {
    filter: brightness(var(--brightness-hover));
  }

  input[type="range"]:active::-moz-range-thumb,
  input[type="range"]:active::-moz-range-progress {
    filter: brightness(var(--brightness-down));
  }

  input[type="range"]:disabled::-moz-range-thumb {
    cursor: not-allowed;
  }
}

.player-container-mobile {
  display: none;
}

.icon-button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.icon-button:hover {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.icon-button:focus {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 700px) {
  .player-container {
    display: none !important;
  }

  .player-container-mobile {
    display: flex;
    align-items: center;
    padding: 15px;

    .left-img {
      width: 48px;
      height: 48px;
      margin-left: 15px;
    }

    .flex {
      display: flex;
      flex-direction: column;
    }

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      margin: 0px;
    }

    #time {
      color: #646c76;
      font-size: 14px;
    }

    #ctrlIcon {
      margin-left: 10px;
    }
  }
}
