section.faq-section-wrapper {

    color: $bmo-wealth-slate;

    .chevron, .question {
        cursor: pointer;
    }

    div.faq-header {
        max-width: 584px;
        margin: 0 auto;

        padding: 8px 0 48px;
        @include tablet {
            max-width: 472px;
            padding: 96px 0 64px;
        }
        @include desktop-and-large-screen {
            max-width: 584px;
            padding: 104px 0 80px;
        }
        &.paired {
            padding: 48px 0 0;
            @include tablet {
                padding: 64px 0 0;
            }
            @include desktop-and-large-screen {
                padding: 80px 0 0;
            }
        }
        h1, h2 {
            font-size: $font-size-44px;
            text-align: center;
            line-height: $line-height-48px;
            letter-spacing: normal;
            @include small-mobile-and-mobile {
                font-size: $font-size-36px;
            }
            margin: 0;
        }
        h1.individual {
            padding-bottom:16px;
        }
        h2.paired {
            padding-bottom:40px;
        }
        div.description {
            text-align: center;
            line-height: $line-height-24px;
            letter-spacing: 0.15px;
        }
    }

    .accordion-wrapper {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 48px;

        @include tablet {
            padding-bottom: 64px;
        }
        @include desktop-and-large-screen {
            max-width: 789px;
            padding-bottom: 80px;
        }
        button {
            display: block;
            border: none;
            background-color: transparent;
            width: 100%;
            padding: 0;
            img {
                &.arrow-up,
                &.arrow-down {
                    float: left;
                }
            }
        }
        button.chevron {
            &:not(.collapsed) {
                .arrow-up {
                    &.active {
                        display: none;
                    }
                }
                .arrow-down {
                    display: none;
                }
            }
        }
        button.chevron.collapsed {
            .arrow-down {
                &.active {
                    display: none;
                }
            }
            .arrow-up {
                display: none;
            }
        }
        button.chevron.collapsed:hover ,button.chevron.collapsed:focus {
            img.arrow-down {
                &.active {
                    display: inline;
                }
                &:not(.active) {
                    display: none;
                }
            }
        }
        button.chevron:focus, button.chevron:hover {
            &:not(.collapsed) {
                img.arrow-up {
                    &.active {
                        display: inline;
                    }
                    &:not(.active) {
                        display: none;
                    }
                }
            }
        }
    }
}
