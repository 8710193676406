.insights-wrapper {
    .insight{
        text-align: center;
    }
    @include desktop-and-large-screen {
        padding: 0 102px;
    }
    .insights-title-text {
        font-size: $font-size-24px;
        font-weight: $font-weight-bold;
        line-height: $line-height-32px;
        letter-spacing: normal;
        text-align: center;
        margin: 8px 0 24px;
        @include tablet-and-desktop {
            margin-top: 48px;
        }
    }
    .insights-inner-wrapper {
        height: 453px;
        margin: 0 auto;
        background-color: $bmo-wealth-white;
        @include hero-banner-shadow-mixin;
        @include small-mobile-and-mobile {
            max-width: 343px;
        }
        @include tablet {
            max-width: 720px;
            height: 236px;
        }
        @include tablet-and-desktop {
            display: flex;
        }
        @include desktop-and-large-screen {
            max-width: 995px;
            height: 272px;
        }
        .insight-image {
            height: 154px;
            object-fit: cover;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 4px 4px 0 0;
            @include tablet {
                width: 224px;
                height: 236px;
            }
            @include tablet-and-desktop {
                border-radius: 4px 0 0 4px;
            }
            @include desktop-and-large-screen{
                width: 379px;
                height: 272px;
            }
        }
        .block-heading {
            display: flex;
            align-items: center;
            .type {
                margin-right: 16px;
                @include small-mobile-and-mobile {
                    display: none;
                }
            }
            .series {
                font-family: $font-family;
                font-size: $font-size-10px;;
                font-weight: $font-weight-bold;;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: 0.63px;
                background-color: $bmo-wealth-light-gray;
                color: $bmo-wealth-slate;
                margin-right: 16px;
                padding: 0 4px;
                border-radius: 2px;
                text-transform: uppercase;
            }
            .type {
                font-family: $font-family;
                text-transform: uppercase;
                font-size: $font-size-12px;
                font-weight: $font-weight-normal;
                font-stretch: normal;
                font-style: normal;
                color: $bmo-wealth-slate;
                line-height: 1.33;
                letter-spacing: 0.25px;
                text-transform: uppercase;
            }
        }
        .insight-link-wrapper {
            text-align: left;
        }
        a.insights-link {
            display: block;
            font-size: $font-size-22px;
            line-height: $line-height-28px;
            letter-spacing: 0.25px;
            margin-top: 24px;
        }
        a.insights-link:hover{
            color: $bmo-wealth-ultramarine;
        }
        .content-wrapper {
            position: relative;
            padding: 24px;
            max-width: 410px;
            background-color: $bmo-wealth-white;
            @include tablet-and-desktop {
                max-width: 481px;
            }
            @include desktop-and-large-screen {
                padding: 24px 24px 24px 32px;
            }
            .content {
                @include tablet {
                    max-width: 386px;
                }
                @include desktop-and-large-screen {
                    max-width: 481px;
                }
            }
        }
        div.description{
            margin: 16px 0 0;
            text-align: left;
        }
    }
    .tag {
        font-size: $font-size-12px;
        line-height: $line-height-16px;
        color: $bmo-wealth-slate;
        text-transform: uppercase;
        text-align: left;
        position: absolute;
        bottom: 20px;
        max-width: 295px;
        @include tablet {
            max-width: 410px;
        }
        @include desktop-and-large-screen {
            max-width: 481px;
        }
        @include small-mobile-and-mobile {
            bottom: -40px;
        }
        &.date{
            @include tablet {
                max-width: 386px;
            }
            @include desktop-and-large-screen {
                max-width: 481px;
            }
            span {
                margin-right: 16px;
                line-height: 1.5;
            }
        }
    }
}
