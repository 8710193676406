.bd-contact-us-form-modal-lg {
  .modal-header {
    border-bottom: 0px;
  }
}

.thank-you-popup-section.show {
  display: flex;
}

.contact-us-popup-section.hide {
  display: none;
}

.contact-us-popup-section {
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 8%;

  .heading {
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
  }

  .select {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  select {
    width: 100%;
    padding: 0.5rem 0 0.5rem 0;
    border-radius: 0px;
    background-color: #fff;
    -webkit-appearance: none;
    color: black;
    border: none;
    border-bottom: 2px solid grey;
    outline: none;
    font-size: 1rem;
    transition: all 0.3s ease;
    background-image: url('/static/assets/svgs/accordion-svg/chevron-down.svg') !important;
    background-position: calc(100% - 10px) !important;
    background-size: 14px 17px !important;
    background-repeat: no-repeat !important;
  }

  .help-text {
    color: #646c76;
    font-size: .875rem;
    font-weight: 400;
    margin-top: 5px;
  }

  label {
    color: #0075be;
  }

  .form-img {
    height: 72px;
    width: 96px;
    align-self: center;
    margin-bottom: 10px;
  }

  .error-message {
    color: #c81414;
    margin-top: 5px;
    display: flex;

    img {
      margin-right: 5px;
    }
  }

  #submission-error {
    text-align: center;
    color: #c81414;
  }

  .input-with-error {
    margin-bottom: 3rem;
  }

  .submit-btn {
    cursor: pointer;
    border-radius: 1.75rem;
    padding: 1rem 2rem;
    border: 0.125rem solid #0075be;
    background-color: #0075be;
    color: #ffffff;
    box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 25, 40, 0.04),
      0 0.25rem 1.5rem 0 rgba(0, 25, 40, 0.1);
    font-size: 1rem;
    font-weight: 700;
    align-self: center;
  }

  .submit-btn {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  #contactForm {
    display: flex;
    flex-direction: column;
    .submit-btn-container {
      display: flex;
      justify-content: center;
    }
  }

  .resource-link {
    color: #0075be;
    cursor: pointer;
    font-weight: 700;
  }

  .privacy,
  .description {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mandtory-field {
    font-size: 0.875rem;
    padding-top: 10px;
    text-align: left;
    padding-bottom: 40px;
  }

  .description {
    font-weight: 300;
  }

  .privacy {
    font-size: 0.875rem;
  }

  .input-group {
    position: relative;

    label {
      position: absolute;
      top: 50%;
      left: 0;
      margin: 0%;
      transform: translateY(-50%);
      pointer-events: none;
      transition: all 0.3s ease;
      color: #0075be;

      &.expanded {
        top: -30%;
      }
      &.expanded-default{
        top: -15%;
      }
    }

    input {
      width: 100%;
      padding: 0.5rem 0 0.5rem 0;
      border: none;
      border-bottom: 2px solid grey;
      border-radius: 0px;
      outline: none;
      font-size: 1rem;
      transition: all 0.3s ease;

      &.expanded {
        border: 2px solid #0075be;
        border-bottom: 2px solid #0075be;
      }
    }
  }
}

.contact-us-popup-section-button {
  display: flex;
  flex-direction: column;

  .contact-us-btn {
    cursor: pointer;
    border-radius: 1.75rem;
    padding: 1rem 2rem;
    border: 0.125rem solid #0075be;
    background-color: #0075be;
    color: #ffffff;
    box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 25, 40, 0.04),
      0 0.25rem 1.5rem 0 rgba(0, 25, 40, 0.1);
    font-size: 1rem;
    font-weight: 700;
    align-self: center;
    margin-bottom: 40px;
  }
}

.thank-you-popup-section {
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 5%;

  .thank-you-img {
    height: 200px;
    width: 240px;
  }

  .show {
    display: flex !important;
  }

  .heading {
    font-size: 2.75rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -0.5px;
    line-height: 1.09;
    margin-bottom: 0;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    max-width: 789px;
  }

  .description {
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 1.5;
    margin: 24px auto 0;
    text-align: center;
    max-width: 789px;
  }

  .resource-link {
    color: #0075be;
    cursor: pointer;
    font-weight: 700;
  }
}

@media (min-width: 1024px) {
  .input-group {
    width: 100%;
  }

  .input-with-error {
    padding: 0 10px 0 0;
  }

  .contact-us-popup-section #contactForm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .one-third-width {
    width: 33%;
  }

  .half-width {
    width: 50%;
  }

  .full-width {
    width: 100%;
  }

  .submit-btn {
    justify-content: center;
    margin: auto;
  }
}

@media (max-width: 797px) {

  .one-third,
  .half,
  .full {
    width: 100%;
  }

  .submit-btn {
    margin-top: 20px;
  }
}

.captcha-contact-us{
    display: flex;
    justify-content: center;
    width: 100%;
    .g-recaptcha{
      width: 273px;
   }
}