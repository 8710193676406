/* Global styles are declared here. */

html {
    font-size: $font-size-16px;
}

h1 {
    @include bmo-font($font-size-44px, $font-weight-light, $font-family);
    letter-spacing: $letter-spacing-44px;
    line-height: $line-height-48px;
}

h2 {
    @include bmo-font($font-size-36px, $font-weight-light, $font-family);
    letter-spacing: $letter-spacing-36px;
    line-height: $line-height-48px;
}

h3 {
    @include bmo-font($font-size-28px, $font-weight-light, $font-family);
    letter-spacing: $letter-spacing-40px;
    line-height: $line-height-32px;
}

h4 {
    @include bmo-font($font-size-24px);
    line-height: $line-height-32px;
}

p,
h5,
h6 {
    @include bmo-font($font-size-16px);
    letter-spacing: normal;
    line-height: $line-height-24px;
}

strong {
    font-family: $font-family;
    font-weight: $font-weight-bold;
}

body {
    font-family: $font-family;
    font-weight: $font-weight-normal;
    color: $bmo-wealth-slate;
    // https://responsivedesign.is/articles/font-smoothing/
    // https://www.html5rocks.com/en/tutorials/internals/antialiasing-101/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    cursor: pointer;
    color: $bmo-wealth-accessible-blue;
    font-weight: $font-weight-bold;
}

a:hover, a:focus {
    text-decoration: underline;
    color: $bmo-wealth-ultramarine;
}

.cta-button-secondary{

    input, a {
        font-size: $font-size-14px;
        line-height: $line-height-24px;
        letter-spacing: 0.25px;
        cursor: pointer;

        &.white-bg-blue-text {
            border: 2px solid $bmo-wealth-accessible-blue;
            color: $bmo-wealth-accessible-blue;
            background-color: inherit;
        }

        &.white-bg-blue-text:hover {
            color: $bmo-wealth-ultramarine;
            border-color: $bmo-wealth-ultramarine;
        }

        &.blue-bg-white-text {
            border: 2px solid $bmo-wealth-accessible-blue;
            background-color: $bmo-wealth-accessible-blue;
            color: $bmo-wealth-white;
        }

        &.blue-bg-white-text:hover {
            color: $bmo-wealth-white;
            border-color: $bmo-wealth-ultramarine;
            background-color: $bmo-wealth-ultramarine;
        }
    }

    a.cta-link.blue-bg-white-text {
        margin: 40px 0px 80px 0px;
    }

    a:link, a:visited, .custom-button {
        @include bold-text;
        font-size: 14px;
        border-radius: 28px;
        padding: 14px 30px;
        text-align: center;
        text-decoration: none;
        display: block;
        text-transform: uppercase;
        width: fit-content;
    }
}
@media (max-width: 700px) {
    a.cta-link.blue-bg-white-text {
        margin: 40px 0px 40px 0px !important;
    }
}



.screen-max-width {
    @include screen-max-width-mixin;
}

.row-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;

    @include tablet {
        max-width: 736px;
    }
    .column {
        padding: 0 16px;
        @include tablet {
            padding: 0 12px;
        }
    }
    &:not(.sub-brand) {
        .rich-text-wrapper {
            h3, h4 {
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .column:first-child {
            @include tablet-and-desktop {
                padding-left: 0;
            }
        }
        .column:last-child {
            @include tablet-and-desktop {
                padding-right: 0;
            }
        }
        .column:not(:first-child) {
            @include small-mobile-and-mobile {
                margin-top:40px;
            }
        }
    }

    &.sub-brand {
        a {
            text-decoration: none !important;
        }
        a:hover {
            text-decoration: underline !important;
        }
        &:not(.home) {
            padding: 48px 0;
            @include tablet {
                padding: 64px 45px;
            }
            @include desktop-and-large-screen {
                padding: 80px 0;
            }
        }
        @include tablet {
            padding: 0 45px;
        }
        h3, h4 {
            font-size: $font-size-24px;
            line-height: $line-height-32px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            margin-bottom: 16px;
        }

        .rich-text-wrapper {
            p:nth-of-type(1) {
                margin-bottom: 24px;
            }
        }

        p:last-child {
            margin: 0;
        }
        .column:first-child {
            @include tablet-and-desktop {
                padding-left: 0;
            }
        }
        .column:last-child {
            @include tablet-and-desktop {
                padding-right: 0;
            }
        }
        .column:not(:first-child) {
             @include small-mobile-and-mobile {
                padding-top: 48px;
             }
        }
        a {
            font-size: $font-size-18px;
            line-height: $line-height-28px
        }
    }

    p {
        letter-spacing: 0.15px;
        line-height: $line-height-24px;
    }
}

section.sub-menu-placeholder {
    display: none;
}

.service-content-wrapper {
    @include tablet-and-desktop{
        padding: 0 12px;
    }
    .content {
        .service-link {
            text-align: center;
            img {
                height: 72px;
                object-fit: contain;
                margin-bottom: 16px;
            }
        }
    }
    .content {
        text-align: center;
        a {
            @include mobile-and-tablet{
                font-size: $font-size-18px;
            }
            font-size: $font-size-22px;
            line-height: $line-height-28px;
            letter-spacing: normal;
            cursor: pointer;
        }
        .description{
            text-align: center;
            letter-spacing: 0.15px;
            margin: 16px 0 40px;
            @include mobile-and-tablet{
                font-size: $font-size-14px;
            }
        }
    }
}

.individual-insight-wrapper {
    margin: 0 auto;

    @include tablet {
        max-width: 348px;
    }

    @include desktop-and-large-screen{
        max-width: 379px;
    }

    .insight-card-wrapper {
        max-width: 343px;
        margin: 16px auto 0;
        background: $bmo-wealth-white;
        height: 453px;
        @include hero-banner-shadow-mixin;
        @include tablet-and-desktop {
            margin-top: 24px;
        }
        @include tablet {
            max-width: 410px;
        }
        @include desktop-and-large-screen{
            height: 470px;
            max-width: 481px;
        }

        .insight-listing-image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            height: 154px;
            @include tablet {
                height: 156px;
            }
            @include desktop-and-large-screen {
                height: 170px;
            }
        }

        .insight-card-content {
            position: relative;
            padding: 24px;
            height: 299px;

            @include tablet {
                height: 297px;
            }

            @include desktop-and-large-screen{
                height: 300px;
            }

            .insight-series-type {
                display: inline-block;
                color: $bmo-wealth-slate;
                font-size: $font-size-10px;
                font-weight: $font-weight-bold;
                background: $bmo-wealth-light-gray;
                padding: 0 4px;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-16px;
                letter-spacing: 0.63px;
                border-radius: 2px;
                margin-bottom: 16px;

                @include tablet {
                    margin-bottom: 25px;
                }

                @include desktop-and-large-screen{
                    margin-bottom: 24px;
                }
                &.sub-brand {
                    color: $bmo-wealth-white;
                    background: $bmo-wealth-slate;
                }
            }

            .insight-title {
                margin-bottom: 16px;

                .insight-card-title {
                    font-size: $font-size-22px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: $line-height-28px;
                    letter-spacing: 0.15px;
                    display: block;
                }
            }

            .insight-card-content-text {
                margin-bottom: 16px;
                font-size: $font-size-16px;
                color: $bmo-wealth-slate;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: $line-height-24px;
                letter-spacing: 0.15px;
            }

            .insight-publication-details {
                max-width: 340px;
                // line-height: 0;
                position: absolute;
                bottom: 20px;
                .insight-closure {
                    display: inline-block;
                    font-size: $font-size-12px;
                    margin-right: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: 0.25px;
                    line-height: $line-height-16px;
                    color: $bmo-wealth-slate;

                    @include tablet-and-desktop{
                        display: inline-block;
                    }
                    &:nth-child(n+5) {
                        display: none;
                    }
                }

            }

        }
    }
}

.row {
    &.page-cta-button {
        @include screen-max-width-mixin;
        a {
            margin: 40px 0 48px;
            @include small-mobile-and-mobile {
                padding: 16px 72.5px 16px 71.5px;
            }
            @include tablet {
                margin: 40px 0 64px;
            }
            @include desktop-and-large-screen {
                margin: 40px 0 80px;
            }
        }
        .column {
            padding: 0;
        }
    }
}

// .footer-style {
//     padding-bottom: 72px;
//     @include tablet {
//         padding-bottom: 96px;
//     }
//     @include desktop-and-large-screen {
//         padding-bottom: 120px;
//     }
// }

.sub-brand-listing {
    h3 {
        font-size: $font-size-24px;
        line-height: $line-height-32px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }
}

.main-landmark {
    position: relative;
    top: 0;
    left: 0;
    #skip-main {
        position: absolute;
        display: inline;
        font-size: $font-size-14px;
        background: $bmo-wealth-white;
        color: $bmo-wealth-accessible-blue;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.25px;
        left: 0px;
        top: 2px;
        transition: z-index 0.3s;
        z-index: -1;
        padding: 15px 32px;
        &:focus {
            z-index: 1000;
            display: inline-block;
        }
    }
}

.broker-check {
    .brokercheck {
        @include small-mobile-and-mobile {
            padding-bottom: 48px;
        }
        @include tablet {
            padding-bottom: 64px;
        }
        @include desktop-and-large-screen {
            padding-bottom: 80px;
        }
    }
}

*:focus {
    @include accessibility-outline;
}
