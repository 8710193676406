.podcast-cards-section {
  background: #f5f6f7;

  padding: 60px 10% 60px 10%;

  .podcast-card-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .podcast-cards-head {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 40px;
    text-align: center;
  }

  .podcast-card-head {
    color: #0075be;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }

  .podcast-card {
    flex: 0 0 30.333333%;
    padding: 15px;
    margin-bottom: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
  }

  .card-img {
    width: 106px;
    height: 82px;
    margin: 10px 0px 0px 0px;
  }

  .podcast-card-des {
    text-align: center;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 700px) {
    .podcast-cards-head {
      font-size: 22px;
    }

    .podcast-card-flex {
      display: flex;
      flex-direction: column;
    }

    .podcast-card-des {
      display: none;
    }
  }
}
